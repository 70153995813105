import React, { useState, useRef, useEffect } from "react";
import { IoLockClosedOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { useUserInfoSelector, useUserTokenSelector } from "store/reducers";
import { Progress } from "antd";
import "./Challenges.css"
import { getChallenges, getLeaderboard } from "actions/challenges";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "store/actions";
import Lottie from "lottie-react";
import walking from '../../assets/JsonAnimations/walking.json';
import sleepChallenge from '../../assets/JsonAnimations/sleepChallenge.json';
import runningGirl from '../../assets/JsonAnimations/runningGirl.json';
import digitalDetoxChallenge from '../../assets/JsonAnimations/digitalDetoxChallenge.json';
import calorieBurnChallenge from '../../assets/JsonAnimations/calorieBurnChallenge.json';
import Skeleton from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';

const dummyLeaderboardData = [
    {
        "rank": 1,
        "name": "Amit",
        "gender": "male",
        "dailyAvgSteps": 220,
        "totalSteps": 2200,
        "days": 10,
        "team": "Marketing"
    },
    {
        "rank": 2,
        "name": "Anant",
        "gender": "male",
        "dailyAvgSteps": 210,
        "totalSteps": 2100,
        "days": 10,
        "team": "Marketing"
    },
    {
        "rank": 3,
        "name": "Amrita",
        "gender": "female",
        "dailyAvgSteps": 200,
        "totalSteps": 2000,
        "days": 10,
        "team": "Marketing"
    },
    {
        "rank": 4,
        "name": "Amit",
        "gender": "male",
        "dailyAvgSteps": 180,
        "totalSteps": 1800,
        "days": 10,
        "team": "Marketing"
    },
    {
        "rank": 5,
        "name": "Amit",
        "gender": "male",
        "dailyAvgSteps": 160,
        "totalSteps": 1600,
        "days": 10,
        "team": "Marketing"
    },
    {
        "rank": 6,
        "name": "Amit",
        "gender": "male",
        "dailyAvgSteps": 150,
        "totalSteps": 1500,
        "days": 10,
        "team": "Marketing"
    }
]
const goalCompletion = 300;
const targetSteps = 1000;
const challengeInfo = {
    "coinsForChallenge": 30,
    "users": 200,
    "days": 21,
}
const avatar = {
    "female":
        "https://ucarecdn.com/5c10944e-91bc-4ee6-a259-23565aac6100/avatar3.png",
    "male": "https://ucarecdn.com/e6d48801-632e-4d97-b297-bd15292f07ed/avatar2.png",
    "other":
        "https://ucarecdn.com/732dfe6d-fdf3-40c5-a066-e8857ce85d23/avatar1.png",
    "": "https://ucarecdn.com/732dfe6d-fdf3-40c5-a066-e8857ce85d23/avatar1.png"
};

const challengeAnimations = {
    "Sleep": sleepChallenge,
    "SocialMediaDetox": digitalDetoxChallenge,
    "Steps": runningGirl,
    "Calories": calorieBurnChallenge,
};

const Challenges = () => {
    const [isLocked, setIsLocked] = useState(true);
    const [fullLeaderboard, setFullLeaderboard] = useState(false);
    // const [leaderboardData, setLeaderboardData] = useState(dummyLeaderboardData);
    const userInfo = useUserInfoSelector();
    const { categoryId } = useUserInfoSelector();
    const ltableDivRef = useRef(null);
    const token = useUserTokenSelector();
    const [challenges, setChallenges] = useState([]);
    const [currentChallengeIndex, setCurrentChallengeIndex] = useState(0);
    const [challengeName, setChallengeName] = useState('');
    const dispatch = useDispatch();
    const myData = useUserInfoSelector();
    const currentChallenge = challenges[currentChallengeIndex];
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalRef = useRef(null);
    const [isLoadingChallenges, setIsLoadingChallenges] = useState(true);
    const [userCurrentProgress, setUserCurrentProgress] = useState(0);

    useEffect(() => {
        getAvailableChallenges()
    }, [])

    useEffect(() => {
        getLeaderboardData()
    },[currentChallenge])

    const getAvailableChallenges = async () => {
        setIsLoadingChallenges(true);
        dispatch(startLoading())
        const response = await getChallenges(token);
        if (response.data.status === "success") {
            setChallenges(response.data.data);
            dispatch(stopLoading())
        } else {
            dispatch(stopLoading())
            console.error("Failed to fetch challenges");
        }
        setIsLoadingChallenges(false);
    }

    const getLeaderboardData = async() => {
        if(currentChallenge?.isParticipating) {
            dispatch(startLoading())
            const response = await getLeaderboard(token, currentChallenge._id);
            if(response.data.status === "success") {
                setUserCurrentProgress(response.data.data.currentDayTotalValue);
                setLeaderboardData(response.data.data.leaderboard)
                dispatch(stopLoading())
            }
        }
    }

    const handleCloseLeaderboard = () => {
        setFullLeaderboard(false);
        ltableDivRef.current.scrollTo(0, 0);
    };

    const handleLeftClick = () => {
        setCurrentChallengeIndex((prevIndex) =>
            prevIndex === 0 ? prevIndex : prevIndex - 1
        );
    };

    const handleRightClick = () => {
        setCurrentChallengeIndex((prevIndex) =>
            prevIndex === challenges.length - 1 ? prevIndex : prevIndex + 1
        );
    };
    const handleStartChallenge = () => {
        setIsLocked(false);
        setIsModalOpen(true);
    }

    // const handleCloseModal = (e) => {
    //     if (modalRef.current && !modalRef.current.contains(e.target)) {
    //         setIsModalOpen(false);
    //     }
    // };

    const handleCloseModal = (e) => {
        if (
            modalRef.current &&
            !modalRef.current.contains(e.target) &&
            !e.target.classList.contains("close-icon") // Check if clicked on the cross icon
        ) {
            setIsModalOpen(false);
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            document.addEventListener("mousedown", handleCloseModal);
        } else {
            document.removeEventListener("mousedown", handleCloseModal);
        }
        return () => {
            document.removeEventListener("mousedown", handleCloseModal);
        };
    }, [isModalOpen]);

    const getUserRank = () => {
        const userIndex = leaderboardData?.findIndex(data => data?.username === myData?.fullName);
        if (userIndex >= 0 && userIndex < 3) {
            return leaderboardData?.[3] ? [leaderboardData?.[3]] : [];
        } else if (userIndex >= 0) {
            return leaderboardData?.[userIndex] ? [leaderboardData?.[userIndex]] : [];
        }
        return [];
    };

    function formatMinutesToHours(minutes) {
        // Calculate hours and remaining minutes
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        // Build the formatted string
        if (remainingMinutes === 0) {
            return `${hours}h`;
        } else {
            return `${hours}h ${remainingMinutes}m`;
        }
    }

    const filteredLeaderboard = fullLeaderboard
        ? leaderboardData
        : leaderboardData?.slice(0, 3)?.concat(getUserRank());

    const shuffleArray = (array) => {
        let shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };

    const renderAvatars = () => {
        const shuffledLeaderboard = shuffleArray(filteredLeaderboard); // Shuffle the leaderboard data

        const avatarsToShow = shuffledLeaderboard.slice(0, 2); // Get the first two users after shuffle

        return avatarsToShow.map((user) => {
            const avatarSrc = user.user_avatar || avatar[user.gender] || avatar[""];

            return (
                <div key={user._id} className="user-div">
                    <img src={avatarSrc} alt={user.username} />
                </div>
            );
        });
    };

    const getChallengeProperties = () => {
        switch (currentChallenge?.challengeName) {
          case 'SocialMediaDetox':
            return {
              animation: digitalDetoxChallenge,
              backgroundColor: "#FADD8A",
              bottomBackgroundColor: "#F6D169",
            };
          case 'Steps':
            return {
              animation: runningGirl,
              backgroundColor: "#FADD8A",
              bottomBackgroundColor: "#F6D169",
            };
          case 'Calories':
            return {
              animation: calorieBurnChallenge,
              backgroundColor: "#DCF6E9",
              bottomBackgroundColor: "#C4EED9",
            };
          case 'Sleep':
            return {
              animation: sleepChallenge,
              backgroundColor: "#96A8FF",
              bottomBackgroundColor: "#B4BDE9",
            };
          default:
            return {
              animation: runningGirl,
              backgroundColor: "#FADD8A",
              bottomBackgroundColor: "#F6D169",
            };
        }
      };
      

      const { animation, backgroundColor } = getChallengeProperties();
    //   console.log({animation, backgroundColor})
    return (
        <div className="challenges-page">
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="Challenge-modal-content" ref={modalRef}>
                        <img 
                            src="https://ucarecdn.com/bd5c587e-94f4-4cd0-bf00-5d805509508d/crossIcon.svg" 
                            alt="Close" 
                            className="close-icon" 
                            onClick={() => setIsModalOpen(false)}
                        />
                        <p style={{fontSize:'1.5em',fontWeight:'800px',margin:'0px'}}>Want to track your progress in the challenge? </p>
                        <p>The best way to do it is through our mobile app. Just download the GoodLives app and you’ll be able to easily join the challenge and keep track of your progress! 📱📈</p>
                        <div className="image-row">
                            <a href="https://play.google.com/store/apps/details?id=com.goodlives.wellness&hl=en_IN">
                                <img src="https://ucarecdn.com/0c775cb4-3b83-4835-b550-8c470657ed18/playStoreImage.svg" alt="Play Store logo" className="store-icon" />
                            </a>
                            <a href="https://apps.apple.com/in/app/goodlives/id6740541252">
                                <img src="https://ucarecdn.com/2788f9eb-7f8c-4307-a871-1b8e1cc3abb6/appStoreImage.svg" alt="App Store Logo" className="store-icon"/>
                            </a>
                        </div>
                    </div>
                </div>
            )}
            <div className="challenges-wrapper">
                <div className="challenges-top">
                    <div className="challenges-tleft">
                        <div className="challenge-container">
                            <button
                                onClick={handleLeftClick}
                                className="nav-button left"
                                disabled={currentChallengeIndex === 0}
                                style={{ visibility: currentChallengeIndex === 0 ? "hidden" : "visible" }}
                            >
                                <img src="https://ucarecdn.com/07a210d9-454a-445e-80e0-0cf17e04133f/leftArrow.svg" alt="left arrow" />
                            </button>
                            <div className="challenge-icontainer" style={{background: backgroundColor}}>
                                {isLoadingChallenges ? (
                                    <Skeleton height={330} width={350} />
                                ) : (
                                <Lottie
                                    animationData={animation}
                                    className="lottieAnimation"
                                    style={{ backgroundColor: backgroundColor}}
                                    loop={true}
                                />
                                )}
                            </div>
                            <div className="challenge-name" style={{background: backgroundColor}}>
                                {isLoadingChallenges ? (
                                    <Skeleton width={200} height={20} />
                                ) : (
                                <span className="head">{currentChallenge?.challengeName} Challenge</span>
                                )}
                            </div>
                            {currentChallenge?.isParticipating && <div className="challenge-users">
                                {renderAvatars()}
                                {leaderboardData.length > 2 && (
                                    <div className="user-div">
                                        <p>+{leaderboardData.length - 2}</p>
                                    </div>
                                )}
                            </div>}
                            <button
                                onClick={handleRightClick}
                                className="nav-button right"
                                disabled={currentChallengeIndex === challenges.length - 1}
                                style={{ visibility: currentChallengeIndex === challenges.length - 1 ? "hidden" : "visible" }}
                            >
                                <img src="https://ucarecdn.com/825c2eb7-b1b3-4b9b-a229-09beaf05861f/rightArrow.svg" alt="right arrow" />
                            </button>
                        </div>
                        {
                            !currentChallenge?.isParticipating &&
                            <div className="challenge-info">
                                <div className="days"><p>{currentChallenge?.totalDays} Days</p></div>
                                <div className="users">
                                    <div className="user-div"><img src={avatar["male"]} /></div>
                                    <div className="user-div"><img src={avatar["female"]} /></div>
                                    <div className="user-div"><img src={avatar["male"]} /></div>
                                    <div className="user-div"><img src={avatar["female"]} /></div>
                                    <div className="user-div">
                                        <p>{leaderboardData.length}</p>
                                    </div>
                                </div>
                                <div className="coins">
                                    <img src="/images/coins.png" />
                                    <span>{challengeInfo.coinsForChallenge}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="challenges-tright">
                        {/* {
                            !isLocked &&
                            <div className="dailygoal-div mobile">
                                <p className="head">You have completed <span className="value">{(userCurrentProgress / currentChallenge?.dailygoal) * 100}%</span> of your daily goal</p>
                                <div className="goalprogress-div">
                                    <Progress
                                        type="dashboard"
                                        format={(percent) => (
                                            <span>
                                                <p className="goal-completion">{userCurrentProgress}</p> Steps out of{' '}
                                                <p className="target-steps">{currentChallenge?.dailygoal}</p>
                                            </span>
                                        )}
                                        percent={(userCurrentProgress / currentChallenge?.dailygoal) * 100}
                                        strokeColor={"#49AF7C"}
                                        strokeWidth={5}
                                    />
                                </div>
                            </div>
                        } */}
                        <div className="win-divs">
                            {!currentChallenge?.isParticipating && <p className="head">How to win this challenge?</p>}
                            <div className="win-values">
                                {
                                    // Dynamically create the win challenge sections based on currentChallenge
                                    [
                                        {
                                            name: "Rank",
                                            value: currentChallenge?.minimumRank,  // Use minimumRank from currentChallenge
                                        },
                                        {
                                            name: "Days Left",
                                            value: Math.max(0, Math.floor((new Date(currentChallenge?.enddate) - new Date()) / (1000 * 60 * 60 * 24))),  // Days Left calculation based on enddate
                                        },
                                        {
                                            name: `Average ${currentChallenge?.challengeName}`,
                                            value: (currentChallenge?.challengeName === "Sleep" || currentChallenge?.challengeName === "SocialMediaDetox")
                                                ? formatMinutesToHours(currentChallenge?.minAverageValue)
                                                : currentChallenge?.minAverageValue,
                                        }
                                    ].map((obj, i) => (
                                        <div className="win-div" key={i}>
                                            <p className="value"><b>{obj.value}</b>{" "}{obj.name}</p>
                                            <div className="meter">
                                                <div
                                                    style={{
                                                        width:
                                                            obj.name === "Rank" ? `50%` :
                                                                obj.name === "Days Left" ? `50%` :  // Calculate width for Days Left
                                                                    obj.name === `Average ${currentChallenge?.challengeName}` ? `50%` : // Calculate width for Average Steps
                                                                        'auto'
                                                    }}
                                                    className="meter-value"
                                                ></div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        {
                            !currentChallenge?.isParticipating &&
                            <button className="start-cha" type="submit" onClick={handleStartChallenge}>
                                Start Now
                            </button>
                        }
                    </div>
                </div>
            </div>
            <div className="challenges-bottom">
                {
                    currentChallenge?.isParticipating &&
                    <>
                        <div className="dailygoal-div">
                            <div className="dailygoal-left">
                                <div className="goalprogress-div">
                                    <Progress type="circle" percent={((userCurrentProgress / currentChallenge?.dailygoal) * 100).toFixed(1)} strokeColor={"#49AF7C"} strokeWidth={12} />
                                </div>
                                <p className="dailygoal-head">Daily Goal Completion</p>
                            </div>
                            <div className="dailygoal-divide"></div>
                            <div className="dailygoal-right">
                                <p className="head">{`${(currentChallenge?.challengeName === "SocialMediaDetox" || currentChallenge?.challengeName === "Sleep") ? formatMinutesToHours(userCurrentProgress) : userCurrentProgress} ${currentChallenge?.challengeName} Today`}</p>
                                <div className="target-div">
                                    <div className="target">
                                        <div className="target-value" style={{ width: `${(userCurrentProgress / currentChallenge?.dailygoal) * 100}%` }} ></div>
                                    </div>
                                    <p className="target-txt">{(currentChallenge?.challengeName === "Sleep" || currentChallenge?.challengeName === "SocialMediaDetox")
                                        ? formatMinutesToHours(currentChallenge?.dailygoal)
                                        : currentChallenge?.dailygoal}</p>
                                </div>
                                <p className="target-txt">{`${currentChallenge?.challengeName} Target`}</p>
                            </div>
                        </div>
                    </>
                }

                <div className={`leaderboard-container ${!currentChallenge?.isParticipating ? "blurred" : fullLeaderboard ? "viewall" : ""}`}>
                    {!currentChallenge?.isParticipating && (
                        <div className="lock-overlay">
                            <IoLockClosedOutline size={30} className="icon" />
                        </div>
                    )}
                    <div className={`user-leaderboard ${!currentChallenge?.isParticipating ? "blurred" : fullLeaderboard ? "viewall" : ""}`}>
                        <div className="leaderboard-top">
                            <div className="leaderboard-tleft">
                                <img src="https://ucarecdn.com/e39edccf-47aa-46a5-9356-ed1003b3d3f4/prize.png" alt="prize" />
                                <p>Leaderboard</p>
                                <img src="https://ucarecdn.com/e39edccf-47aa-46a5-9356-ed1003b3d3f4/prize.png" alt="prize" />
                            </div>
                            {
                                fullLeaderboard ?
                                    <div className="close-uleaderboard" onClick={handleCloseLeaderboard}>
                                        <IoClose size={20} />
                                    </div>
                                    :
                                    <div className="view-uleaderboard">
                                        <p onClick={() => setFullLeaderboard(true)}>
                                            View All
                                        </p>
                                    </div>
                            }

                        </div>
                        <div className="ltable-div" ref={ltableDivRef}>
                            <div className="ltable-head">
                                <div className="hrank">
                                    Rank
                                </div>
                                <div className="hname">
                                    Name
                                </div>
                                <div className="hasteps">
                                    Daily Avg. {currentChallenge?.challengeName}
                                </div>
                                <div className="hsteps">
                                    Total {currentChallenge?.challengeName}
                                </div>
                                <div className="hdays">
                                    Days participated
                                </div>
                                {
                                    categoryId == 2 &&
                                    <div className="hteam">
                                        Team
                                    </div>
                                }
                            </div>
                            <div className="ltable-body">
                                {filteredLeaderboard?.map((data, index) => (
                                    <div className="ltable-box" key={index}>
                                        <div className="rank">
                                            {data?.newposition === 1 ? (
                                                <img src="https://ucarecdn.com/3306e177-28f6-43ea-807d-c015117dac92/first.png" alt="first" />
                                            ) : data?.newposition === 2 ? (
                                                <img src="https://ucarecdn.com/a344e25e-71c9-47ce-904b-4de2bf824226/second.png" alt="second" />
                                            ) : data?.newposition === 3 ? (
                                                <img src="https://ucarecdn.com/2d39e42a-af1a-487d-960d-de79464340cd/third.png" alt="third" />
                                            ) : (
                                                <p>{data?.newposition}</p>
                                            )}
                                        </div>
                                        <div className="name">
                                            <img src={data?.user_avatar ? data?.user_avatar : avatar[data?.gender]} alt={data.username} />
                                            <p>{data?.username}</p>
                                        </div>
                                        <div className="asteps">
                                            <p>{`${data?.averageDailyValue} ${currentChallenge?.challengeName}`}</p>
                                        </div>
                                        <div className="steps">
                                            <p>{`${data?.totalValue} ${currentChallenge?.challengeName}`}</p>
                                        </div>
                                        <div className="days">
                                            <p>{data?.participatedInDays}</p>
                                        </div>
                                        {
                                            categoryId == 2 &&
                                            <div className="team">
                                                <p>{data?.userTeamName || ""}</p>
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Challenges;