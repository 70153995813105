import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import HeaderHomeScreen from "../../components/HeaderHomeScreen";
import "./index.css";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { useUserInfoSelector, useUserTokenSelector } from "store/reducers";
import { getGoogleClientTeamLocation, getTeamLocation, signUpB2B, signUpB2C, updateProfileB2B, updateUserProfile } from "actions/auth";
import { setAvailableFeatures, setUserInfo, setUserToken, startLoading, stopLoading } from "store/actions";
import { useDispatch } from "react-redux";
import { loadAllFeatures } from "actions/dataLoad";
import CryptoJS from "crypto-js";
import Lottie from "lottie-react";
import { motion } from "motion/react";
import blissWavingHand from "../../assets/JsonAnimations/blissWavingHand.json";

const genderOptions = [
  { id: "male", src: "https://ucarecdn.com/52c99efd-cba4-4fe1-93f2-e393088b7a0c/male.svg", label: "Male" },
  { id: "female", src: "https://ucarecdn.com/c22b8f55-8c68-411e-a5c9-8b5b99c08701/femaleEmoji.svg", label: "Female" },
  { id: "queer", src: "https://ucarecdn.com/c7bb4c01-2258-428d-b8ce-e2196ee63220/lgbtq.svg", label: "Queer" },
];

const callingCodes = [
  { id: 1, name: 'Afghanistan', code: '+93' },
  { id: 2, name: 'Albania', code: '+355' },
  { id: 3, name: 'Algeria', code: '+213' },
  { id: 4, name: 'Andorra', code: '+376' },
  { id: 5, name: 'Angola', code: '+244' },
  { id: 6, name: 'Antigua and Barbuda', code: '+1-268' },
  { id: 7, name: 'Argentina', code: '+54' },
  { id: 8, name: 'Armenia', code: '+374' },
  { id: 9, name: 'Australia', code: '+61' },
  { id: 10, name: 'Austria', code: '+43' },
  { id: 11, name: 'Azerbaijan', code: '+994' },
  { id: 12, name: 'Bahamas', code: '+1-242' },
  { id: 13, name: 'Bahrain', code: '+973' },
  { id: 14, name: 'Bangladesh', code: '+880' },
  { id: 15, name: 'Barbados', code: '+1-246' },
  { id: 16, name: 'Belarus', code: '+375' },
  { id: 17, name: 'Belgium', code: '+32' },
  { id: 18, name: 'Belize', code: '+501' },
  { id: 19, name: 'Benin', code: '+229' },
  { id: 20, name: 'Bhutan', code: '+975' },
  { id: 21, name: 'Bolivia', code: '+591' },
  { id: 22, name: 'Bosnia and Herzegovina', code: '+387' },
  { id: 23, name: 'Botswana', code: '+267' },
  { id: 24, name: 'Brazil', code: '+55' },
  { id: 25, name: 'Brunei Darussalam', code: '+673' },
  { id: 26, name: 'Bulgaria', code: '+359' },
  { id: 27, name: 'Burkina Faso', code: '+226' },
  { id: 28, name: 'Burundi', code: '+257' },
  { id: 29, name: 'Cambodia', code: '+855' },
  { id: 30, name: 'Cameroon', code: '+237' },
  { id: 31, name: 'Canada', code: '+1' },
  { id: 32, name: 'Cape Verde', code: '+238' },
  { id: 33, name: 'Cayman Islands', code: '+1-345' },
  { id: 34, name: 'Central African Republic', code: '+236' },
  { id: 35, name: 'Chad', code: '+235' },
  { id: 36, name: 'Chile', code: '+56' },
  { id: 37, name: 'China', code: '+86' },
  { id: 38, name: 'Colombia', code: '+57' },
  { id: 39, name: 'Comoros', code: '+269' },
  { id: 40, name: 'Congo (Congo-Brazzaville)', code: '+242' },
  { id: 41, name: 'Congo (Congo-Kinshasa)', code: '+243' },
  { id: 42, name: 'Costa Rica', code: '+506' },
  { id: 43, name: 'Croatia', code: '+385' },
  { id: 44, name: 'Cuba', code: '+53' },
  { id: 45, name: 'Cyprus', code: '+357' },
  { id: 46, name: 'Czech Republic', code: '+420' },
  { id: 47, name: 'Denmark', code: '+45' },
  { id: 48, name: 'Djibouti', code: '+253' },
  { id: 49, name: 'Dominica', code: '+1-767' },
  { id: 50, name: 'Dominican Republic', code: '+1-809' },
  { id: 51, name: 'Ecuador', code: '+593' },
  { id: 52, name: 'Egypt', code: '+20' },
  { id: 53, name: 'El Salvador', code: '+503' },
  { id: 54, name: 'Equatorial Guinea', code: '+240' },
  { id: 55, name: 'Eritrea', code: '+291' },
  { id: 56, name: 'Estonia', code: '+372' },
  { id: 57, name: 'Eswatini', code: '+268' },
  { id: 58, name: 'Ethiopia', code: '+251' },
  { id: 59, name: 'Fiji', code: '+679' },
  { id: 60, name: 'Finland', code: '+358' },
  { id: 61, name: 'France', code: '+33' },
  { id: 62, name: 'Gabon', code: '+241' },
  { id: 63, name: 'Gambia', code: '+220' },
  { id: 64, name: 'Georgia', code: '+995' },
  { id: 65, name: 'Germany', code: '+49' },
  { id: 66, name: 'Ghana', code: '+233' },
  { id: 67, name: 'Greece', code: '+30' },
  { id: 68, name: 'Grenada', code: '+1-473' },
  { id: 69, name: 'Guatemala', code: '+502' },
  { id: 70, name: 'Guinea', code: '+224' },
  { id: 71, name: 'Guinea-Bissau', code: '+245' },
  { id: 72, name: 'Guyana', code: '+592' },
  { id: 73, name: 'Haiti', code: '+509' },
  { id: 74, name: 'Honduras', code: '+504' },
  { id: 75, name: 'Hungary', code: '+36' },
  { id: 76, name: 'Iceland', code: '+354' },
  { id: 77, name: 'India', code: '+91' },
  { id: 78, name: 'Indonesia', code: '+62' },
  { id: 79, name: 'Iran', code: '+98' },
  { id: 80, name: 'Iraq', code: '+964' },
  { id: 81, name: 'Ireland', code: '+353' },
  { id: 82, name: 'Israel', code: '+972' },
  { id: 83, name: 'Italy', code: '+39' },
  { id: 84, name: 'Jamaica', code: '+1-876' },
  { id: 85, name: 'Japan', code: '+81' },
  { id: 86, name: 'Jordan', code: '+962' },
  { id: 87, name: 'Kazakhstan', code: '+7' },
  { id: 88, name: 'Kenya', code: '+254' },
  { id: 89, name: 'Kiribati', code: '+686' },
  { id: 90, name: 'Korea (North)', code: '+850' },
  { id: 91, name: 'Korea (South)', code: '+82' },
  { id: 92, name: 'Kuwait', code: '+965' },
  { id: 93, name: 'Kyrgyzstan', code: '+996' },
  { id: 94, name: 'Laos', code: '+856' },
  { id: 95, name: 'Latvia', code: '+371' },
  { id: 96, name: 'Lebanon', code: '+961' },
  { id: 97, name: 'Lesotho', code: '+266' },
  { id: 98, name: 'Liberia', code: '+231' },
  { id: 99, name: 'Libya', code: '+218' },
  { id: 100, name: 'Liechtenstein', code: '+423' },
  { id: 101, name: 'Lithuania', code: '+370' },
  { id: 102, name: 'Luxembourg', code: '+352' },
  { id: 103, name: 'Madagascar', code: '+261' },
  { id: 104, name: 'Malawi', code: '+265' },
  { id: 105, name: 'Malaysia', code: '+60' },
  { id: 106, name: 'Maldives', code: '+960' },
  { id: 107, name: 'Mali', code: '+223' },
  { id: 108, name: 'Malta', code: '+356' },
  { id: 109, name: 'Marshall Islands', code: '+692' },
  { id: 110, name: 'Mauritania', code: '+222' },
  { id: 111, name: 'Mauritius', code: '+230' },
  { id: 112, name: 'Mexico', code: '+52' },
  { id: 113, name: 'Micronesia', code: '+691' },
  { id: 114, name: 'Moldova', code: '+373' },
  { id: 115, name: 'Monaco', code: '+377' },
  { id: 116, name: 'Mongolia', code: '+976' },
  { id: 117, name: 'Montenegro', code: '+382' },
  { id: 118, name: 'Morocco', code: '+212' },
  { id: 119, name: 'Mozambique', code: '+258' },
  { id: 120, name: 'Myanmar', code: '+95' },
  { id: 121, name: 'Namibia', code: '+264' },
  { id: 122, name: 'Nauru', code: '+674' },
  { id: 123, name: 'Nepal', code: '+977' },
  { id: 124, name: 'Netherlands', code: '+31' },
  { id: 125, name: 'New Zealand', code: '+64' },
  { id: 126, name: 'Nicaragua', code: '+505' },
  { id: 127, name: 'Niger', code: '+227' },
  { id: 128, name: 'Nigeria', code: '+234' },
  { id: 129, name: 'North Macedonia', code: '+389' },
  { id: 130, name: 'Norway', code: '+47' },
  { id: 131, name: 'Oman', code: '+968' },
  { id: 132, name: 'Pakistan', code: '+92' },
  { id: 133, name: 'Palau', code: '+680' },
  { id: 134, name: 'Panama', code: '+507' },
  { id: 135, name: 'Papua New Guinea', code: '+675' },
  { id: 136, name: 'Paraguay', code: '+595' },
  { id: 137, name: 'Peru', code: '+51' },
  { id: 138, name: 'Philippines', code: '+63' },
  { id: 139, name: 'Poland', code: '+48' },
  { id: 140, name: 'Portugal', code: '+351' },
  { id: 141, name: 'Puerto Rico', code: '+1-787' },
  { id: 142, name: 'Qatar', code: '+974' },
  { id: 143, name: 'Romania', code: '+40' },
  { id: 144, name: 'Russia', code: '+7' },
  { id: 145, name: 'Rwanda', code: '+250' },
  { id: 146, name: 'Saint Kitts and Nevis', code: '+1-869' },
  { id: 147, name: 'Saint Lucia', code: '+1-758' },
  { id: 148, name: 'Saint Vincent and the Grenadines', code: '+1-784' },
  { id: 149, name: 'Samoa', code: '+685' },
  { id: 150, name: 'San Marino', code: '+378' },
  { id: 151, name: 'Sao Tome and Principe', code: '+239' },
  { id: 152, name: 'Saudi Arabia', code: '+966' },
  { id: 153, name: 'Senegal', code: '+221' },
  { id: 154, name: 'Serbia', code: '+381' },
  { id: 155, name: 'Seychelles', code: '+248' },
  { id: 156, name: 'Sierra Leone', code: '+232' },
  { id: 157, name: 'Singapore', code: '+65' },
  { id: 158, name: 'Slovakia', code: '+421' },
  { id: 159, name: 'Slovenia', code: '+386' },
  { id: 160, name: 'Solomon Islands', code: '+677' },
  { id: 161, name: 'Somalia', code: '+252' },
  { id: 162, name: 'South Africa', code: '+27' },
  { id: 163, name: 'South Sudan', code: '+211' },
  { id: 164, name: 'Spain', code: '+34' },
  { id: 165, name: 'Sri Lanka', code: '+94' },
  { id: 166, name: 'Sudan', code: '+249' },
  { id: 167, name: 'Suriname', code: '+597' },
  { id: 168, name: 'Sweden', code: '+46' },
  { id: 169, name: 'Switzerland', code: '+41' },
  { id: 170, name: 'Syria', code: '+963' },
  { id: 171, name: 'Taiwan', code: '+886' },
  { id: 172, name: 'Tajikistan', code: '+992' },
  { id: 173, name: 'Tanzania', code: '+255' },
  { id: 174, name: 'Thailand', code: '+66' },
  { id: 175, name: 'Togo', code: '+228' },
  { id: 176, name: 'Tonga', code: '+676' },
  { id: 177, name: 'Trinidad and Tobago', code: '+1-868' },
  { id: 178, name: 'Tunisia', code: '+216' },
  { id: 179, name: 'Turkey', code: '+90' },
  { id: 180, name: 'Turkmenistan', code: '+993' },
  { id: 181, name: 'Tuvalu', code: '+688' },
  { id: 182, name: 'Uganda', code: '+256' },
  { id: 183, name: 'Ukraine', code: '+380' },
  { id: 184, name: 'United Arab Emirates', code: '+971' },
  { id: 185, name: 'United Kingdom', code: '+44' },
  { id: 186, name: 'United States', code: '+1' },
  { id: 187, name: 'Uruguay', code: '+598' },
  { id: 188, name: 'Uzbekistan', code: '+998' },
  { id: 189, name: 'Vanuatu', code: '+678' },
  { id: 190, name: 'Vatican City', code: '+39' },
  { id: 191, name: 'Venezuela', code: '+58' },
  { id: 192, name: 'Vietnam', code: '+84' },
  { id: 193, name: 'Yemen', code: '+967' },
  { id: 194, name: 'Zambia', code: '+260' },
  { id: 195, name: 'Zimbabwe', code: '+263' },
];

let userSpaceInfo = {};

const BuildYourSpace = () => {
  const location = useLocation();
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const [fromGoogleSignUp, setFromGoogleSignUp] = useState(searchParams.get("google") || false);
  const googleUserName = searchParams.get("name") || "";
  const googleUserEmail = searchParams.get("email") || "";
  const [phone, setPhone] = useState(location.state?.phone || "");
  const countryCode = location.state?.countryCode || "";
  const [alreadyEnteredEmail, setAlreadyEnteredEmail] = useState(location.state?.email || "");
  const [email, setEmail] = useState(location?.state?.email || "");
  const password = location?.state?.password || "";
  const [loggedOutMessage, setLoggedOutMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedGender, setSelectedGender] = useState("female");
  const [name, setName] = useState(Boolean(fromGoogleSignUp) ? googleUserName : "");
  const [otp, setOtp] = useState(location.state?.otp || "");
  const [age, setAge] = useState("");
  const [number, setNumber] = useState(location.state?.b2cRedirectPhone || "");
  const [team, setTeam] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const userToken = useUserTokenSelector();
  const [referralCode, setReferralCode] = useState("");
  const dispatch = useDispatch();
  const [plannerQuestions, setPlannerQuestions] = useState([]);
  const userInfo = useUserInfoSelector();
  const [currentPage, setCurrentPage] = useState(1);
  const isLastScreen = currentPage * 2 >= plannerQuestions.length;
  const [teamOptions, setTeamOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [countryCodeValue, setCountryCodeValue] = useState("+91");
  const ageRef = useRef(null);
  const otpRef = useRef(null);
  const numberRef = useRef(null);
  const referralCodeRef = useRef(null);
  const emailRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const [showCorporateRedirectModal,setShowCorporateRedirectModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const { isBack } = location.state || {};

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if ((alreadyEnteredEmail && otp.length === 6) || fromGoogleSignUp) {
      getClientTeamLocation();
    }
  }, [otp]);

  const getClientTeamLocation = async () => {
    try {
      if (Boolean(fromGoogleSignUp)) {
        const response = await getGoogleClientTeamLocation(userToken);
        if (response && response.data && response.data.teamLocations) {
          // Group the team locations by teamName
          const groupedData = response.data.teamLocations.reduce((acc, { teamName, location }) => {
            if (!acc[teamName]) {
              acc[teamName] = [];
            }
            acc[teamName].push(location);
            return acc;
          }, {});
          setGroupedData(groupedData);

          setTeamOptions(Object.keys(groupedData));
          if (isBack && team && groupedData[team]) {
            setLocationOptions(groupedData[team]);
          }
        }
      } else {
        const response = await getTeamLocation({ email: alreadyEnteredEmail, otp });

        if (response && response.data && response.data.teamLocations) {
          // Group the team locations by teamName
          const groupedData = response.data.teamLocations.reduce((acc, { teamName, location }) => {
            if (!acc[teamName]) {
              acc[teamName] = [];
            }
            acc[teamName].push(location);
            return acc;
          }, {});
          setGroupedData(groupedData);

          setTeamOptions(Object.keys(groupedData));
          if (isBack && team && groupedData[team]) {
            setLocationOptions(groupedData[team]);
          }
        }
      }
    } catch (error) {
      setSeverity("error");
      setLoggedOutMessage(error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
      setOpen(true);
      console.error("error", error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
    }
  };

  useEffect(() => {
    if (team && groupedData[team]) {
      setLocationOptions(groupedData[team]);
      setSelectedLocation(groupedData[team][0]); // Set default location for the selected team
    } else {
      setLocationOptions([]);
      setSelectedLocation("");
    }
  }, [team]);

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
    setLoggedOutMessage("");
  };

  const moveToOnboardingQues = (canGoBack=true) => {
    if (canGoBack) {
      userSpaceInfo = {
        fullName: name,
        gender: selectedGender,
        otp: otp,
        age: age,
        email: email,
        number: phone || number,
        teamName: team,
        teamLocation: selectedLocation,
        referralCode: referralCode,
        countryCode: countryCode || countryCodeValue,
        phone: phone,
        fromGoogleSignUp: fromGoogleSignUp,
        alreadyEnteredEmail: alreadyEnteredEmail,
        teamOptions,
        locationOptions
      };
    }
    history.push("/onboardingQuestions", { signup: true, canGoBack });
  }

  const handleSignUpB2B = async () => {
    if(!name || !selectedGender || !age || !number || !otp || !selectedLocation || !team) {
      setSeverity("error");
      setLoggedOutMessage("Please provide all details");
      setOpen(true);
    } else {
    try {
      dispatch(startLoading());
      const passwordCipher = CryptoJS.AES.encrypt(
        JSON.stringify(password),
        process.env.REACT_APP_PASSWORD_ENCRYPT_KEY
      ).toString();
      const response = await signUpB2B({
        email: email,
        confirmPassword: passwordCipher,
        password: passwordCipher,
        countryCode: countryCodeValue,
        fullName: name,
        gender: selectedGender,
        teamLocation: selectedLocation,
        teamName: team,
        otp: otp,
        phone: number,
        age: Number(age),
        devicetoken: null,
        deviceType: "web",
      });
      if (response.data.status === "success") {
        dispatch(stopLoading());
        window.localStorage.setItem("auth", JSON.stringify(response.data));
        dispatch(setUserInfo(response.data.user));
        dispatch(setUserToken(response.data.token));
        const allFeatures = await loadAllFeatures(response.data.token);

        dispatch(setAvailableFeatures(allFeatures.data));
        moveToOnboardingQues();
      } else {
        dispatch(stopLoading());
      }
    } catch (error) {
      setSeverity("error");
      setLoggedOutMessage(error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
      setOpen(true);
    } finally {
      dispatch(stopLoading());
    }
  }
  };

  const handleSignUpB2C = async () => {
    if(!name || !selectedGender || !age) {
      setSeverity("error");
      setLoggedOutMessage("Please provide all details");
      setOpen(true);
    } else {
    try {
      dispatch(startLoading());
      const response = await signUpB2C({
        email: email,
        countryCode: countryCode,
        fullName: name,
        gender: selectedGender,
        otp: otp,
        phone: phone,
        referralCode: referralCode,
        age: Number(age),
        devicetoken: null,
        deviceType: "web",
      });
      if (response.data.status === "success") {
        dispatch(stopLoading());
        window.localStorage.setItem("auth", JSON.stringify(response.data));
        dispatch(setUserInfo(response.data.user));
        dispatch(setUserToken(response.data.token));
        moveToOnboardingQues();
      } else {
        dispatch(stopLoading());
      }
    } catch (error) {
      if (error.response.data.isCorporateRedirect) {
        setShowCorporateRedirectModal(true);
        setLoggedOutMessage(error.response?.data?.error || "An unknown error occurred")
        return;
      }
      setSeverity("error");
      setLoggedOutMessage(error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
      setOpen(true);
    } finally {
      dispatch(stopLoading());
    }
  }
  };

  const handleGoogleSignUp = async () => {
    try {
      dispatch(startLoading());
      const response = await updateProfileB2B(
        {
          fullName: googleUserName,
          phone: number,
          teamName: team,
          teamLocation: selectedLocation,
          countryCode: countryCodeValue,
          deviceType: "web",
          devicetoken: null,
          gender: selectedGender,
          age: Number(age),
        },
        userToken
      );
      if (response.data.status === "success") {
        dispatch(stopLoading());
        window.localStorage.setItem("auth", JSON.stringify(response.data));
        dispatch(setUserInfo(response.data.user));
        moveToOnboardingQues();
      } else {
        dispatch(stopLoading());
      }
    } catch (error) {
      setSeverity("error");
      setLoggedOutMessage(error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
      setOpen(true);
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleUpdateUserProfile = async () => {
    try {
      if (!phone && (!team || !selectedLocation)) {
        setSeverity("error");
        setLoggedOutMessage("Please provide team name and location");
        setOpen(true);
      }

      dispatch(startLoading());
      const response = await updateUserProfile(userToken, {
        email: email,
        fullName: name,
        mobileNo: phone || number,
        teamName: team,
        teamLocation: selectedLocation,
        countryCode: countryCode || countryCodeValue,
        gender: selectedGender,
        age: Number(age),
      });
      if (response.data.status === "success") {
        dispatch(stopLoading());
        moveToOnboardingQues(false);
      }
    }
    catch (error) {
      setSeverity("error");
      setLoggedOutMessage(error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
      setOpen(true);
    }
    dispatch(stopLoading());
  }

  const handleSignUp = async () => {
    if (isBack) {
      handleUpdateUserProfile();
      return;
    }
    if (phone) {
      await handleSignUpB2C();
    } else if (Boolean(fromGoogleSignUp)) {
      handleGoogleSignUp();
    } else {
      await handleSignUpB2B();
    }
  };

  const handleNumberKeypress = (e) => {
    if (e.key === "Enter") {
      handleSignUp()
    }
  }

  const handleCorporateRedirectClick = (agree) => {
    if (agree) {
      history.replace("/signup", { b2cRedirectPhone: phone, b2cRedirectEmail: email })
    }
    else {
      setSeverity("error");
      setShowCorporateRedirectModal(false);
      setOpen(true);
    }
  }

  const handleSelectChange = (code) => {
    setCountryCodeValue(code);
    setShowDropdown(false);
  };
  
  useEffect (() => {
    if (isBack) {
      const { fullName, otp, gender, age, email, number, teamName, teamLocation, referralCode, countryCode, phone, fromGoogleSignUp, alreadyEnteredEmail, teamOptions, locationOptions } = userSpaceInfo;
      setName(fullName);
      setOtp(otp);
      setSelectedGender(gender);
      setAge(age);
      setEmail(email);
      setNumber(number);
      setTeam(teamName);
      setSelectedLocation(teamLocation);
      setReferralCode(referralCode);
      setCountryCodeValue(countryCode);
      setPhone(phone);
      setFromGoogleSignUp(fromGoogleSignUp);
      setAlreadyEnteredEmail(alreadyEnteredEmail);
    }
  }, [isBack])

  return (
    <div>
      <HeaderHomeScreen />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {loggedOutMessage}
        </Alert>
      </Snackbar>
      {isMobile ? (
          <div className="mobileScree-bys-screen-main-container">
          <div>
          <h2 className="mobileScreen-heading">Build Your Space</h2>
        </div>
        <div className="progress-indicator" style={{marginLeft:'20px', marginTop:'10px', marginBottom:'20px'}}>
        <span className={`step ${currentPage >= 1 ? "active" : ""}`}></span>
        <span className={`step ${currentPage >= 2 ? "active" : ""}`}></span>
        <span className={`step ${currentPage >= 3 ? "active" : ""}`}></span>
      </div>
      <p className="bys-box-heading" style={{ paddingTop: !fromGoogleSignUp && !alreadyEnteredEmail ? "20px" : "0px" }}>Select gender</p>
            <div className="gender-selection" style={{paddingBottom: !fromGoogleSignUp && !alreadyEnteredEmail ? "10px" : "0px"}}>
              {genderOptions.map((option) => (
                <div
                  key={option.id}
                  className={`gender-option ${selectedGender === option.id ? "selected" : ""}`}
                  onClick={() => setSelectedGender(option.id)}
                >
                  <img src={option.src} alt={option.label} />
                  <p>{option.label}</p>
                </div>
              ))}
            </div>
            <div className="seperation-line"></div>

            <div className="build-your-space-form-inner-stuff-lcm" style={{width:'90%'}}>
              <input style={{width:'100%'}} placeholder="Full name*" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            {!isBack && !Boolean(fromGoogleSignUp)
              ? !phone && (
                  <p className="bys-otp-txt">
                    We've sent you a one time password on <b>{alreadyEnteredEmail}</b>
                  </p>
                )
              : null}
            {!isBack && !Boolean(fromGoogleSignUp)
              ? !phone && (
                  <div className="build-your-space-form-inner-stuff-lcm" style={{width:'90%'}}>
                    <input style={{width:'100%'}} placeholder="Enter OTP*" value={otp} onChange={(e) => setOtp(e.target.value)} />
                  </div>
                )
              : null}
                <div className="build-your-space-form-inner-stuff-lcm" style={{width:'90%'}}>
                  <input
                    placeholder="Your age*"
                    value={age}
                    style={{ width: "100%" }}
                    maxLength={2}
                    onChange={(e) => setAge(e.target.value)}
                  />
                </div>
                {!phone ? (
                <div className="bys-dropdown-container">
                <div className="bys-input-box" onClick={() => setShowDropdown(!showDropdown)}>
                  <span className="bys-selected-code">{countryCodeValue}</span>
                  <span className="bys-arrow">▼</span>
                </div>
                {showDropdown && (
                  <ul className="bys-dropdown-list">
                    {callingCodes.map((country) => (
                      <li key={country.id} onClick={() => handleSelectChange(country.code)}>
                        {country.name} ({country.code})
                      </li>
                    ))}
                  </ul>
                )}
                <input
                  type="tel"
                  style={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    flex: 1,
                  }}
                  placeholder="Enter whatsapp number"
                  className="bys-phone-input"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  onKeyPress={handleNumberKeypress}
                  maxLength={10}
                />
              </div>
                
                ) : (
                  <div className="build-your-space-form-inner-stuff-lcm" style={{width:'90%'}}>
                    <input
                      placeholder="Enter email*"
                      value={email}
                      style={{ width: "100%" }}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                )}

            {!phone && teamOptions.length > 0 &&(
              <div className="form-row" style={{width:'90%'}}>
                <div className="build-your-space-form-inner-stuff-lcm" style={{width:'48%'}}>
                  <select value={team} onChange={(e) => setTeam(e.target.value)}>
                    <option value="" disabled> 
                      Select Team
                    </option>
                    {teamOptions.map((teamName) => (
                      <option key={teamName} value={teamName}>
                        {teamName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="build-your-space-form-inner-stuff-lcm" style={{width:'48%'}}>
                  <select
                    value={selectedLocation}
                    onChange={(e) => setSelectedLocation(e.target.value)}
                  >
                    <option value="" disabled>
                      Select Location
                    </option>
                    {locationOptions.map((location) => (
                      <option key={location} value={location}>
                        {location}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            {!Boolean(fromGoogleSignUp) && !alreadyEnteredEmail ? (
              <div className="build-your-space-form-inner-stuff-lcm" style={{width:'90%'}}>
                <input style={{width:'100%'}} placeholder="Enter referral code (optional)" value={referralCode} onChange={(e) => setReferralCode(e.target.value)} onKeyPress={handleNumberKeypress}/>
              </div>
            ) : null}

            <div className="mobileScreen-bys-btn-Continuemain">
              <button onClick={handleSignUp}>Next</button>
            </div>
      </div>
        ) : (
        <>
      <div className="bys-screen-main-container">
        <div className="bys-box">
          <>
          <div className="progress-indicator">
            <span className={`step ${currentPage >= 1 ? "active" : ""}`}></span>
            <span className={`step ${currentPage >= 2 ? "active" : ""}`}></span>
            <span className={`step ${currentPage >= 3 ? "active" : ""}`}></span>
          </div>

            <p className="bys-box-heading" style={{ paddingTop: !fromGoogleSignUp && !alreadyEnteredEmail ? "20px" : "0px" }}>Select your gender</p>
            {/* Gender Selection Section */}
            <div className="gender-selection" style={{paddingBottom: !fromGoogleSignUp && !alreadyEnteredEmail ? "10px" : "0px"}}>
              {genderOptions.map((option) => (
                <div
                  key={option.id}
                  className={`gender-option ${selectedGender === option.id ? "selected" : ""}`}
                  onClick={() => setSelectedGender(option.id)}
                >
                  <img src={option.src} alt={option.label} />
                  <p>{option.label}</p>
                </div>
              ))}
            </div>
            <div className="seperation-line"></div>
            
            <div className="build-your-space-form-inner-stuff-lcm">
              <input style={{width:'590px',marginTop: !fromGoogleSignUp && !alreadyEnteredEmail ? "10px" : "0px"}} placeholder="Full name*"  value={name} onChange={(e) => setName(e.target.value)} 
                            onKeyPress={(e) => {
                              if(!phone) {
                                otpRef.current?.focus();
                              } else {
                                ageRef.current?.focus();
                              }
                            }}
              />
            </div>
            {!isBack && !Boolean(fromGoogleSignUp)
              ? !phone && (
                  <p className="bys-otp-txt">
                    We've sent you a one time password on <b>{alreadyEnteredEmail}</b>
                  </p>
                )
              : null}
            {!isBack && !Boolean(fromGoogleSignUp)
              ? !phone && (
                  <div className="build-your-space-form-inner-stuff-lcm">
                    <input style={{width:'590px'}} ref={otpRef} placeholder="Enter OTP*" value={otp} onChange={(e) => setOtp(e.target.value)} onKeyPress={(e) => {ageRef.current?.focus()}}/>
                  </div>
                )
              : null}
              <div className="form-row">
                <div className="build-your-space-form-inner-stuff-lcm">
                  <input
                    ref={ageRef}
                    placeholder="Your age*"
                    value={age}
                    style={{ width: "292px" }}
                    maxLength={2}
                    onChange={(e) => setAge(e.target.value)}
                    onKeyPress={(e) => {if(!phone) {numberRef.current?.focus()} else {emailRef.current?.focus()}}}
                  />
                </div>
                {!phone ? (
                  <div className="bys-dropdown-container">
                  <div className="bys-input-box" onClick={() => setShowDropdown(!showDropdown)}>
                    <span className="bys-selected-code">{countryCodeValue}</span>
                    <span className="bys-arrow">▼</span>
                  </div>
                  {showDropdown && (
                    <ul className="bys-dropdown-list">
                      {callingCodes.map((country) => (
                        <li key={country.id} onClick={() => handleSelectChange(country.code)}>
                          {country.name} ({country.code})
                        </li>
                      ))}
                    </ul>
                  )}
                  <input
                    type="tel"
                    style={{
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      flex: 1,
                    }}
                    placeholder="Enter whatsapp number"
                    className="bys-phone-input"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    onKeyPress={handleNumberKeypress}
                    maxLength={10}
                  />
                </div>
                ) : (
                  <div className="build-your-space-form-inner-stuff-lcm">
                    <input
                    ref={emailRef}
                      placeholder="Enter email*"
                      value={email}
                      style={{ width: "292px" }}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyPress={(e) => {referralCodeRef.current?.focus()}}
                    />
                  </div>
                )}
              </div>

            {!phone && teamOptions.length > 0 &&(
              <div className="form-row">
                <div className="build-your-space-form-inner-stuff-lcm">
                  <select value={team} style={{ width: "292px" }} onChange={(e) => setTeam(e.target.value)}>
                    <option value="" disabled> 
                      Select Team
                    </option>
                    {teamOptions.map((teamName) => (
                      <option key={teamName} value={teamName}>
                        {teamName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="build-your-space-form-inner-stuff-lcm">
                  <select
                    value={selectedLocation}
                    style={{ width: "292px" }}
                    onChange={(e) => setSelectedLocation(e.target.value)}
                  >
                    <option value="" disabled>
                      Select Location
                    </option>
                    {locationOptions.map((location) => (
                      <option key={location} value={location}>
                        {location}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            {!Boolean(fromGoogleSignUp) && !alreadyEnteredEmail ? (
              <div className="build-your-space-form-inner-stuff-lcm">
                <input ref={referralCodeRef} style={{width:'590px'}} placeholder="Enter referral code (optional)" value={referralCode} onChange={(e) => setReferralCode(e.target.value)} onKeyPress={handleNumberKeypress}/>
              </div>
            ) : null}

            <div className="bys-btn-Continuemain">
              <button onClick={handleSignUp}>Next</button>
            </div>
          </>
        </div>
        <div className="bys-box-Image" style={{position:'fixed',right:'0px'}}>
          <h2 style={{ textAlign: "left" }}>Build<br />Your<br />Space</h2>
          <Lottie
            animationData={blissWavingHand}
            style={{ height: "280px", width: "280px", marginRight:'-80px' }}
            loop={false}
          />
        </div>
      </div>
      </>
      )}
      { 
        showCorporateRedirectModal && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="user-modal">
            <div className="corporate-redirect-modal"> 
                <p className="corporate-redirect-head">You can avail free therapy sessions on behalf of your company. Would you like to signup with your company email?</p>
                <div className="sbtns">
                    <button className="corporate-redirect-btn" onClick={() => handleCorporateRedirectClick(true)}>Yes</button>
                    <button className="corporate-redirect-btn no" onClick={() => handleCorporateRedirectClick(false)}>No</button>
                </div>
            </div>
        </motion.div>
        )
      }
    </div>
  );
};

export default BuildYourSpace;