import { useSelector } from "react-redux";
import {
  REMOVE_AVAILABLE_FEATURES,
  REMOVE_USER_INFO,
  REMOVE_USER_TOKEN,
  SET_AVAILABLE_FEATURES,
  SET_LOADING,
  SET_USER_INFO,
  SET_USER_TOKEN,
  REMOVE_VIDEO_ITEM,
  SET_VIDEO_ITEM,
  SET_USER_COINS,
  SET_USER_STREAK,
  SET_LIBRARY_DATA,
  SET_LIBRARY_CATEGORIES_LOADING,
  SET_LIBRARY_CATEGORY_LOADING,
  SET_LIBRARY_CATEGORY_DATA,
  SET_lIBRARY_SELECTED_CATEGORY,
  SET_LIBRARY_CATEGORIES,
  REMOVE_LIBRARY_DATA,
} from "./actions";

// auth reducer
const initialAuthState = {
  userToken: "",
};

export const authReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case SET_USER_TOKEN:
      return { ...state, userToken: action.payload };
    case REMOVE_USER_TOKEN:
      return { ...state, userToken: "" };
    default:
      return state;
  }
};

// user reducer
export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return { ...state, ...action.payload };
    case REMOVE_USER_INFO:
      return null;
    case SET_USER_COINS:
      return { ...state, coinsBalance: action.payload};
    case SET_USER_STREAK:
      return { ...state, streakCount: action.payload};
    default:
      return state;
  }
};

// feature reducer
export const featureReducer = (state = null, action) => {
  switch (action.type) {
    case SET_AVAILABLE_FEATURES:
      return { ...state, ...action.payload };
    case REMOVE_AVAILABLE_FEATURES:
      return null;
    default:
      return state;
  }
};

// loading reducer (to show loading gif)
export const loadingReducer = (state = false, action) => {
  switch (action.type) {
    case SET_LOADING:
      return action.payload;
    default:
      return state;
  }
};

// video item reducer
export const VideoDataReducer = (state = null, action) => {
  switch (action.type) {
    case SET_VIDEO_ITEM:
      return { ...state, ...action.payload };
    case REMOVE_VIDEO_ITEM:
      return null;
    default:
      return state;
  }
};

// wellness library reducer

const initialLibraryState = {
  libraryData: {},
  categoriesLoading: false,
  selectedCategory: "",
  libraryCategories: null,
};

export const WellnessLibraryReducer = (state = initialLibraryState, action) => {
  switch (action.type) {
    case SET_LIBRARY_DATA:
      return { ...state, libraryData: action.payload };
    case SET_LIBRARY_CATEGORIES_LOADING:
      return { ...state, categoriesLoading: action.payload };
    case SET_LIBRARY_CATEGORY_LOADING:
      return {
        ...state,
        libraryCategories: state.libraryCategories.map((category) => {
          if (category.category === action.payload.selectedCategory) {
            return {
              ...category,
              loading: action.payload.value,
            };
          }
          return category;
        }),
      };
    case SET_LIBRARY_CATEGORY_DATA:
      return {
        ...state,
        libraryData: {
          ...state.libraryData,
          [action.payload.selectedCategory]: action.payload.value,
        },
      };
    case SET_lIBRARY_SELECTED_CATEGORY:
      return { ...state, selectedCategory: action.payload };
    case SET_LIBRARY_CATEGORIES:
      return { ...state, libraryCategories: action.payload };
    case REMOVE_LIBRARY_DATA:
      return {
        libraryData: {},
        selectedCategory: "",
        libraryCategories: [],
        categoriesLoading: false,
      };
    default:
      return state;  }
};

// selectors
export const useLoadingSelector = () => useSelector((state) => state.loading);

export const useUserInfoSelector = () => useSelector((state) => state.user);

export const useUserTokenSelector = () => useSelector((state) => state.auth.userToken);

export const useAvailableFeaturesSelector = () => useSelector((state) => state.features);

export const useVideoDataSelector = () => useSelector((state) => state.videoData);

// wellness library selectors
export const useLibraryCategories = () => useSelector((state) => state.library.libraryCategories);

export const useSelectedLibraryCategory = () => useSelector((state) => state.library.selectedCategory);

export const useIsLibraryLoading = () => useSelector((state) => state.library.categoriesLoading);

export const useIsLibraryVideosLoading = (selectedCategory) => useSelector((state) => state.library.libraryCategories?.find(val => val.category === selectedCategory)?.loading || false);

export const useLibraryCategoryContent = (selectedCategory) => useSelector((state) => state.library.libraryData[selectedCategory]);


