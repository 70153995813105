import React, { Component, useState, useEffect, useRef } from "react";
import HeaderBooking from "../../components/HeaderBooking";
import HeaderDashboard from "../../components/HeaderDashboard";
import json from "../../assets/JSONs/quiz_data_format.json";
import "./index.css";
import "Containers/Booking Selection/index.css";
import ReactFullpage from "@fullpage/react-fullpage";
import CheckIcon from "@material-ui/icons/Check";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { loadSpecificQuiz, loadSpecificQuizQuestions, loadResult, loadQuizNumber } from "../../actions/quiz";
import { logout } from "../../actions/auth";
import Loading from "../../components/Utilities/Loading/Loading";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import PageNotFound from "../../screens/PageNotFound/PageNotFound.js";
import { logoutUser, setUserCoins, startLoading, stopLoading } from "store/actions";
import { useUserInfoSelector, useUserTokenSelector } from "store/reducers";
import { useDispatch } from "react-redux";
import { markActivityComplete } from "actions/dataLoad";

const AssessmentQuiz = (props) => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [quizNum, setQuizNum] = useState(8);
  const [quizName, setquizName] = useState([]);
  const [imageurl, setImageurl] = useState("");
  const userToken = useUserTokenSelector();
  const userInfo = useUserInfoSelector();
  const [open, setOpen] = useState(false);
  const [openResult, setOpenResult] = useState(false);
  const [result, setResult] = useState("");
  const [error, setError] = useState("");
  const [quizNotFound, setQuizNotFound] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { activityId } = location?.state || {};
  const { urlSlug } = useParams();

  const handleLogout = () => {
    logout(userToken);

    logoutUser(dispatch);

    window.localStorage.removeItem("auth");

    history.push({
      pathname: "/signin",
      state: {
        loggedOutMessage: "You have been logged out. Please login again.",
      },
    });
  };

  const showError = (text) => {
    setError(text);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
  };

  const showResultAlert = (text) => {
    setResult(text);
    setOpenResult(true);
  };

  const handleCloseResult = (event, reason) => {
    if (reason === "clickaway") {
      setOpenResult(false);
    }
    setOpenResult(false);
  };
  const getQuizNumber = async (urlSlug) => {
    try {
      const quiz = await loadQuizNumber(urlSlug, userToken);
      return Number(quiz.data.number);
    } catch (error) {
      setQuizNotFound(true);
    }
  };
  useEffect(() => {
    loadAllQuizData();
  }, []);

  useEffect(() => {
    const rootElement = document.getElementById("root");
    const originalOverflow = window.getComputedStyle(rootElement).getPropertyValue("overflow");
    rootElement.style.overflow = "hidden";

    // Cleanup function to reset the overflow when the component is unmounted or cleaned up
    return () => {
      rootElement.style.overflow = originalOverflow; // Reset the overflow when cleaning up
    };
  }, []);

  const loadAllQuizData = async () => {
    try {
      dispatch(startLoading());
      const quizNo = await getQuizNumber(urlSlug);

      const res = await loadSpecificQuiz(quizNo, userToken);

      const { name, answers, imageUrl } = res.data;
      setAnswers(answers);
      setquizName(name);
      setImageurl(imageUrl);
      const questionRes = await loadSpecificQuizQuestions(quizNo, userToken);
      setQuestions(questionRes.data);
    } catch (err) {
      if (err.response?.data?.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(
          () =>
            history.push({
              pathname: "/signin",
              state: { referer: location.pathname },
            }),
          3000
        );
      } else {
        console.log(err);
        showError(err.response);
      }
    } finally {
      dispatch(stopLoading());
    }
  };

  const activityComplete = async (activityId) => {
    try {
      const response = await markActivityComplete(userToken, userInfo.id, activityId);
      const { status, error, coinsBalance } = response.data;
      if (status !== "success") {
        throw new Error(error);
      }
      if (coinsBalance) {
        dispatch(setUserCoins(coinsBalance));
      }
    } catch (error) {
      console.error("Failed to complete activity: ", error.message);
    }
  };

  const showResult = async (allAnswers) => {
    try {
      dispatch(startLoading());
      const quizNo = await getQuizNumber(urlSlug);
      let sum = 0;
      allAnswers.forEach((m) => (sum += m));
      const res = await loadResult(quizNo, sum, userToken);
      if (activityId) {
        activityComplete(activityId);
      }

      const { interpretation } = res.data;
      const { userScore } = res.data;
      setResult(interpretation);
      setOpenResult(true);
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(
          () =>
            history.push({
              pathname: "/signin",
              state: { referer: location.pathname },
            }),
          3000
        );
      } else {
        showError(err?.response?.data?.error);
      }
    } finally {
      dispatch(stopLoading());
    }
  };
  if (quizNotFound) {
    return <PageNotFound />;
  }
  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      {/*<Snackbar
        open={openResult}
        autoHideDuration={6000}
        onClose={handleCloseResult}
      >
        <Alert
          onClose={handleCloseResult}
          severity='info'
          sx={{ width: "100%" }}
        >
          {result}
        </Alert>
      </Snackbar>*/}
      {questions.length !== 0 && (
        <Quiz
          answers={answers}
          questions={questions}
          quizName={quizName}
          imageurl={imageurl}
          showResult={showResult}
          handleLogout={handleLogout}
          result={result}
          openResult={openResult}
        />
      )}
    </div>
  );
};

const Quiz = ({ answers, questions, quizName, imageurl, showResult, handleLogout, result, openResult }) => {
  const [allAnswers, setAllAnswers] = useState(new Array(questions.length).fill(-1));
  const history = useHistory();

  const checkValidity = () => {
    return allAnswers.length === 0 || allAnswers.includes(-1);
  };
  useEffect(() => {
    if (window.fullpage_api) {
      window.fullpage_api.setAllowScrolling(false);
    }
    return () => {
      if (window.fullpage_api) {
        window.fullpage_api.setAllowScrolling(true);
      }
    };
  }, []);

  return (
    <div className="sectionpageassessmnet">
      <ReactFullpage
        //fullpage options
        licenseKey={"YOUR_KEY_HERE"}
        //keyboardScrolling={false}
        scrollingSpeed={1000}
        scrollingOverflow={true}
        render={({ state, fullpageApi }) => {
          return (
            <div>
              {questions &&
                questions.map((e, i) => {
                  return (
                    <div className={`section ${i === 0 ? "active" : ""}`} key={i}>
                      <div className="halfcontainerquiz">
                        <p className="title-hcqs">
                          Quiz : {quizName} ({i + 1}/{questions.length})
                        </p>
                        <p className="subtitle-hcqs">
                          {i + 1}. {e.question}
                        </p>
                        {answers.map((u) => {
                          return (
                            <div
                              className={`select-box-lcb ${allAnswers[i] === u.value ? "selected" : ""}`}
                              onClick={() => {
                                const newArr = [...allAnswers];
                                newArr[i] = u.value;
                                setAllAnswers(newArr);
                                if (i < questions.length - 1) {
                                  fullpageApi.moveSectionDown();
                                }
                              }}
                            >
                              <div className="top-sblcb">
                                <div className={`grayboxtsb ${allAnswers[i] === u.value ? "checkboxtsb" : ""}`}>
                                  {allAnswers[i] === u.value && <CheckIcon style={{ color: "white" }} />}
                                </div>
                                <p className="quizOptions">{u.answer}</p>
                              </div>
                            </div>
                          );
                        })}
                        <div className="btns-quiz-updown">
                          {i > 0 && (
                            <button
                              style={{ color: "#394B42", fontWeight: "600" }}
                              onClick={() => {
                                fullpageApi.moveSectionUp();
                              }}
                            >
                              <ChevronLeftIcon style={{ marginRight: 5 }} />
                              Previous{" "}
                            </button>
                          )}
                          {questions && i < questions.length - 1 ? (
                            <button
                              className={`next-btn ${allAnswers[i] !== -1 ? "active" : ""}`}
                              disabled={allAnswers[i] === -1}
                              onClick={() => {
                                if (allAnswers[i] != -1) {
                                  fullpageApi.moveSectionDown();
                                }
                              }}
                            >
                              Next <ChevronRightIcon style={{ marginLeft: 5 }} />
                            </button>
                          ) : (
                            <button
                              className={`${checkValidity() && "disabled"}`}
                              disabled={checkValidity()}
                              style={{ color: "#FFFCF8", fontWeight: "800" }}
                              onClick={() => {
                                showResult(allAnswers);
                              }}
                            >
                              Show Results <CheckIcon style={{ marginLeft: 5 }} />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        }}
      />

      <div
        className="right-apm"
        style={{
          color: "lightblue",
          backgroundImage: `url(${imageurl})`,
        }}
      ></div>
      
      {openResult && (
        <div className="popup-spassessment-cont">
          <div className="popup-spassessment">
            <p style={{ textAlign: "center" }}>
              <span style={{ textDecoration: "underline" }}>Your Score's Interpretation</span>
              <br />
              <br />
              {result}
            </p>
            <div className="buttons-pspa">
              <button onClick={() => history.replace("/dashboard/analytics")}>Go to Dashboard</button>
              <button onClick={() => history.replace("/dashboard/sessionBooking")}>Book a Session</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssessmentQuiz;
