import React, { useState, useEffect } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { HiOutlineVideoCamera } from "react-icons/hi";
import { IoChatbubbleEllipsesOutline, IoClose, IoCheckmark } from "react-icons/io5";
import Button from "@material-ui/core/Button";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { cancelSlot, postFeedback, sendReminder, setTaskComplete } from "../../actions/bookSlot";
import "./index.css";
import { BsInfoCircle } from "react-icons/bs";
import Alert from "@material-ui/lab/Alert";
import { Tooltip } from "antd";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Rating from "@material-ui/lab/Rating";
import CheckIcon from "@material-ui/icons/Check";
import Snackbar from "@material-ui/core/Snackbar";
import { Rate } from "antd";
import { Link, useHistory } from "react-router-dom";
import { FaRegCalendarAlt, FaRegClock } from "react-icons/fa";
import BookingsIcon from "assets/svg_icons/bookings_icon_colored.svg";
import ClockIcon from "assets/svg_icons/clock_icon.svg";
import CalendarLarge from "assets/svg_icons/calendar_large.svg";
import ButtonBg from "assets/svg_icons/button-bg.svg";
import { motion } from "motion/react";

import { useUserInfoSelector, useUserTokenSelector } from "store/reducers";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "store/actions";
import SessionFeedbackPopup from "../../components/SessionFeedbackPopup/SessionFeedbackPopup";
import { fetchLatestBooking, fetchUserBookings } from "actions/dataLoad";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

const monthOptions = [
  { name: "All", fullName: "All Months", value: "all"},
  { num: 1, name: "Jan", fullName: "January" , value: "01"},
  { num: 2, name: "Feb", fullName: "February", value: "02" },
  { num: 3, name: "Mar", fullName: "March", value: "03" },
  { num: 4, name: "Apr", fullName: "April", value: "04" },
  { num: 5, name: "May", fullName: "May" , value: "05"},
  { num: 6, name: "June", fullName: "June", value: "06" },
  { num: 7, name: "July", fullName: "July", value: "07" },
  { num: 8, name: "Aug", fullName: "August", value: "08" },
  { num: 9, name: "Sep", fullName: "September", value: "09" },
  { num: 10, name: "Oct", fullName: "October", value: "10" },
  { num: 11, name: "Nov", fullName: "November", value: "11" },
  { num: 12, name: "Dec", fullName: "December", value: "12" },
];
const years = [new Date().getFullYear(), new Date().getFullYear() - 1];

export const UserBookings = ({ showMessage, showError }) => {
  const [cancelPopup, setCancelPopup] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [freeAllowedSessionReschedules, setFreeAllowedSessionReschedules] = useState(0);
  const [isFreecancellationAvailable, setIsFreecancellationAvailable] = useState(false);

  const [showOverlay, setShowOverlay] = useState(false);
  const [elementId, setElementId] = useState("");
  const [therapistId, setTherapistId] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [completedBookings, setCompletedBookings] = useState([]);
  const userToken = useUserTokenSelector();
  const userInfo = useUserInfoSelector();
  const [sessionFeedbackPopupState, setSessionFeedbackPopupState] = useState({ show: false, data: {} });
  const [selectedCategory, setSelectedCategory] = useState("upcoming");
  const [latestBooking, setLatestBooking] = useState(null);
  const [userFirstBookingYear, setUserFirstBookingYear] = useState("");
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');

  //loads all the therapy bookings of the user
  const getAllBookings = async (monthNumber, year = "") => {
    try {
      dispatch(startLoading());
      const res = await fetchUserBookings(
        {
          month: monthNumber,
          year: year,
          timeOffset: new Date().getTimezoneOffset(),
        },
        userToken
      );
      const upcomingBookingsData = res.data["upcoming"];
      const completedBookingsData = res.data["completed"];
      const userTimestamp = res.data.userTimestamp;

      let i = 0;
      for (const booking of completedBookingsData) {
        if (!booking.isCancel && i < 2) {
          booking.showRecommendation = true;
          i++;
        } else {
          booking.showRecommendation = false;
        }
      }
      setUpcomingBookings(upcomingBookingsData);
      setCompletedBookings(completedBookingsData);
      setUserFirstBookingYear(userTimestamp);
      setFreeAllowedSessionReschedules(res.data.freeAllowedSessionReschedules);
      setIsFreecancellationAvailable(res.data.isFreecancellationAvailable);
    } catch (err) {
      console.error(err);
      showError(err?.response?.data?.error);
    } finally {
      dispatch(stopLoading());
    }
  };

  const sendRating = async (bookingID, rating, therapistID) => {
    try {
      dispatch(startLoading());
      await postFeedback({ bookingID, rating, therapistID }, userToken);
      await getAllBookings(selectedMonth);
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
      } else {
        showError(err.response.data.error);
      }
    }
  };

  const cancelBookingSlot = async () => {
    if (bookingId && therapistId) {
      let bookingID = bookingId;
      let therapistID = therapistId;
      try {
        const data = {
          bookingID,
          therapistID,
        };
        dispatch(startLoading());
        setIsCancelModalOpen(false);
        //returns a message
        const res = await cancelSlot(data, userToken);
        if (res.data.isCancel) {
          // setCancelPopup(false);
          setIsCancelModalOpen(false);
          await getAllBookings(selectedMonth, "upcoming");
        } else {
          setIsCancelModalOpen(false);
          // setCancelPopup(false);
          showError(res.data.error);
        }
        setBookingId("");
        // setmeetId("");

        setTherapistId("");
        dispatch(stopLoading());
      } catch (err) {
        dispatch(stopLoading());
        setBookingId("");
        // setmeetId("");
        setTherapistId("");
        setIsCancelModalOpen(false);
        // setCancelPopup(false);
        if (err.response.data.redirectToSignIn) {
          showError(err.response.data.error);
        } else {
          showError(err.response.data.error);
        }
      }
    }
  };

  const handleSendReminder = async (mess, bookingID, i) => {
    try {
      dispatch(startLoading());
      await sendReminder({ bookingID, message: mess }, userToken);
      let element = `reminderpopup${i}`;
      document.getElementById(element).style.opacity = 0;
      document.getElementById(element).style.pointerEvents = "none";
      setShowOverlay(false);
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      let element = `reminderpopup${i}`;
      document.getElementById(element).style.opacity = 0;
      document.getElementById(element).style.pointerEvents = "none";
      setShowOverlay(false);
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
      } else {
        showError(err.response.data.error);
      }
    }
  };

  const getLatestBooking = async () => {
    try {
      dispatch(startLoading());
      const response = await fetchLatestBooking(userToken);
      setLatestBooking(response.data.latest || null);
    } catch (error) {
      showError(error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
      console.error(error);
    } finally {
      dispatch(stopLoading());
    }
  };

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      getAllBookings(selectedMonth, selectedYear);
    }
  }, [selectedMonth, selectedYear, selectedCategory]);

  useEffect(() => {
    getLatestBooking();
  }, []);

  const getMonthName = (i) => {
    i = Number(i);
    if (i === 1) {
      return "Jan";
    } else if (i === 2) {
      return "Feb";
    } else if (i === 3) {
      return "Mar";
    } else if (i === 4) {
      return "Apr";
    } else if (i === 5) {
      return "May";
    } else if (i === 6) {
      return "June";
    } else if (i === 7) {
      return "July";
    } else if (i === 8) {
      return "Aug";
    } else if (i === 9) {
      return "Sep";
    } else if (i === 10) {
      return "Oct";
    } else if (i === 11) {
      return "Nov";
    } else if (i === 12) {
      return "Dec";
    }
  };

  const getMonthNumber = (i) => {
    if (i === "Jan") {
      return 0;
    } else if (i === "Feb") {
      return 1;
    } else if (i === "Mar") {
      return 2;
    } else if (i === "Apr") {
      return 3;
    } else if (i === "May") {
      return 4;
    } else if (i === "June") {
      return 5;
    } else if (i === "July") {
      return 6;
    } else if (i === "Aug") {
      return 7;
    } else if (i === "Sep") {
      return 8;
    } else if (i === "Oct") {
      return 9;
    } else if (i === "Nov") {
      return 10;
    } else if (i === "Dec") {
      return 11;
    }
  };

  function capitalize(input) {
    var words = input.split(" ");
    var CapitalizedWords = [];
    words.forEach((element) => {
      CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
    });
    return CapitalizedWords.join(" ");
  }

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
  };

  const checkUpcomingForSelectedMonth = (upcomingBookings, selectedMonth) => {
    if (upcomingBookings.length === 0 || getMonthNumber(selectedMonth) < new Date().getMonth()) {
      return false;
    } else {
      let A = upcomingBookings.filter((b) => {
        let { sessionTime } = b.session;
        let { start } = sessionTime;
        let month = new Date(start).getMonth();
        return getMonthName(month) === selectedMonth;
      });
      if (A.length === 0) {
        return false;
      } else {
        return true;
      }
    }
  };

  const checkCompletedForSelectedMonth = (completedBookings, selectedMonth) => {
    if (completedBookings.length === 0 || getMonthNumber(selectedMonth) > new Date().getMonth()) {
      return false;
    } else {
      let A = completedBookings.filter((b) => {
        let { sessionTime } = b.session;
        let { start, end } = sessionTime;
        let month = new Date(start).getMonth();
        return getMonthName(month) === selectedMonth;
      });
      if (A.length === 0) {
        return false;
      } else {
        return true;
      }
    }
  };

  const showUpcomingTitle = (upcomingBookings, completedBookings, selectedMonth) => {
    let A = checkUpcomingForSelectedMonth(upcomingBookings, selectedMonth);
    let B = checkCompletedForSelectedMonth(completedBookings, selectedMonth);
    //return !((A && !B) || (!A && B)) || (A && !B)
    return A || !B;
  };

  const sendTaskDone = async (taskId, bookingId) => {
    try {
      dispatch(startLoading());
      const data = { taskId, bookingId };
      await setTaskComplete(data, userToken);
      dispatch(stopLoading());
    } catch (err) {
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
      } else {
        showError(err.response.data.error);
      }
    }
  };
  const handleSessionFeedbackSubmit = async ({ rating, sessionId, therapistId, message, comments }) => {
    try {
      setSessionFeedbackPopupState({ show: false, data: {} });
      dispatch(startLoading());
      await postFeedback({ rating, bookingID: sessionId, therapistID: therapistId, message, comments }, userToken);
      await getAllBookings(selectedMonth);
      dispatch(stopLoading());
    } catch (err) {
      showError("An error occurred!");
    }
  };
  const handleRating = ({ therapistName, therapistId, sessionId }) => {
    setSessionFeedbackPopupState({ show: true, data: { therapistName, therapistId, sessionId } });
  };
  const handleMonthYearSelect = async (monthNumber, year) => {
    dispatch(startLoading());
    await getAllBookings(monthNumber);
    setSelectedMonth(monthNumber);
    dispatch(stopLoading());
  };

  const handleExpandBtnClick = (sessionId) => {
    if (selectedCategory === "upcoming") {
      setUpcomingBookings((bookings) =>
        bookings.map((booking) => {
          if (booking._id === sessionId) {
            return {
              ...booking,
              expanded: !booking.expanded,
            };
          } else {
            return booking;
          }
        })
      );
    }
  };

  if (!latestBooking?._id) {
    return (
      <div className="scheduledashboard-empty">
        <div>
          <img src={CalendarLarge} />
          <div>You haven’t booked a session yet</div>
          <button onClick={() => history.push("/dashboard/sessionBooking")}>Book session now</button>
        </div>
      </div>
    );
  }

  const hasCompletedBookings = completedBookings && completedBookings.length > 0;
  const earliestBookingYear = hasCompletedBookings
  ? new Date(userFirstBookingYear).getFullYear()
  : currentYear;


  let years = hasCompletedBookings
  ? [
      { label: "All times", value: "all" },
      ...Array.from({ length: currentYear - earliestBookingYear + 1 }, (_, i) => ({
        label: (earliestBookingYear + i).toString(),
        value: (earliestBookingYear + i).toString(),
      })),
    ]
  : [
      { label: "All times", value: "all" },
      { label: currentYear.toString(), value: currentYear.toString() },
    ];
  if (parseInt(currentMonth) === 12) {
    years = [...years, { label: (currentYear+1).toString(), value: (currentYear+1).toString() }]
  }

  const nextMonth = (parseInt(currentMonth) === 12) ? "01" : (parseInt(currentMonth) + 1).toString().padStart(2, '0');

  // Inside the MyBookings component
  const filteredMonths = (selectedCategory === "upcoming")
  ? [
      monthOptions[0], // Include 'All months'
      monthOptions.find((month) => month.value === currentMonth), // Include current month
      monthOptions.find((month) => month.value === nextMonth), // Include next month (handles December -> January correctly)
    ]
  : selectedYear === currentYear
  ? [
      monthOptions[0], // Include 'All months'
      ...Array.from({ length: parseInt(currentMonth) }, (_, i) => {
        let monthValue = (parseInt(currentMonth) - i).toString().padStart(2, '0');
        return monthOptions.find((month) => month.value === monthValue);
      }).filter(Boolean) // Remove any undefined values
    ]
  : [
      monthOptions[0], // Include 'All months'
      ...monthOptions.slice(1) // From January to December
    ];

  return (
    <div className="dashboardofboxes scheduledashboard">
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      <div className="usession-top">
        <div className="sessions-category">
          <button
            onClick={() => setSelectedCategory("upcoming")}
            className={`${selectedCategory === "upcoming" ? "active" : ""}`}
          >
            Upcoming Sessions
          </button>
          <button
            onClick={() => setSelectedCategory("completed")}
            className={`${selectedCategory === "completed" ? "active" : ""}`}
          >
            Completed Sessions
          </button>
        </div>
        <div className="session-filters">
          <div className={`session-filter`}>
            <select
              value={selectedMonth}
              onChange={(e) => {
                if(e.target.value === "All Months") {
                  setSelectedMonth("all")
                } else {
                  setSelectedMonth(e.target.value);
                }
              }}
            >
              {filteredMonths.map((month) => (
                <option value={month.value}>{month.fullName}</option>
              ))}
            </select>
          </div>
          <div className={`session-filter`}>
            <select value={selectedYear} onChange={(e) =>{
              if(e.target.value === "All Months") {
                setSelectedYear("all")
              } else {
                setSelectedYear(e.target.value);
              }
               }}>
              {years.map((year, index) => (
                <option key={index} value={year.value}>
                  {year.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {selectedCategory === "upcoming" ? (
        upcomingBookings.length > 0 ? (
          upcomingBookings.map((booking, i) => {
            const {
              isCancel,
              sessionTime,
              _id,
              reschedule,
              therapistID,
              meetid,
              therapistName,
              therapistImageUrl,
              therapistExpertise,
              therapistBio,
              expanded = false,
              therapistTotalSessionMinutes,
            } = booking;
            let { start, end } = sessionTime;
            let timeDifference = new Date(start).getTime() - new Date().getTime();
            let timeDifferenceEnd = new Date(end).getTime() - new Date().getTime();
            let date = new Date(start).getDate();
            let month = new Date(start).getMonth();
            start = new Date(start).toLocaleTimeString();
            start = start.replace(":00", "");
            end = new Date(end).toLocaleTimeString();
            end = end.replace(":00", "");

            //what I know is, there are a total of three booking states - completed, upcoming, cancelled
            let in_progress = !isCancel && timeDifference <= 900000 && timeDifferenceEnd >= 0;
            let canBeRescheduled =
              reschedule < freeAllowedSessionReschedules && timeDifference > 3600000 * 10
                ? true
                : timeDifference > 0
                ? isFreecancellationAvailable
                : false;
            const canBeCancelled = timeDifference > 0;

            return (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={`usession-div`}>
                <div className="usession-table">
                  <div className="usession-body">
                    <div className={`usession-container`}>
                      <div className="thname-value">
                        <img src={therapistImageUrl || "/images/therapist-demo.png"} alt="therapist-pic" />
                        <div className="name-div">
                          <p className="name">{therapistName}</p>
                          <p className="head">Therapist</p>
                        </div>
                      </div>
                      <div className="thsession-value">
                        <p className="combine">
                          <img src={BookingsIcon} />
                          {date} {getMonthName(month + 1)}
                        </p>
                        <p>
                          <img src={ClockIcon} /> {start}-{end}
                        </p>
                      </div>
                      {!isCancel && (
                        <div className="ubtns-div">
                          {in_progress && (
                            <div className="in-progress">
                              <button className={`upsession-btn in-progress join`}>
                                <Link
                                  to={{
                                    pathname: "/session/" + meetid,
                                    state: {
                                      therapistID: therapistID,
                                      bookingID: _id,
                                    },
                                  }}
                                  style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                  }}
                                >
                                  <div className="ujoin-div">
                                    <p>Join Now</p>
                                    <HiOutlineVideoCamera size={20} />
                                  </div>
                                </Link>
                              </button>
                            </div>
                          )}
                          {canBeCancelled && (
                            <Tooltip
                              placement="bottomLeft"
                              title="Free cancellation is allowed only if you cancel 10 hrs before the session start time. To ease the process, we've made the first cancellation free for You."
                              color="#e8e8e8"
                              overlayInnerStyle={{
                                borderRadius: "10px",
                                fontSize: "10px",
                                color: "#000",
                                fontWeight: "bold",
                                maxWidth: "250px",
                              }}
                            >
                              <button
                                onClick={() => {
                                  setIsCancelModalOpen(true);
                                  // setCancelPopup(true);
                                  setBookingId(_id);
                                  setTherapistId(therapistID);
                                  // setmeetId(meetid);
                                }}
                                className="upsession-btn cancel"
                              >
                                Cancel{" "}
                              </button>
                            </Tooltip>
                          )}
                          {canBeRescheduled && (
                            <button className="upsession-btn">
                              <Tooltip
                                placement="bottomLeft"
                                title="You can only reschedule a session 10 hours prior to the start time of session."
                                color="#e8e8e8"
                                overlayInnerStyle={{
                                  borderRadius: "10px",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                  color: "#000",
                                  width: "300px",
                                }}
                              >
                                <Link
                                  to={{
                                    pathname: "/rescheduleSession",
                                    state: {
                                      therapistID: therapistID,
                                      bookingID: _id,
                                    },
                                  }}
                                  style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                  }}
                                >
                                  Reschedule{" "}
                                </Link>
                              </Tooltip>
                            </button>
                          )}
                        </div>
                      )}
                      {/* <div className={`uchat-div ${in_progress ? "" : "hide"}`}>
                          <IoChatbubbleEllipsesOutline size={20} color={"white"} />
                        </div> */}
                    </div>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className={`usession-content ${!expanded ? "limit-height" : ""}`}
                    >
                      <div><div>{therapistTotalSessionMinutes}+ mins of session</div></div>
                      <div>
                        <p>Specialization </p>
                        <div className="usession-content-tags">
                          {therapistExpertise?.map((expertise, index) => {
                            return <div>{expertise}</div>;
                          })}
                        </div>
                      </div>
                      <div>
                        <p>Bio</p>
                        <div className="usession-content-bio">{therapistBio}</div>
                      </div>
                    </motion.div>

                    <div className="usession-btn-cont">
                      <button className="usession-btn" onClick={() => handleExpandBtnClick(_id)}>
                        View {expanded ? "less" : "more"}
                        {expanded ? <RiArrowDropUpLine size={24} /> : <RiArrowDropDownLine size={24} />}
                      </button>
                    </div>
                    <div className="ubtns-div-mobile">
                          {in_progress && (
                            <div className="in-progress">
                              <button className={`upsession-btn in-progress join`}>
                                <Link
                                  to={{
                                    pathname: "/session/" + meetid,
                                    state: {
                                      therapistID: therapistID,
                                      bookingID: _id,
                                    },
                                  }}
                                  style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                  }}
                                >
                                  <div className="ujoin-div">
                                    <p>Join Now</p>
                                    <HiOutlineVideoCamera size={20} />
                                  </div>
                                </Link>
                              </button>
                            </div>
                          )}
                          {canBeCancelled && (
                            <Tooltip
                              placement="bottomLeft"
                              title="Free cancellation is allowed only if you cancel 10 hrs before the session start time. To ease the process, we've made the first cancellation free for You."
                              color="#e8e8e8"
                              overlayInnerStyle={{
                                borderRadius: "10px",
                                fontSize: "10px",
                                color: "#000",
                                fontWeight: "bold",
                                maxWidth: "250px",
                              }}
                            >
                              <button
                                onClick={() => {
                                  setIsCancelModalOpen(true);
                                  // setCancelPopup(true);
                                  setIsCancelModalOpen(true);
                                  setBookingId(_id);
                                  setTherapistId(therapistID);
                                  // setmeetId(meetid);
                                }}
                                className="upsession-btn cancel"
                              >
                                Cancel{" "}
                              </button>
                            </Tooltip>
                          )}
                          {canBeRescheduled && (
                            <button className="upsession-btn">
                              <Tooltip
                                placement="bottomLeft"
                                title="You can only reschedule a session 10 hours prior to the start time of session."
                                color="#e8e8e8"
                                overlayInnerStyle={{
                                  borderRadius: "10px",
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                  color: "#000",
                                  width: "300px",
                                }}
                              >
                                <Link
                                  to={{
                                    pathname: "/rescheduleSession",
                                    state: {
                                      therapistID: therapistID,
                                      bookingID: _id,
                                    },
                                  }}
                                  style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                  }}
                                >
                                  Reschedule{" "}
                                </Link>
                              </Tooltip>
                            </button>
                          )}
                        </div>
                  </div>
                </div>
              </motion.div>
            );
          })
        ) : (
          <div className="empty-upcoming">
            <img src={CalendarLarge} />
            <div>
              No bookings found for this time period. <br /> Let's plan your next session!
            </div>
            <button onClick={() => history.push("/dashboard/sessionBooking")}>Book session now</button>
          </div>
        )
      ) : (
        <></>
      )}
      {selectedCategory === "completed" ? (
        completedBookings.length > 0 ? (
          completedBookings.map((booking, i) => {
            const { sessionTime, _id, isCancel, therapistImageUrl, therapistName } = booking;
            let { start, end } = sessionTime;
            let timeDifference = new Date(start).getTime() - new Date().getTime();
            let timeDifferenceEnd = new Date(end).getTime() - new Date().getTime();
            let date = new Date(start).getDate();
            let month = new Date(start).getMonth();
            start = new Date(start).toLocaleTimeString();
            start = start.replace(":00", "");
            end = new Date(end).toLocaleTimeString();
            end = end.replace(":00", "");

            return (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={`usession-div`}>
                <div className="usession-table">
                  <div className="usession-body">
                    <div className={`usession-container`}>
                      <div className="thname-value">
                        <img src={therapistImageUrl || "/images/therapist-demo.png"} alt="therapist-pic" />
                        <div className="name-div">
                          <p className="name">{therapistName}</p>
                          <p className="head">Therapist</p>
                        </div>
                      </div>
                      <div className="thsession-value">
                        <p className="combine">
                          <img src={BookingsIcon} />
                          {date} {getMonthName(month + 1)}
                        </p>
                        <p>
                          <img src={ClockIcon} /> {start}-{end}
                        </p>
                      </div>
                      <div className="completed" style={{backgroundColor:'transparent'}}>
                        {isCancel ? (
                          <div className="completed-cancelled">
                            {" "}
                            <IoClose size={20} fill="#F45E4B" /> <span>Cancelled</span>{" "}
                          </div>
                        ):
                        <div className="completed-active">
                            {" "}
                            <IoCheckmark size={20} fill="#F45E4B" /> <span>Completed</span>{" "}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            );
          })
        ) : (
          <div className="empty-upcoming">
            <img src={CalendarLarge} />
            <div>No completed sessions found for the selected month and year.</div>
          </div>
        )
      ) : (
        <></>
      )}
      {
            isCancelModalOpen &&
            <div className="user-modal">
            <div className="signout-modal">
                <p className="signout-head">Are you sure you want to cancel this session?</p>
                <div className="sbtns">
                    <button className="signout-btn" onClick={() => cancelBookingSlot()}>Yes</button>
                    <button className="signout-btn no" onClick={() => setIsCancelModalOpen(false)}>No</button>
                </div>
            </div>
        </div>
          }
      {showOverlay && (
        <div
          className="popup-payment-alert-overaly"
          style={{ backgroundColor: "transparent", zIndex: 9997 }}
          onClick={() => {
            document.getElementById(elementId).style.opacity = 0;
            document.getElementById(elementId).style.pointerEvents = "none";
            //this.setState({ showOverlay: false, elementId: "" })
            setShowOverlay(false);
            setElementId("");
          }}
        ></div>
      )}
      <SessionFeedbackPopup
        isOpen={sessionFeedbackPopupState.show}
        onSessionRatingSubmit={handleSessionFeedbackSubmit}
        sessionData={sessionFeedbackPopupState.data}
      />
    </div>
  );
};

const Recommendations = ({ tasks, sendTaskDone, bookingID }) => {
  const [rec1, setRec1] = useState(false);
  const [rec2, setRec2] = useState(false);
  const [rec3, setRec3] = useState(false);
  const [rec1Text, setRec1Text] = useState("Task 1 Not Assigned.");
  const [rec2Text, setRec2Text] = useState("Task 2 Not Assigned.");
  const [rec3Text, setRec3Text] = useState("Task 3 Not Assigned.");
  const [dR1, setDR1] = useState(false);
  const [dR2, setDR2] = useState(false);
  const [dR3, setDR3] = useState(false);

  useEffect(() => {
    if (tasks[0]) {
      setRec1Text(tasks[0].Task);
      if (tasks[0].CompletedByUser) {
        setRec1(true);
        setDR1(true);
      }
    } else {
      setDR1(true);
    }
    if (tasks[1]) {
      setRec2Text(tasks[1].Task);
      if (tasks[1].CompletedByUser) {
        setRec2(true);
        setDR2(true);
      }
    } else {
      setDR2(true);
    }
    if (tasks[2]) {
      setRec3Text(tasks[2].Task);
      if (tasks[2].CompletedByUser) {
        setRec3(true);
        setDR3(true);
      }
    } else {
      setDR3(true);
    }
  }, []);

  return (
    <div className="select-therapist-recommendations">
      <p className="title-str">Your Therapist's Recommendations</p>
      <div
        className="select-box-lcb"
        onClick={() => {
          if (!dR1) {
            setRec1((prev) => !prev);
            if (tasks[0]) {
              sendTaskDone(tasks[0]._id, bookingID);
              setDR1(1);
            }
          }
        }}
      >
        <div className={rec1 ? `top-sblcb-completed` : `top-sblcb`}>
          {rec1 ? (
            <div className="checkboxtsb">
              <CheckIcon />
            </div>
          ) : (
            <div className="grayboxtsb"></div>
          )}
          <p>{rec1Text}</p>
        </div>
      </div>
      <div
        className="select-box-lcb"
        style={{ cursor: dR2 ? "text" : "pointer" }}
        onClick={() => {
          if (!dR2) {
            setRec2((prev) => !prev);
            if (tasks[1]) {
              sendTaskDone(tasks[1]._id, bookingID);
              setDR2(true);
            }
          }
        }}
      >
        <div className={rec2 ? `top-sblcb-completed` : `top-sblcb`}>
          {rec2 ? (
            <div className="checkboxtsb">
              <CheckIcon />
            </div>
          ) : (
            <div className="grayboxtsb"></div>
          )}
          <p>{rec2Text}</p>
        </div>
      </div>
      <div
        className="select-box-lcb"
        onClick={() => {
          if (!dR3) {
            setRec3((prev) => !prev);
            if (tasks[2]) {
              sendTaskDone(tasks[2]._id, bookingID);
              setDR3(true);
            }
          }
        }}
      >
        <div className={rec3 ? `top-sblcb-completed` : `top-sblcb`}>
          {rec3 ? (
            <div className="checkboxtsb">
              <CheckIcon />
            </div>
          ) : (
            <div className="grayboxtsb"></div>
          )}
          <p>{rec3Text}</p>
        </div>
      </div>
    </div>
  );
};

export default UserBookings;
