import { useState, useEffect } from "react";
import HeaderBooking from "../../components/HeaderBooking";
import CheckIcon from "@material-ui/icons/Check";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useDispatch } from "react-redux";
import "./index.css";
//actions
import {
  loadAllTherapyTypes,
  loadAllProblemTypes,
} from "../../actions/dataLoad";
import { bookSession } from "../../actions/bookSlot";
import { fetchTherapists, previousBooking } from "../../actions/therapist";
import { logout } from "../../actions/auth";
import BookingCalendar from "../Calendar Booking/index.js";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { logoutUser, startLoading, stopLoading } from "store/actions";
import {
  useLoadingSelector,
  useUserInfoSelector,
  useUserTokenSelector,
} from "store/reducers";
import Loading from "components/Utilities/Loading/Loading";
import { useHistory, useLocation } from "react-router-dom";
import { countryCodes } from "utils/data";
import { ArrowBack } from "@material-ui/icons";
import { IoIosArrowBack } from "react-icons/io";

const BookingSelection = () => {
  const dispatch = useDispatch();
  const userInfo = useUserInfoSelector();
  const [searchedText, setSearchedText] = useState("");
  const [meetid, setstateformeetid] = useState("");
  const [noCategoryFiltered, setNoCategoryFiltered] = useState(false);
  const [categoriesFiltered, setCategoriesFiltered] = useState([]);
  const [countryDropdown, setCountryDropdown] = useState(false);
  const [codeSearchedText, setCodeSearchedText] = useState("");
  const [noCodeFiltered, setNoCodeFiltered] = useState(false);
  const [codeFiltered, setCodeFiltered] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [description, setDescription] = useState("");
  const [MobileNumber, setMobileNumber] = useState(userInfo.phone || "");
  const [CountryCode, setCountryCode] = useState(userInfo.countrycode || "");
  const [age, setAge] = useState(userInfo.age || "");
  const [madropdown, setMadropdown] = useState("");
  const [therapyDropdown, setTherapyDropdown] = useState(false);
  const [categoryDropdown, setCategoryDropdown] = useState(false);
  const [selectedMedicalAssistance, setSelectedMedicalAssistance] =
    useState("");
  const [selectedTherapy, setSelectedTherapy] = useState("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [allCategories, setAllCategories] = useState([]);
  const [therapyType, setTherapyType] = useState([]);
  const [medicalAssistance, setMedicalAssistance] = useState([
    "Yes- Taken medicines before",
    "Yes - currently on medication",
    "No - never taken any medication",
  ]);
  const [availableTherapist, setAvailableTherapist] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [errorPayment, setErrorPayment] = useState(false);
  const [firstBooking, setFirstBooking] = useState(true);
  const userToken = useUserTokenSelector();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [loadingPrevScreen, setLoadingPrevScreen] = useState(false);
  const [therapistsName, settherapistsName] = useState("");
  const isLoading = useLoadingSelector();
  const [therapistDataPromise, setTherapistDataPromise] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const change = location.state?.change || false;
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isNumberEditable, setIsNumberEditable] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  const showError = (text) => {
    setError(text);
    setOpen(true);
  };

  const handleCategoryClick = (category) => {
    const index = selectedCategory.indexOf(category);
    if (index === -1) {
      setSelectedCategory([...selectedCategory, category]);
    } else {
      setSelectedCategory(selectedCategory.filter((cat) => cat !== category));
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
  };

  useEffect(() => {
    if (!CountryCode) {
      try {
        fetch("https://api.ipify.org/?format=json")
          .then((response) => response.json())
          .then((data) => {
            let ip = data.ip;
            fetch("https://ipapi.co/" + ip + "/json")
              .then((response) => response.json())
              .then((data) => {
                let country_dial_code = data.country_calling_code;
                setCountryCode((prev) => country_dial_code);
              });
          });
      } catch (err) {
        setCountryCode((prev) => "+91");
      }
    }
    prevSession();
  }, []);

  useEffect(() => {
    if (
      age >= 10 &&
      age <= 100 &&
      selectedCategory.length > 0 &&
      (therapistsName !== undefined || "")
    ) {
      chooseTimeSlot();
    }
  }, [age, selectedCategory]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // function reverseStr(s) {
  //   return s.split("").reverse().join("")
  // }

  const prevSession = async () => {
    try {
      setLoadingPrevScreen(true);
      const problemRes = await allProblems();
      const therapyRes = await allTherapyTypes();
      const res = await previousBooking(
        { userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
        userToken
      );

      setFirstBooking(true);

      if (res.data.length !== 0) {
        if (!change) {
          setFirstBooking(false);
        }
        const {
          therapistID,
          userAge,
          medicalAssistanceTaken,
          problemCategory,
          problemDescription,
          contact,
        } = res.data.userData;
        const { therapistsFullName } = res.data.therapistsFullName;
        if (!change) {
          settherapistsName(therapistsFullName);
        }

        const allEvents = {
          therapists: [therapistID],
          userData: {
            age: userAge,
            medicine: medicalAssistanceTaken,
            problem: problemCategory,
            therapy: res.data["userData"].therapyType,
          },
          therapistsFullName: res.data["therapistsFullName"],
          allEvents: res.data["allEvents"],
        };
        if (problemDescription) {
          setDescription(problemDescription);
        }
        let problems = [];
        problemCategory.map((probId, i) => {
          const problemDetails = problemRes.filter((p) => {
            return p.problemID === probId;
          });
          problems.push(problemDetails[0].problemCategory);
        });
        const therapyId = therapyRes.filter((t) => {
          return t.therapyType === res.data["userData"].therapyType;
        });
        setSelectedTherapy(therapyId[0]?.therapy);
        setAge(userAge);
        setSelectedCategory(problems);
        setMobileNumber(contact);
        let medStat = medicalAssistanceTaken ? "Yes" : "No";
        setSelectedMedicalAssistance(medStat);
        if (!change) {
          setAvailableTherapist(allEvents);
          setShowCalendar(true);
        }
        setLoadingPrevScreen(false);
      }

      setLoadingPrevScreen(false);
    } catch (err) {
      /**replace with error */
      setLoadingPrevScreen(false);
      if (err?.response?.data?.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(
          () =>
            history.push({
              pathname: "/signin",
              state: { referer: location.pathname },
            }),
          3000
        );
      } else {
        showError(err.response.data.error);
      }
    }
  };

  async function afterpayment(
    meetid,
    date,
    time,
    month,
    GMTSlotDateTime,
    userTimeZone,
    description,
    planName,
    mobileNo,
    _id,
    goodLivesOrderID,
    response,
    transactionAmount,
    currency
  ) {
    let medicineBool =
      selectedMedicalAssistance === "Never - Taken any Medication" ||
      selectedMedicalAssistance === "No"
        ? false
        : true;
    const therapyId = therapyType.filter((t) => {
      return t.therapy === selectedTherapy;
    });
    availableTherapist.userData.medicine = medicineBool;
    availableTherapist.userData.therapy = therapyId[0]?.therapyType || "null";
    var paymentSuccess = await bookSession(
      {
        ...availableTherapist,
        date,
        time,
        month,
        GMTSlotDateTime,
        userTimeZone,
        problemDescription: description,
        planName,
        mobile: mobileNo,
        purpose: 1,
        planId: _id,
        goodLivesOrderID,
        paymentId: response.razorpay_payment_id,
        paymentOrderId: response.razorpay_order_id,
        paymentSignature: response.razorpay_signature,
        meetid: meetid,
        countryCode: CountryCode,
      },
      userToken
    );
    const { data } = paymentSuccess.data;

    const { bookingEvent, withdrawal, balance, cashback } = data;

    dispatch(stopLoading());

    history.push({
      pathname: "/dashboard",
      state: {
        message: "Event Booked Successfully",
        bookingEvent: bookingEvent,
        withdrawal: withdrawal,
        balance: balance,
        cashback: cashback,
        orderId: goodLivesOrderID,
        amountPaid: transactionAmount,
        planName,
        currency: currency,
      },
    });
  }

  const razorpayHandler = async function (response) {
    try {
      dispatch(startLoading());
      //sunil start
      const data1 = JSON.stringify({
        title: "Good Lives",
        preferred_region: "ap-south-1",
        record_on_start: false,
        live_stream_on_start: false,
      });
      fetch(`https://api.cluster.dyte.in/v2/meetings`, {
        method: "POST",
        headers: {
          Authorization:
            "Basic ZTAyZGRmY2EtMDdkMS00MzFjLTgyZDItNzkzMTIzMDdmOTQ2OjBiZWNhZjIxOTkzZGE3N2Y3YTcx",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: data1,
      })
        .then((responseJson) => responseJson.json())
        .then((responseJson) => {
          // console.log(responseJson)
          // console.log(responseJson.data1.id)
          console.log("responseJson.data.id");
          afterpayment(
            responseJson.data.id,
            date,
            time,
            month,
            GMTSlotDateTime,
            userTimeZone,
            description,
            planName,
            MobileNumber,
            _id,
            goodLivesOrderID,
            response,
            transactionAmount,
            currency
          );
          //
          // console.log("meetingid", meetingid)
          // setstateformeetid(meetingid)
          // API Call to Book the Session for Successful Payment
        })
        .catch((error) => {
          console.log(error);
          // setloader(false)
        });
      //sunil end
    } catch (err) {
      dispatch(stopLoading());
      setErrorPayment(true);
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(
          () =>
            history.push({
              pathname: "/signin",
              state: { referer: location.pathname },
            }),
          3000
        );
      } else {
        showError(err.response.data.error);
      }
    }
  };

  const allProblems = async () => {
    try {
      const res = await loadAllProblemTypes(userToken);
      setAllCategories(res.data);
      return res.data;
      //setError("")
    } catch (err) {
      /**replace with front end error */
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(
          () =>
            history.push({
              pathname: "/signin",
              state: { referer: location.pathname },
            }),
          3000
        );
      } else {
        showError(err.response.data.error);
      }
    }
  };

  const allTherapyTypes = async () => {
    try {
      const res = await loadAllTherapyTypes(userToken);
      setTherapyType(res.data);
      return res.data;
      //setError("")
    } catch (err) {
      /**replace with front end error */
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(
          () =>
            history.push({
              pathname: "/signin",
              state: { referer: location.pathname },
            }),
          3000
        );
      } else {
        showError(err.response.data.error);
      }
    }
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function formatDateBrowserCompatibility(date) {
    return date.replace(/-/g, "/");
  }

  // Function that calls Wallet/Payment Gateway and completes Session Booking
  const payAndBookEvent = async (selectedPlan, currentDate, selectedTime) => {
    try {
      dispatch(startLoading());
      const { planName } = selectedPlan;
      const { _id } = selectedPlan;
      const { discountedPrice } = selectedPlan;
      const { discountedPriceUSD } = selectedPlan;

      const date = formatDate(currentDate);
      const time = "" + selectedTime.hours + " : " + selectedTime.minutes;
      const month = currentDate.getMonth();

      const GMTSlotDateTime = new Date(
        formatDateBrowserCompatibility(date) +
          " " +
          selectedTime.hours.toString() +
          ":" +
          selectedTime.minutes.toString() +
          ":00"
      ).toString();
      const userTimeZone = Intl.DateTimeFormat("default", {
        timeZonename: "short",
      }).resolvedOptions().timeZone;

      // Invoke API call to Payment Middleware to validate and Generate Order
      // should only have userToken, planid and purpose: 0 in data
      const availableTherapistNew = {...availableTherapist};
      delete availableTherapistNew["allEvents"];

      const dytePayload = JSON.stringify({
        title: "Good Lives",
        preferred_region: "ap-south-1",
        record_on_start: false,
        live_stream_on_start: false,
      });

      const dyteRes = await fetch(`https://api.cluster.dyte.in/v2/meetings`, {
        method: "POST",
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_DYTE_AUTH_TOKEN}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: dytePayload,
      });
      const dyteData = await dyteRes.json();
      let medicineBool =
        selectedMedicalAssistance === "Never - Taken any Medication" ||
        selectedMedicalAssistance === "No"
          ? false
          : true;
      const therapyId = therapyType.filter((t) => {
        return t.therapy === selectedTherapy;
      });
      availableTherapistNew.userData.medicine = medicineBool;
      availableTherapistNew.userData.therapy = therapyId[0]?.therapyType || "null";

      let createOrderRequestData = {
        ...availableTherapistNew,
        date,
        time,
        month,
        GMTSlotDateTime,
        userTimeZone,
        problemDescription: description,
        planName,
        mobile: MobileNumber,
        planId: _id,
        purpose: 0,
        meetid: dyteData.data.id,
        countryCode: CountryCode,
      };

      const createOrder = await bookSession(createOrderRequestData, userToken);

      const { status } = createOrder.data;
      if (status === "Booking Successful") {
        const { data } = createOrder.data;
        const { bookingEvent, withdrawal, balance, cashback, orderID } = data;

        dispatch(stopLoading());

        history.push({
          pathname: "/dashboard",
          state: {
            message: "Event Booked Successfully",
            bookingEvent: bookingEvent,
            withdrawal: withdrawal,
            balance: balance,
            cashback: cashback,
            orderId: orderID,
            amountPaid: Math.abs(withdrawal.transaction.amount),
            planName,
            currency: "Wallet",
          },
        });
      } else {
        const {
          goodLivesOrderID,
          orderId,
          customerEmail,
          customerName,
          currency,
          transactionAmount,
        } = createOrder.data;

        //script is loaded here
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );

        const options = {
          key: process.env.RAZORPAY_KEY,
          amount: transactionAmount,
          currency: currency,
          name: "GoodLives",
          description: planName,
          order_id: orderId,
          prefill: {
            name: customerName,
            email: customerEmail,
            contact: MobileNumber,
          },
          notes: {
            goodLivesOrderID: goodLivesOrderID,
          },
          handler: async function (response) {
            try {
              dispatch(startLoading());
              let medicineBool =
                selectedMedicalAssistance === "Never - Taken any Medication" ||
                selectedMedicalAssistance === "No"
                  ? false
                  : true;
              const therapyId = therapyType.filter((t) => {
                return t.therapy === selectedTherapy;
              });
              availableTherapistNew.userData.medicine = medicineBool;
              availableTherapistNew.userData.therapy =
                therapyId[0]?.therapyType || "null";

              var paymentSuccess = await bookSession(
                {
                  ...availableTherapistNew,
                  date,
                  time,
                  month,
                  GMTSlotDateTime,
                  userTimeZone,
                  problemDescription: description,
                  planName,
                  mobile: MobileNumber,
                  purpose: 1,
                  planId: _id,
                  goodLivesOrderID,
                  paymentId: response.razorpay_payment_id,
                  paymentOrderId: response.razorpay_order_id,
                  paymentSignature: response.razorpay_signature,
                  meetid: dyteData.data.id,
                  countryCode: CountryCode,
                },
                userToken
              );
              const { data } = paymentSuccess.data;

              const { bookingEvent, withdrawal, balance, cashback } = data;

              dispatch(stopLoading());

              history.push({
                pathname: "/dashboard",
                state: {
                  message: "Event Booked Successfully",
                  bookingEvent: bookingEvent,
                  withdrawal: withdrawal,
                  balance: balance,
                  cashback: cashback,
                  orderId: goodLivesOrderID,
                  amountPaid: transactionAmount,
                  planName,
                  currency: currency,
                },
              });
              //sunil end
            } catch (err) {
              dispatch(stopLoading());
              setErrorPayment(true);
              if (err.response.data.redirectToSignIn) {
                showError(err.response.data.error);
                setTimeout(
                  () =>
                    history.push({
                      pathname: "/signin",
                      state: { referer: location.pathname },
                    }),
                  3000
                );
              } else {
                showError(err.response.data.error);
              }
            }
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", function (response) {
          setErrorPayment(true);
        });
        paymentObject.open();
        dispatch(stopLoading());
      }
    } catch (err) {
      dispatch(stopLoading());
      if (err.response.data.redirectToSignIn) {
        throw err;
      } else {
        throw err;
      }
    }
  };

  const chooseTimeSlot = () => {
    let problemIDs = [];

    selectedCategory.map((problemName, i) => {
      const problemDetails = allCategories.filter((p, i) => {
        return p.problemCategory === problemName;
      });
      problemIDs.push(problemDetails[0].problemID);
    });

    const therapyId = therapyType.filter((t) => {
      return t.therapy === selectedTherapy;
    });

    let medicineBool =
      selectedMedicalAssistance === "Never - Taken any Medication" ||
      selectedMedicalAssistance === "No"
        ? false
        : true;

    const data = {
      age,
      therapy: therapyId[0]?.therapyType || "null",
      medicine: medicineBool,
      problem: problemIDs,
      change: change,
    };

    const res = fetchTherapists(data, userToken);
    setTherapistDataPromise(res);
    res.catch((err) => {
      setError(err);
    });
  };

  const handleChooseTimeSlotClick = async () => {
    try {
      if (!therapistDataPromise) {
        chooseTimeSlot();
      } else if (error) {
        await Promise.reject(error);
      }
      dispatch(startLoading());
      const res = await therapistDataPromise;
      setAvailableTherapist(res.data);
      setShowCalendar(true);
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      if (err?.response?.data?.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(
          () =>
            history.push({
              pathname: "/signin",
              state: { referer: location.pathname },
            }),
          3000
        );
      } else {
        showError(err.response?.data?.error || error);
        setTherapistDataPromise(null);
      }
    }
  };

  const handleLogout = () => {
    logout(userToken);

    logoutUser(dispatch);

    window.localStorage.removeItem("auth");

    history.push({
      pathname: "/signin",
      state: {
        loggedOutMessage: "You have been logged out. Please login again.",
      },
    });
  };

  const country_codes = countryCodes.sort();
  const checkCountryCodeValidity = () => {
    if (CountryCode.length > 6) return false;
    if (CountryCode[0] !== "+") return false;
    let countryCode = CountryCode.substring(1).replace("-", "");
    let isNum = /^\d+$/.test(countryCode);

    if (country_codes.includes(CountryCode) && isNum) {
      return true;
    } else {
      return false;
    }
  };
  const checkContactValidity = () => {
    if (
      CountryCode &&
      MobileNumber &&
      MobileNumber.length > 9 &&
      CountryCode[0] === "+"
    ) {
      let countryCode = CountryCode.substring(1).replace("-", "");
      let isNum = /^\d+$/.test(countryCode);

      if (country_codes.includes(CountryCode) && isNum) {
        let number = MobileNumber;
        let phonenoRegex = /^\d{10}$/;
        if (number.match(phonenoRegex)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleBookingContainerClick = (event) => {
    const target = event.target;
    const container = document.querySelector(".fmt-bottom-sblcb");
    if (categoryDropdown && !container.contains(target)) {
      setCategoryDropdown(false);
    }
  };

  const showChooseTimeSlotBtn =
    selectedCategory.length > 0 &&
    selectedTherapy &&
    age >= 10 &&
    age <= 100 &&
    selectedMedicalAssistance &&
    checkContactValidity() &&
    description;

  if (loadingPrevScreen) return <Loading show={true} />;
  else if (showCalendar)
    return (
      <BookingCalendar
        allEvents={availableTherapist}
        setShowCalendar={setShowCalendar}
        payAndBookEvent={payAndBookEvent}
        errorPayment={errorPayment}
        setErrorPayment={setErrorPayment}
        loading={isLoading}
        firstBooking={firstBooking}
        therapistsName={therapistsName}
      />
    );
  else
    return (
      <div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {error}
          </Alert>
        </Snackbar>
        {isMobile ? (
          <div className="fmt-mobile-container-bselection">
            <div className="fmt-mobile-header">
            <button
              onClick={() => history.replace("/dashboard")}
              className="fmt-mobile-backButton"
            >
              <IoIosArrowBack size={13} style={{marginTop:'-6px'}}/>
            </button>
            <h3>Find my therapist</h3>
            <div className="fmt-mobile-backButton" style={{backgroundColor:'#fffcf8'}}/>
            </div>
            <div className="fmt-mobile-progress-container">
              <div className="progress-line"></div>
              <div
                className="progress-fill"
                style={{ width: `${(2 / 9) * 100}%` }}
              ></div>

              <div className="progress-steps">
                <div className="fmt-mobile-progress-step active"></div>
                <div className="fmt-mobile-progress-step active"></div>
                <div className="fmt-mobile-progress-step active"></div>
              </div>
            </div>
            <img
              src="https://ucarecdn.com/a2c52ed5-3634-4850-8810-43f638fabf5a/findTherapistBlissImg.svg"
              alt="Bliss"
              style={{ height: "155px", width: "300px", marginTop: "5px" }}
            />
            <div className="fmt-select-box-lcb">
              <div
                className={`fmt-top-sblcb ${
                  categoryDropdown && "fmt-topselectedsb"
                }`}
                onClick={() => {
                  setCategoryDropdown((prev) => !prev);
                  setTherapyDropdown(false);
                  setMadropdown(false);
                  setCountryDropdown(false);
                }}
              >
                <p style={{ paddingLeft: "10px", fontSize: "16px" }}>
                  {selectedCategory.length > 0
                    ? selectedCategory.join(", ")
                    : "Select problem categories"}
                </p>
                <div className="fmt-dropdowntsb">
                  {categoryDropdown ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </div>
              </div>
              {categoryDropdown && (
                <div className="fmt-bottom-sblcb" style={{ width: "90%" }}>
                  <ul>
                    <li className="fmt-listinputbsb">
                      <input
                        placeholder="Filter Category"
                        value={searchedText}
                        onChange={(e) => {
                          const filtered = allCategories.filter((y) =>
                            y.problemCategory
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          );
                          setSearchedText(e.target.value);
                          setNoCategoryFiltered(filtered.length === 0);
                          setCategoriesFiltered(filtered);
                        }}
                      />
                    </li>
                    {noCategoryFiltered ? (
                      <li>No filters</li>
                    ) : categoriesFiltered.length > 0 ? (
                      categoriesFiltered.map((y) => (
                        <li
                          key={y.problemCategory}
                          onClick={() => handleCategoryClick(y.problemCategory)}
                        >
                          {selectedCategory.includes(y.problemCategory) ? (
                            <CheckIcon />
                          ) : null}
                          {y.problemCategory}
                        </li>
                      ))
                    ) : (
                      allCategories.map((y) => (
                        <li
                          key={y.problemCategory}
                          onClick={() => handleCategoryClick(y.problemCategory)}
                        >
                          {selectedCategory.includes(y.problemCategory) ? (
                            <CheckIcon />
                          ) : null}
                          {y.problemCategory}
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              )}
            </div>

            <div className="fmt-select-box-lcb">
              <div
                className={`fmt-top-sblcb ${
                  therapyDropdown && "fmt-topselectedsb"
                }`}
                onClick={() => {
                  setTherapyDropdown((prev) => !prev);
                  setCategoryDropdown(false);
                  setMadropdown(false);
                  setCountryDropdown(false);
                }}
              >
                {selectedTherapy ? (
                  <p style={{ paddingLeft: "10px", fontSize: "16px" }}>
                    {selectedTherapy}
                  </p>
                ) : (
                  <p style={{ paddingLeft: "10px", fontSize: "16px" }}>
                    Select Therapy Type
                  </p>
                )}
                <div className="fmt-dropdowntsb">
                  {therapyDropdown ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </div>
              </div>
              {therapyDropdown && (
                <div className="fmt-bottom-sblcb" style={{ width: "90%" }}>
                  <ul>
                    {therapyType.map((y) => {
                      return (
                        <li
                          onClick={() => {
                            setSelectedTherapy(y.therapy);
                            setTherapyDropdown(false);
                          }}
                        >
                          {y.therapy}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
            <div className="fmt-select-box-lcb">
              <div
                className={`fmt-top-sblcb ${madropdown && "fmt-topselectedsb"}`}
                onClick={() => {
                  setMadropdown((prev) => !prev);
                  setTherapyDropdown(false);
                  setCategoryDropdown(false);
                  setCountryDropdown(false);
                }}
              >
                {selectedMedicalAssistance ? (
                  <p style={{ paddingLeft: "10px", fontSize: "16px" }}>
                    {selectedMedicalAssistance}
                  </p>
                ) : (
                  <p style={{ paddingLeft: "10px", fontSize: "16px" }}>
                    Previous medications
                  </p>
                )}
                <div className="fmt-dropdowntsb">
                  {madropdown ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </div>
              </div>
              {madropdown && (
                <div className="fmt-bottom-sblcb" style={{ width: "90%" }}>
                  <ul>
                    {medicalAssistance.map((y) => {
                      return (
                        <li
                          onClick={() => {
                            setSelectedMedicalAssistance(y);
                            setMadropdown(false);
                          }}
                        >
                          {y}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
            {userInfo.phone && userInfo.categoryId !== 1 && (
                <div className="therapy-medication-mobile-container">
                  <div className="fmt-select-box-lcb" style={{width:'20%'}}>
                    <div
                      className={`fmt-top-sblcb ${
                        countryDropdown && "fmt-topselectedsb"
                      }`}
                      style={{backgroundColor:'#DCF6E9',borderTopRightRadius:'0px',borderBottomRightRadius:'0px',marginRight:'0px'}}
                      onClick={() => {
                        setCountryDropdown((prev) => !prev);
                        setTherapyDropdown(false);
                        setCategoryDropdown(false);
                        setMadropdown(false);
                      }}
                    >
                      {CountryCode ? (
                        <p
                          style={{
                            paddingLeft: "10px",
                            fontSize: "16px",
                          }}
                        >
                          {CountryCode}
                        </p>
                      ) : (
                        <p>Country Code</p>
                      )}
                      <div className="fmt-dropdowntsb">
                        {countryDropdown ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </div>
                    </div>
                    {countryDropdown && (
                      <div className="fmt-bottom-sblcb">
                        <ul>
                          <li className="fmt-listinputbsb">
                            <input
                              style={{ fontSize: "16px" }}
                              placeholder="Filter Country Code"
                              value={codeSearchedText}
                              onChange={(e) => {
                                let filtered = country_codes.filter((y) => {
                                  return y
                                    .toLowerCase()
                                    .includes(e.target.value.toLowerCase());
                                });
                                if (filtered.length > 0) {
                                  setCodeSearchedText(e.target.value);
                                  setNoCodeFiltered(false);
                                  setCodeFiltered(filtered);
                                } else {
                                  setCodeSearchedText(e.target.value);
                                  setCodeFiltered([]);
                                  setNoCodeFiltered(true);
                                }
                              }}
                            />
                          </li>
                          {noCodeFiltered ? (
                            <li>No filters</li>
                          ) : codeFiltered.length > 0 ? (
                            codeFiltered.map((y) => {
                              return (
                                <li
                                  onClick={() => {
                                    setCountryCode(y);
                                    setCountryDropdown(false);
                                  }}
                                >
                                  {y}
                                </li>
                              );
                            })
                          ) : (
                            country_codes.map((y) => {
                              return (
                                <li
                                  onClick={() => {
                                    setCountryCode(y);
                                    setCountryDropdown(false);
                                  }}
                                >
                                  {y}
                                </li>
                              );
                            })
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="fmt-select-box-lcb fmt-mobile-number">
                    <div className={`fmt-top-sblcb`} style={{borderTopLeftRadius:'0px',borderBottomLeftRadius:'0px',flex:1}}>
                      <input
                        style={{
                          width: "100%",
                          maxWidth: "100%",
                          paddinRight: "0",
                          fontSize: "16px",
                        }}
                        type="number"
                        placeholder="Whatsapp Number"
                        value={MobileNumber}
                        onChange={(e) => {
                          if (Number(e.target.value) < 10000000000) {
                            setMobileNumber(e.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
            )}

            <div className="fmt-select-box-lcb fmt-textareaboxsbl">
              <div className={`fmt-top-sblcb`} style={{ borderRadius: "20px" }}>
                <textarea
                  spellCheck={false}
                  placeholder="Give an example why you feel this way"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>

            {showChooseTimeSlotBtn ? (
              <div className="fmt-mobile-btn-Continuemain">
                <button onClick={handleChooseTimeSlotClick}>Continue</button>
              </div>
            ) : (
              <div className="fmt-mobile-btn-Continuemain">
                <button className="timeslotdisabled">Continue</button>
              </div>
            )}
          </div>
        ) : (
          <>
            <div
              className="fmt-container-bselection"
              onClick={handleBookingContainerClick}
            >
              <button
                onClick={() => history.replace("/dashboard")}
                className="backButton"
              >
                <IoIosArrowBack size={18} />
              </button>
              <h3>Find my therapist</h3>
              <div className="fmt-progress-container">
                <div className="progress-line"></div>
                <div
                  className="progress-fill"
                  style={{ width: `${(2 / 9) * 100}%` }}
                ></div>

                <div className="progress-steps">
                  <div className="progress-step active"></div>
                  <div className="progress-step active"></div>
                  <div className="progress-step active"></div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="fmt-left-cbselection">
                  <div className="fmt-select-box-lcb">
                    <div
                      className={`fmt-top-sblcb ${
                        categoryDropdown && "fmt-topselectedsb"
                      }`}
                      onClick={() => {
                        setCategoryDropdown((prev) => !prev);
                        setTherapyDropdown(false);
                        setMadropdown(false);
                        setCountryDropdown(false);
                      }}
                    >
                      <p style={{ paddingLeft: "10px", fontSize: "16px" }}>
                        {selectedCategory.length > 0
                          ? selectedCategory.join(", ")
                          : "Select problem categories"}
                      </p>
                      <div className="fmt-dropdowntsb">
                        {categoryDropdown ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </div>
                    </div>
                    {categoryDropdown && (
                      <div
                        className="fmt-bottom-sblcb"
                        style={{ width: "48%" }}
                      >
                        <ul>
                          <li className="fmt-listinputbsb">
                            <input
                              placeholder="Filter Category"
                              value={searchedText}
                              onChange={(e) => {
                                const filtered = allCategories.filter((y) =>
                                  y.problemCategory
                                    .toLowerCase()
                                    .includes(e.target.value.toLowerCase())
                                );
                                setSearchedText(e.target.value);
                                setNoCategoryFiltered(filtered.length === 0);
                                setCategoriesFiltered(filtered);
                              }}
                            />
                          </li>
                          {noCategoryFiltered ? (
                            <li>No filters</li>
                          ) : categoriesFiltered.length > 0 ? (
                            categoriesFiltered.map((y) => (
                              <li
                                key={y.problemCategory}
                                onClick={() =>
                                  handleCategoryClick(y.problemCategory)
                                }
                              >
                                {selectedCategory.includes(
                                  y.problemCategory
                                ) ? (
                                  <CheckIcon />
                                ) : null}
                                {y.problemCategory}
                              </li>
                            ))
                          ) : (
                            allCategories.map((y) => (
                              <li
                                key={y.problemCategory}
                                onClick={() =>
                                  handleCategoryClick(y.problemCategory)
                                }
                              >
                                {selectedCategory.includes(
                                  y.problemCategory
                                ) ? (
                                  <CheckIcon />
                                ) : null}
                                {y.problemCategory}
                              </li>
                            ))
                          )}
                        </ul>
                      </div>
                    )}
                  </div>

                  <div className="therapy-medication-container">
                    <div className="fmt-select-box-lcb">
                      <div
                        className={`fmt-top-sblcb ${
                          therapyDropdown && "fmt-topselectedsb"
                        }`}
                        onClick={() => {
                          setTherapyDropdown((prev) => !prev);
                          setCategoryDropdown(false);
                          setMadropdown(false);
                          setCountryDropdown(false);
                        }}
                      >
                        {selectedTherapy ? (
                          <p style={{ paddingLeft: "10px", fontSize: "16px" }}>
                            {selectedTherapy}
                          </p>
                        ) : (
                          <p style={{ paddingLeft: "10px", fontSize: "16px" }}>
                            Select Therapy Type
                          </p>
                        )}
                        <div className="fmt-dropdowntsb">
                          {therapyDropdown ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </div>
                      </div>
                      {therapyDropdown && (
                        <div
                          className="fmt-bottom-sblcb"
                          style={{ width: "24%" }}
                        >
                          <ul>
                            {therapyType.map((y) => {
                              return (
                                <li
                                  onClick={() => {
                                    setSelectedTherapy(y.therapy);
                                    setTherapyDropdown(false);
                                  }}
                                >
                                  {y.therapy}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="fmt-select-box-lcb">
                      <div
                        className={`fmt-top-sblcb ${
                          madropdown && "fmt-topselectedsb"
                        }`}
                        onClick={() => {
                          setMadropdown((prev) => !prev);
                          setTherapyDropdown(false);
                          setCategoryDropdown(false);
                          setCountryDropdown(false);
                        }}
                      >
                        {selectedMedicalAssistance ? (
                          <p style={{ paddingLeft: "10px", fontSize: "16px" }}>
                            {selectedMedicalAssistance}
                          </p>
                        ) : (
                          <p style={{ paddingLeft: "10px", fontSize: "16px" }}>
                            Previous medications
                          </p>
                        )}
                        <div className="fmt-dropdowntsb">
                          {madropdown ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </div>
                      </div>
                      {madropdown && (
                        <div
                          className="fmt-bottom-sblcb"
                          style={{ width: "24%" }}
                        >
                          <ul>
                            {medicalAssistance.map((y) => {
                              return (
                                <li
                                  onClick={() => {
                                    setSelectedMedicalAssistance(y);
                                    setMadropdown(false);
                                  }}
                                >
                                  {y}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  {userInfo.phone && userInfo.categoryId !== 1 && (
                    <div className="fmt-mobile-input-wrapper">
                      {window.innerWidth > 900 ? (
                        <div
                          className="fmt-mobile-input-container"
                          onClick={() => {
                            if (!isNumberEditable) {
                              setIsEditModalOpen(true);
                            }
                          }}
                        >
                          {/* <div className="mobile-div"> */}
                          <div className="fmt-select-box-lcb country-code-dropdown">
                            <div
                              style={{
                                backgroundColor: "#E8FDF2",
                                width: "80px",
                                borderTopRightRadius: "0px",
                                borderBottomRightRadius: "0px",
                              }}
                              className={`fmt-top-sblcb ${
                                countryDropdown && "fmt-topselectedsb"
                              }`}
                              onClick={() => {
                                setCountryDropdown((prev) => !prev);
                                setTherapyDropdown(false);
                                setCategoryDropdown(false);
                                setMadropdown(false);
                              }}
                            >
                              {CountryCode ? (
                                <p
                                  style={{
                                    paddingLeft: "10px",
                                    fontSize: "16px",
                                  }}
                                >
                                  {CountryCode}
                                </p>
                              ) : (
                                <p>Country Code</p>
                              )}
                              <div className="fmt-dropdowntsb">
                                {countryDropdown ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </div>
                            </div>
                            {countryDropdown && (
                              <div
                                className="fmt-bottom-sblcb"
                                style={{ width: "10%" }}
                              >
                                <ul>
                                  <li className="fmt-listinputbsb">
                                    <input
                                      placeholder="Filter Country Code"
                                      value={codeSearchedText}
                                      onChange={(e) => {
                                        let filtered = country_codes.filter(
                                          (y) => {
                                            return y
                                              .toLowerCase()
                                              .includes(
                                                e.target.value.toLowerCase()
                                              );
                                          }
                                        );
                                        if (filtered.length > 0) {
                                          setCodeSearchedText(e.target.value);
                                          setNoCodeFiltered(false);
                                          setCodeFiltered(filtered);
                                        } else {
                                          setCodeSearchedText(e.target.value);
                                          setCodeFiltered([]);
                                          setNoCodeFiltered(true);
                                        }
                                      }}
                                    />
                                  </li>
                                  {noCodeFiltered ? (
                                    <li>No matche found</li>
                                  ) : codeFiltered.length > 0 ? (
                                    codeFiltered.map((y) => {
                                      return (
                                        <li
                                          onClick={() => {
                                            setCountryCode(y);
                                            setCountryDropdown(false);
                                          }}
                                        >
                                          {y}
                                        </li>
                                      );
                                    })
                                  ) : (
                                    country_codes.map((y) => {
                                      return (
                                        <li
                                          onClick={() => {
                                            setCountryCode(y);
                                            setCountryDropdown(false);
                                          }}
                                        >
                                          {y}
                                        </li>
                                      );
                                    })
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                          <div className="fmt-select-box-lcb fmt-mobile-number">
                            <div
                              className={`fmt-top-sblcb`}
                              style={{
                                borderTopLeftRadius: "0px",
                                borderBottomLeftRadius: "0px",
                              }}
                            >
                              <input
                                style={{ fontSize: "16px" }}
                                type="number"
                                maxLength={10}
                                placeholder="Whatsapp Number"
                                value={MobileNumber}
                                disabled={!isNumberEditable}
                                onChange={(e) => {
                                  if (Number(e.target.value) < 10000000000) {
                                    setMobileNumber(e.target.value);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="therapy-medication-container">
                          <div className="fmt-select-box-lcb">
                            <div
                              className={`fmt-top-sblcb ${
                                countryDropdown && "fmt-topselectedsb"
                              }`}
                              onClick={() => {
                                setCountryDropdown((prev) => !prev);
                                setTherapyDropdown(false);
                                setCategoryDropdown(false);
                                setMadropdown(false);
                              }}
                            >
                              {CountryCode ? (
                                <p
                                  style={{
                                    paddingLeft: "10px",
                                    fontSize: "16px",
                                  }}
                                >
                                  {CountryCode}
                                </p>
                              ) : (
                                <p>Country Code</p>
                              )}
                              <div className="fmt-dropdowntsb">
                                {countryDropdown ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </div>
                            </div>
                            {countryDropdown && (
                              <div className="fmt-bottom-sblcb">
                                <ul>
                                  <li className="fmt-listinputbsb">
                                    <input
                                      style={{ fontSize: "16px" }}
                                      placeholder="Filter Country Code"
                                      value={codeSearchedText}
                                      onChange={(e) => {
                                        let filtered = country_codes.filter(
                                          (y) => {
                                            return y
                                              .toLowerCase()
                                              .includes(
                                                e.target.value.toLowerCase()
                                              );
                                          }
                                        );
                                        if (filtered.length > 0) {
                                          setCodeSearchedText(e.target.value);
                                          setNoCodeFiltered(false);
                                          setCodeFiltered(filtered);
                                        } else {
                                          setCodeSearchedText(e.target.value);
                                          setCodeFiltered([]);
                                          setNoCodeFiltered(true);
                                        }
                                      }}
                                    />
                                  </li>
                                  {noCodeFiltered ? (
                                    <li>No filters</li>
                                  ) : codeFiltered.length > 0 ? (
                                    codeFiltered.map((y) => {
                                      return (
                                        <li
                                          onClick={() => {
                                            setCountryCode(y);
                                            setCountryDropdown(false);
                                          }}
                                        >
                                          {y}
                                        </li>
                                      );
                                    })
                                  ) : (
                                    country_codes.map((y) => {
                                      return (
                                        <li
                                          onClick={() => {
                                            setCountryCode(y);
                                            setCountryDropdown(false);
                                          }}
                                        >
                                          {y}
                                        </li>
                                      );
                                    })
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                          <div className="fmt-select-box-lcb fmt-mobile-number">
                            <div className={`fmt-top-sblcb`}>
                              <input
                                style={{
                                  width: "100%",
                                  maxWidth: "90%",
                                  paddinRight: "0",
                                  fontSize: "16px",
                                }}
                                type="number"
                                placeholder="Whatsapp Number"
                                value={MobileNumber}
                                onChange={(e) => {
                                  if (Number(e.target.value) < 10000000000) {
                                    setMobileNumber(e.target.value);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="fmt-select-box-lcb fmt-textareaboxsbl">
                    <div
                      className={`fmt-top-sblcb`}
                      style={{ borderRadius: "20px" }}
                    >
                      <textarea
                        spellCheck={false}
                        placeholder="Give an example why you feel this way"
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>

                  {showChooseTimeSlotBtn ? (
                    <div className="fmt-btn-Continuemain">
                      <button onClick={handleChooseTimeSlotClick}>
                        Continue
                      </button>
                    </div>
                  ) : (
                    <div className="fmt-btn-Continuemain">
                      <button className="timeslotdisabled">Continue</button>
                    </div>
                  )}
                </div>
                <div className="fmt-right-cbselection">
                  <img
                    src="https://ucarecdn.com/a2c52ed5-3634-4850-8810-43f638fabf5a/findTherapistBlissImg.svg"
                    alt="Bliss"
                  />
                </div>
              </div>
            </div>
            {isEditModalOpen && (
              <div className="user-modal">
                <div className="signout-modal">
                  <p className="signout-head">
                    Are you sure you want to edit your number?
                  </p>
                  <div className="sbtns">
                    <button
                      className="signout-btn"
                      onClick={() => {
                        setIsNumberEditable(true);
                        setIsEditModalOpen(!isEditModalOpen);
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="signout-btn no"
                      onClick={() => setIsEditModalOpen(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
};

export default BookingSelection;
