import { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import HeaderHomeScreen from "../../components/HeaderHomeScreen";
import "./index.css";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { signinB2B } from "actions/auth";
import { useDispatch } from "react-redux";
import {setAvailableFeatures,setUserInfo,setUserToken,startLoading,stopLoading,} from "store/actions";
import { useUserTokenSelector } from "store/reducers";
import CryptoJS from "crypto-js";
import { loadAllFeatures } from "actions/dataLoad";
import { moodAvailable } from "actions/features";
import Lottie from "lottie-react";
import blissWelcomingFinalWeb from "../../assets/JsonAnimations/blissWelcomingFinalWeb.json";
import blissClosingEyesFinalWeb from "../../assets/JsonAnimations/blissClosingEyesFinalWeb.json";

const EmailLogin = () => {
  const [loggedOutMessage, setLoggedOutMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  const referer = location.state?.referer;
  const dispatch = useDispatch();
  const userToken = useUserTokenSelector();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const passwordRef = useRef(null);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
    setLoggedOutMessage("");
  };

  const whichScreenToRedirect = async (token) => {
    try {
      const res = await moodAvailable(token);
      dispatch(stopLoading());
      //For redirecting back to prev URL
      if (referer) {
        history.push(referer, { redirectFrom: "signin" });
      } else {
        if (res.data.isAvailable) {
          history.push("/dashboard", { redirectFrom: "signin" });
        } else {
          history.push("/dashboard", { redirectFrom: "signin" });
        }
      }
    } catch (err) {
      dispatch(stopLoading());
      history.push("/dashboard", { redirectFrom: "signin" });
    }
  };

  const handleNumberKeypress = (e) => {
    if (e.key === "Enter") {
      handleLoginB2B();
    }
  };

  const handleLoginB2B = async () => {
    try {
      if (email && password) {
        dispatch(startLoading());
        const passwordCipher = CryptoJS.AES.encrypt(
          JSON.stringify(password),
          process.env.REACT_APP_PASSWORD_ENCRYPT_KEY
        ).toString();
        const response = await signinB2B({
          email,
          password: passwordCipher,
          devicetoken: null,
          deviceType: "web",
        });
        if (response.data.status === "success") {
          window.localStorage.setItem("auth", JSON.stringify(response.data));
          dispatch(setUserInfo(response.data.user));
          dispatch(setUserToken(response.data.token));
          const allFeatures = await loadAllFeatures(response.data.token);

          dispatch(setAvailableFeatures(allFeatures.data));
          await whichScreenToRedirect(response.data.token);
          dispatch(stopLoading());
        } else {
          console.error("Something is wrong", response.data);
          dispatch(stopLoading());
        }
      } else {
        setSeverity("error");
        setLoggedOutMessage("Please provide email or password");
        setOpen(true);
      }
    } catch (error) {
      setSeverity("error");
      setLoggedOutMessage(
        error.response?.data?.message ||
          error.response?.data?.error ||
          "An unknown error occurred"
      );
      setOpen(true);
      console.error(
        "error",
        error.response?.data?.message ||
          error.response?.data?.error ||
          "An unknown error occurred"
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div>
      <HeaderHomeScreen />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {loggedOutMessage}
        </Alert>
      </Snackbar>
      <div className="email-screen-main-container">
      {isMobile ? (
        <div style={{backgroundColor:'#e8ebff',display:'flex',flexDirection:'column',alignItems:'center'}}>
        <div className="mobileScreen-email-box-Image">
          <h3>Welcome to GoodLives</h3>
          <Lottie
            animationData={isPasswordFocused  ? blissClosingEyesFinalWeb : blissWelcomingFinalWeb} 
            style={{height:'300px',width:'300px',marginTop:'-50px'}}
            loop={false}
          />
        </div>
        <div className="email-box" style={{marginTop:'0px'}}>
          <p className="email-box-heading">
            Let’s create a more mindful workspace
          </p>
          <div className="mobileScreen-email-form-inner-stuff-lcm" style={{width:'100%'}}>
            <input
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mobileScreen-email-form-inner-stuff-lcm" style={{ position: "relative",width:'100%' }}>
            <input
              ref={passwordRef}
              placeholder="Enter password"
              type={showPassword ? "text" : "password"}
              value={password}
              style={{ paddingRight: "40px" }}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setIsPasswordFocused(true)}
              onBlur={() => setIsPasswordFocused(false)}
              onKeyPress={handleNumberKeypress}
            />
            <img
              src={showPassword 
                ? "https://ucarecdn.com/0d2f75a6-0797-440c-abf9-8912f9ac8f0b/eye.svg" 
                : "https://ucarecdn.com/fcb57aff-9ce6-4ce7-babc-b7d740922a2c/eyeslash.svg"}
              alt="Toggle Password Visibility"
              onClick={togglePasswordVisibility}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                width: "24px",
                height: "24px",
              }}
            />
          </div>

          <p className="forgot-Password-txt" onClick={() => history.push("/forgetPassword")}>
            Forgot Password?
          </p>
          <div className="email-btn-main" onClick={handleLoginB2B}>
            <button>Continue</button>
          </div>
          <div className="or-main-lw" style={{width:'90%',alignSelf:'center'}}>
            <div className="small-line-or"></div>
            <p style={{ marginTop: "13px" }}>Or</p>
            <div className="small-line-or"></div>
          </div>
          <a
            href={`${
              process.env.REACT_APP_SERVER_URI
            }/api/user/auth/google?referer=${referer || "none"}&signin=true`}
            style={{ textDecoration: "none", color: "black", width:'95%' }}
          >
            <div className="noaccountpara" style={{width:'100%'}}>
              Log In with{" "}
              {/* <Link to={{ pathname: "signup", state: { referer: referer } }}> */}
              <img
                src="https://ucarecdn.com/7a98107d-8029-4998-a896-207b1a316f11/googlelogo.png"
                style={{ height: "20px", width: "20px" }}
              />
              {/* </Link> */}
            </div>
          </a>
          <p className="email-txt">
            Don't have an account yet?{" "}
            <Link to="/signup">
              Sign Up
              <img />
            </Link>
          </p>
        </div>
        </div>
      ) : (
        <>
        <div className="email-box">
          <p className="email-box-heading">
            Let’s create a more mindful workspace
          </p>
          <div className="email-form-inner-stuff-lcm">
            <input
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={(e) => {
                if(e.key === "Enter"){
                passwordRef.current?.focus?.();
                }
              }}
            />
            {/* {numberAlertPopup && (
                <p className="alert-field-fisl">{numberAlertPopup}</p>
              )} */}
          </div>
          <div
            className="email-form-inner-stuff-lcm"
            style={{ position: "relative" }}
          >
            <input
              ref={passwordRef}
              placeholder="Enter password"
              type={showPassword ? "text" : "password"}
              value={password}
              style={{ paddingRight: "40px" }}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setIsPasswordFocused(true)}
              onBlur={() => setIsPasswordFocused(false)}
              onKeyPress={handleNumberKeypress}
            />
            <img
              src={showPassword 
                ? "https://ucarecdn.com/0d2f75a6-0797-440c-abf9-8912f9ac8f0b/eye.svg" 
                : "https://ucarecdn.com/fcb57aff-9ce6-4ce7-babc-b7d740922a2c/eyeslash.svg"}
              alt="Toggle Password Visibility"
              onClick={togglePasswordVisibility}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                width: "24px",
                height: "24px",
              }}
            />
          </div>

          <p
            className="forgot-Password-txt"
            onClick={() => history.push("/forgetPassword")}
          >
            Forgot Password?
          </p>
          <div className="email-btn-main" onClick={handleLoginB2B}>
            <button>Continue</button>
          </div>
          <div className="or-main-lw">
            <div className="small-line-or"></div>
            <p style={{ marginTop: "13px" }}>Or</p>
            <div className="small-line-or"></div>
          </div>
          <a
            href={`${
              process.env.REACT_APP_SERVER_URI
            }/api/user/auth/google?referer=${referer || "none"}&signin=true`}
            style={{ textDecoration: "none", color: "black" }}
          >
            <div className="noaccountpara">
              Log In with{" "}
              {/* <Link to={{ pathname: "signup", state: { referer: referer } }}> */}
              <img
                src="https://ucarecdn.com/7a98107d-8029-4998-a896-207b1a316f11/googlelogo.png"
                style={{ height: "20px", width: "20px" }}
              />
              {/* </Link> */}
            </div>
          </a>
          <p className="email-txt">
            Don't have an account yet?{" "}
            <Link to="/signup">
              Sign Up
              <img />
            </Link>
          </p>
        </div>
        <div className="email-box-Image">
          <h3>Welcome to GoodLives</h3>
          <Lottie
            // key={isPasswordFocused ? "closing" : "welcoming"}
            animationData={isPasswordFocused ? blissClosingEyesFinalWeb : blissWelcomingFinalWeb}
            style={{ height: "300px", width: "300px", marginLeft: "8vh" }}
            loop={false}
          />
        </div>
        </>
      )}
      </div>
    </div>
  );
};

export default EmailLogin;