import { configureStore } from "@reduxjs/toolkit";
import {
  authReducer,
  userReducer,
  featureReducer,
  loadingReducer,
  VideoDataReducer,
  WellnessLibraryReducer
} from "./reducers";

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    features: featureReducer,
    loading: loadingReducer,
    videoData: VideoDataReducer,
    library: WellnessLibraryReducer
  },
});

export default store;
