import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import AOS from "aos";
import { logout } from "../../actions/auth";
import { loadAllPaymentPlans } from "../../actions/dataLoad";
import "aos/dist/aos.css";
import PaymentPlan from "../Payment Plan/index.js";
import { useHistory } from "react-router";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useLocation } from "react-router-dom";
// ..
import "./index.css";
import { logoutUser, startLoading, stopLoading } from "store/actions";
import { useUserInfoSelector, useUserTokenSelector } from "store/reducers";
import { IoIosArrowBack } from "react-icons/io";
AOS.init();

export const BookingCalendar = (props) => {
  const [currentDate, setCurrentDate] = useState("");
  const [selectedTime, setSelectedTime] = useState({});
  const [disabled, setDisabled] = useState([1, 6, 10, 13, 29]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [showPayment, setShowPayment] = useState(false);
  const [plans, setPlans] = useState([]);
  const [walletSessionBalance, setWalletSessionBalance] = useState(0);
  const [currency, setCurrency] = useState("");
  const [timeSlotByCategory, setTimeSlotByCategory] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const userToken = useUserTokenSelector();
  const { categoryId } = useUserInfoSelector();
  const firstBooking = props.firstBooking;
  const therapistsName = props.allEvents.therapistsFullName;
  const location = useLocation();
  const [expandedPeriods, setExpandedPeriods] = useState({
    morning: false,
    afternoon: false,
    evening: false,
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleExpand = (period) => {
    setExpandedPeriods((prev) => ({
      morning: period === "morning" ? !prev.morning : false,
      afternoon: period === "afternoon" ? !prev.afternoon : false,
      evening: period === "evening" ? !prev.evening : false,
    }));
  };

  const showError = (text) => {
    setError(text);
    setOpen(true);
  };
  
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
  };

  function calcCurrentTimeIST() {
    var offset = "+5.5"; //IST
    // create Date object for current location
    var date = new Date();

    // get UTC time in msec
    var utc = date.getTime() + date.getTimezoneOffset() * 60000;

    // create new Date object IST Timezone` using supplied offset
    var newDate = new Date(utc + 3600000 * offset);

    // return IST DateTime
    return newDate;
  }

  const dateToday = () => {
    // If Current Time converted in IST + 6 Hours in Future is Greater than 11PM IST, disable today's date for user selection
    if (calcCurrentTimeIST().getHours() + 6 > 23) {
      return new Date(
        new Date(
          calcCurrentTimeIST().setDate(calcCurrentTimeIST().getDate() + 1)
        ).setHours(0, 0, 0, 0)
      );
    } else {
      return new Date(new Date().setHours(0, 0, 0, 0));
    }
  };
  // Calendar Visibility limited to 30 days in future
  let futureDate = new Date(
    new Date(new Date().setDate(new Date().getDate() + 30)).setHours(
      23,
      59,
      59,
      0
    )
  );

  useEffect(() => {
    /*let currentDay = new Date()
        let daysBeforeToday = []
        let date = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        while (date.getMonth() === currentDay.getMonth()) {
        if (new Date(date).getDate() < new Date().getDate()) {
        daysBeforeToday.push(new Date(date).getDate())
        }
        date.setDate(date.getDate() + 1)
        }
        let concatted = daysBeforeToday.concat(disabled)
        let mixtured = concatted.filter(
      (v, i, a) => a.findIndex((t) => t === v) === i
        )
    this.setState({ disabled: mixtured })*/
  }, []);

  const showAvailablePaymentPlans = async () => {
    try {
      dispatch(startLoading())
      const res = await loadAllPaymentPlans(userToken);
      setPlans(res.data.plans);
      setWalletSessionBalance(res.data.walletSessionBalance);
      setCurrency(res.data.currency);
      const deductFromWalletPlan = res.data.plans.find(plan => plan.planID === 1001);
      if(deductFromWalletPlan.status && res.data.walletSessionBalance >= 1) {
        payAndBookEvent(deductFromWalletPlan);
      } else {
      dispatch(stopLoading())
      }
      setShowPayment(true);
    } catch (err) {
      /**replace with front end error */
      dispatch(stopLoading())
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname} }), 3000)
      } else {
        showError(err.response.data.error);
      }
    }
  };

  const showAvailableTimeSlots = async (e) => {
    try {
      setAvailableSlots([]);
      setSelectedTime({});
      setCurrentDate(e);

      let date = e.toLocaleDateString("en-IN");
      let outputSlots = props.allEvents.allEvents[date];
      
      if (!outputSlots) {
        throw new Error("No slot available!")
      }

      outputSlots.sort((a, b) => {
        if (a.hours === b.hours) {
          return a.minutes - b.minutes;
        }
        return a.hours - b.hours;
      });

      if (outputSlots) {
        setAvailableSlots(outputSlots);
      } else {
        setAvailableSlots([]);
      }
    } catch (err) {
      showError(err.message);
    }
  };

  const payAndBookEvent = async (selectedPlan) => {
    try {
      await props.payAndBookEvent(selectedPlan, currentDate, selectedTime);
    } catch (err) {
      if (err.response.data.redirectToSignIn) {
        throw err;
      } else {
        throw err;
      }
    }
  };

  const handleLogout = () => {
    logout(userToken);

    logoutUser(dispatch);

    window.localStorage.removeItem("auth");

    history.push({
      pathname: "/signin",
      state: {
        loggedOutMessage: "You have been logged out. Please login again.",
      },
    });
  };

  function groupTimeSlotsByCategory(slots) {
    const groupedSlots = {
      morning: [],
      afternoon: [],
      evening: [],
    };

    slots &&
      slots.forEach((slot) => {
        const { hours } = slot;
        if (hours >= 11 && hours < 13) {
          groupedSlots.morning.push(slot);
        } else if (hours >= 13 && hours < 18) {
          groupedSlots.afternoon.push(slot);
        } else {
          groupedSlots.evening.push(slot);
        }
      });

    return groupedSlots;
  }

  useEffect(() => {
    const data = groupTimeSlotsByCategory(availableSlots);
    setTimeSlotByCategory(data);
  }, [availableSlots]);
  
  useEffect(() => {
    const allEvents = props.allEvents.allEvents || {};
    const sortedDates = Object.keys(allEvents)
      .map((date) => {
        const [day, month, year] = date.split("/").map(Number);
        return new Date(year, month - 1, day);
      })
      .sort((a, b) => a - b);
  
    for (let date of sortedDates) {
      const formattedDate = date.toLocaleDateString("en-IN");
      if (allEvents[formattedDate] && allEvents[formattedDate].length > 0) {
        setCurrentDate(date);
        showAvailableTimeSlots(date);
        break;
      }
    }
  }, [props.allEvents.allEvents]);

   // Add event listener for Enter key when a time slot is selected
   useEffect(() => {
    // Function to handle the Enter key press
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && Object.keys(selectedTime).length !== 0) {
        // If a time slot is selected, show the payment plans
        showAvailablePaymentPlans();

        // Remove the event listener to stop listening after the Enter key is pressed
        window.removeEventListener("keydown", handleKeyPress);
      }
    };

    // Only add the event listener if a slot is selected
    if (Object.keys(selectedTime).length !== 0) {
      window.addEventListener("keydown", handleKeyPress);
    }

    // Cleanup function to remove the event listener when component unmounts or when selectedTime changes
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedTime]);
  
  if (showPayment)
    return (
      <PaymentPlan
        categoryId={categoryId}
        plans={plans}
        walletSessionBalance={walletSessionBalance}
        payAndBookEvent={payAndBookEvent}
        errorPayment={props.errorPayment}
        setErrorPayment={props.setErrorPayment}
        loading={props.loading}
        setShowPayment={setShowPayment}
        currency={currency}
        handleLogout={handleLogout}
      />
    );
  else
    return (
      <div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {error}
          </Alert>
        </Snackbar>
        {isMobile ? (
          <div className="ba-mobileScreen-container">
          <div className="ba-mobileScreen-header">
          {<button onClick={() => firstBooking ? props.setShowCalendar(false) : history.replace("/dashboard")} className='ba-mobileScreen-backButton'>
            <IoIosArrowBack size={13} style={{marginTop:'-6px'}}/>
          </button>}
          <h3>Book Appointment</h3>
          <div className='ba-mobileScreen-backButton'  style={{backgroundColor:'#fffcf8'}}/>
          </div>
          <div className="ba-mobileScreen-progress-container">
            <div className="progress-line"></div>
            <div
              className="progress-fill"
              style={{ width: `${(2 / 6) * 100}%` }}
            ></div>
            <div className="progress-steps">
              <div className="ba-mobileScreen-progress-step completed">✔</div>
              <div className="ba-mobileScreen-progress-step active"></div>
              <div className="ba-mobileScreen-progress-step active"></div>
            </div>
          </div>
          <div className="ba-mobileScreen-unlockBox">
            <img src="https://ucarecdn.com/db25b287-681f-4be9-919c-5ba9e6f6a3c7/BookAppointmentDP.svg" alt="dp"/>
            <p style={{marginTop:'6px'}}>Book to unlock</p>
          </div>
          <div className="ba-mobileScreen-calendar-rccbooking">
                <p className="ba-mobileScreen-calendar-heading-subtitle">
                  Calendar displayed in{" "}
                  <span>
                    {new Date().toTimeString().split("(")[1].split(")")[0]}
                  </span>
                  <br></br>({new Date().toTimeString().split(" ")[1]} Timezone)
                </p>
                <Calendar
                  onChange={showAvailableTimeSlots}
                  value={currentDate}
                  showNeighboringMonth={false}
                  defaultView="month"
                  tileClassName={({ date }) => {
                    const formattedDate = date.toLocaleDateString("en-IN");
                    return props.allEvents.allEvents[formattedDate] ? "available-slot" : "";
                  }}
                  tileDisabled={({ activeStartDate, date }) => {
                    return date < dateToday() || date > futureDate;
                  }}
                />
                <div className="calendar-legend">
                  <div className="legend-item">
                    <span className="selected-box"></span> Selected
                  </div>
                  <div className="legend-item">
                    <span className="available-box"></span> Available
                  </div>
                </div>
              </div>
              {availableSlots && (
                <div
                  className="ba-mobileScreen-timeslots-rccbooking"
                  style={{
                    opacity: currentDate ? 1 : 0,
                    pointerEvents: currentDate ? "all" : "none",
                  }}
                >
                  <p>Choose Session time</p>
                  {(() => {
                    const timeSlotByCategory = {
                      morning: [],
                      afternoon: [],
                      evening: [],
                    };
              
                    availableSlots.forEach(({ hours, minutes }) => {
                      if (hours >= 6 && hours < 12) {
                        timeSlotByCategory.morning.push({ hours, minutes });
                      } else if (hours >= 12 && hours < 17) {
                        timeSlotByCategory.afternoon.push({ hours, minutes });
                      } else if (hours >= 17 && hours <= 23) {
                        timeSlotByCategory.evening.push({ hours, minutes });
                      }
                    });
              
                    return (
                      <div className="ba-mobileScreen-choose-session-container">
                        {["morning", "afternoon", "evening"].map((period) => {
                          const slots = timeSlotByCategory[period] || [];
                          const isExpanded = expandedPeriods[period];
                          return slots.length > 0 ? (
                          <div key={period} className="time-slot-category">
                            <h5 className="time-period-title">
                              {period.charAt(0).toUpperCase() + period.slice(1)}
                            </h5>
                            <div className="slots-div">
                              {slots.slice(0, isExpanded ? slots.length : 5).map((slot) => (
                                <TimeSlots
                                  key={`${slot.hours}:${slot.minutes}`}
                                  hours={slot.hours}
                                  minutes={slot.minutes}
                                  setSelectedTime={setSelectedTime}
                                  selectedTime={selectedTime}
                                />
                              ))}
                              {slots.length > 5 && (
                                <button className="see-more-btn" style={{fontSize:'12px'}} onClick={() => toggleExpand(period)}>
                                  {isExpanded ? "See Less" : "See More"}
                                </button>
                              )}
                            </div>
                          </div>
                        ) : null
                      })}
                      </div>
                    );
                  })()}
                </div>
              )}
              
              <div className="ba-mobileScreen-btn-Continuemain">
              {currentDate && Object.entries(selectedTime).length !== 0 ? (
                <button onClick={() => {showAvailablePaymentPlans()}}>
                  Confirm
                </button>
              ) : (
                <button className="ba-timeslotdisabledd">
                  Confirm
                </button>
              )}
            </div>

          </div>
        ) : (
        <div className="calendar-booking">
          {<button onClick={() => firstBooking ? props.setShowCalendar(false) : history.replace("/dashboard")} className='backButton'>
              <IoIosArrowBack size={18} />
          </button>}
          <h3>Book Appointment</h3>
          <div className="ba-progress-container">
          <div className="progress-line"></div>
          <div
            className="progress-fill"
            style={{ width: `${(2 / 7) * 100}%`  }}
          ></div>

          <div className="progress-steps">
            <div className="progress-step completed" style={{color:'white',backgroundColor:'#49af7c',borderColor:'#49af7c'}}>✔</div>
            <div className="progress-step active"></div>
            <div className="progress-step active"></div>
          </div>
        </div>
          <div className="unlockBox">
            <img src="https://ucarecdn.com/db25b287-681f-4be9-919c-5ba9e6f6a3c7/BookAppointmentDP.svg" alt="dp"/>
            <p style={{marginTop:'6px'}}>Book to unlock</p>
          </div>
          
          <div style={{display:'flex',flexDirection:'row', alignSelf:'center'}}>
          <div className="leftBookingBox">
          <div className="calendar-rccbooking">
                <p className="calendar-heading-subtitle">
                  Calendar displayed in{" "}
                  <span>
                    {new Date().toTimeString().split("(")[1].split(")")[0]}
                  </span>
                  <br></br>({new Date().toTimeString().split(" ")[1]} Timezone)
                </p>
                <Calendar
                  onChange={showAvailableTimeSlots}
                  value={currentDate}
                  showNeighboringMonth={false}
                  defaultView="month"
                  tileClassName={({ date }) => {
                    const formattedDate = date.toLocaleDateString("en-IN");
                    return props.allEvents.allEvents[formattedDate] ? "available-slot" : "";
                  }}
                  tileDisabled={({ activeStartDate, date }) => {
                    return date < dateToday() || date > futureDate;
                  }}
                />
                <div className="calendar-legend">
                  <div className="legend-item">
                    <span className="selected-box"></span> Selected
                  </div>
                  <div className="legend-item">
                    <span className="available-box"></span> Available
                  </div>
                </div>
              </div>
          </div>
            
            <div className="right-box-bookingImage">
              {availableSlots && (
                <div
                  className="timeslots-rccbooking"
                  style={{
                    opacity: currentDate ? 1 : 0,
                    pointerEvents: currentDate ? "all" : "none",
                  }}
                >
                  <p>Choose Session time</p>
                  {(() => {
                    const timeSlotByCategory = {
                      morning: [],
                      afternoon: [],
                      evening: [],
                    };
              
                    availableSlots.forEach(({ hours, minutes }) => {
                      if (hours >= 6 && hours < 12) {
                        timeSlotByCategory.morning.push({ hours, minutes });
                      } else if (hours >= 12 && hours < 17) {
                        timeSlotByCategory.afternoon.push({ hours, minutes });
                      } else if (hours >= 17 && hours <= 23) {
                        timeSlotByCategory.evening.push({ hours, minutes });
                      }
                    });
              
                    return (
                      <div className="choose-session-container">
                        {["morning", "afternoon", "evening"].map((period) => {
                          const slots = timeSlotByCategory[period] || [];
                          const isExpanded = expandedPeriods[period];
                          return slots.length > 0 ? (
                          <div key={period} className="time-slot-category">
                            <h5 className="time-period-title">
                              {period.charAt(0).toUpperCase() + period.slice(1)}
                            </h5>
                            <div className="slots-div" 
                            style={{justifyContent:'flex-start',paddingLeft:'0px'}}
                            >
                              {slots.slice(0, isExpanded ? slots.length : 5).map((slot) => (
                                <TimeSlots
                                  key={`${slot.hours}:${slot.minutes}`}
                                  hours={slot.hours}
                                  minutes={slot.minutes}
                                  setSelectedTime={setSelectedTime}
                                  selectedTime={selectedTime}
                                />
                              ))}
                              {slots.length > 5 && (
                                <button className="see-more-btn" onClick={() => toggleExpand(period)}>
                                  {isExpanded ? "See Less" : "See More"}
                                </button>
                              )}
                            </div>
                          </div>
                        ) : null
                      })}
                      </div>
                    );
                  })()}
                </div>
              )}
            </div>
            </div>
            <div className="btn-loginmain">
              {currentDate && Object.entries(selectedTime).length !== 0 ? (
                <button onClick={() => {showAvailablePaymentPlans()}}>
                  Confirm
                </button>
              ) : (
                <button className="timeslotdisabled">
                  Confirm
                </button>
              )}
            </div>
          </div>
        )}
        </div>
    );
};

export default BookingCalendar;

const TimeSlots = ({ minutes, hours, setSelectedTime, selectedTime }) => {
  return (
    <button
      onClick={() => {
        setSelectedTime({ minutes, hours });
      }}
      className={
        hours === selectedTime.hours &&
        minutes === selectedTime.minutes ?
        "active" : ""
      }
    >
      {hours}:{minutes === 0 ? "00" : minutes}
    </button>
  );
};
