import { useEffect, useRef } from "react";
import "antd/dist/antd.css";
import "./HappinessCoinsModal.css";
import Lottie from "lottie-react";
import coins2web from "../../assets/JsonAnimations/coins2web.json";

export const HappinessCoinsModal = ({ isOpenCoins, onCloseCoins ,coinsCount = 0 }) => {
  const modalRef = useRef(null);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          onCloseCoins();
        }
      };
  
      if (isOpenCoins) {
        document.addEventListener("mousedown", handleClickOutside);
      }
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isOpenCoins, onCloseCoins]);
  
    if (!isOpenCoins) return <></>;

  return (
    <div className="happiness-modal-overlay" onClick={onCloseCoins}>
    <div className="happiness-modal-content" style={{ width: "430px" }} ref={modalRef}>
      <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end'}}>
      <Lottie
      animationData={coins2web}
      style={{ height: "25px", width: "30px", alignSelf: "center",marginTop:'-18px'}}
      />
      <p>{coinsCount}</p>
      </div>
      <img src="https://ucarecdn.com/9174b506-27d4-43c2-b8ac-e0670bf2e468/happinessCoin.svg" alt='coin' style={{height:'153px',width:'150px',alignSelf:'center', marginBottom:'5px'}}/>
      <p className="yeyTxt">Yeh!</p>
      <p style={{marginTop:'-20px'}}>You earned {coinsCount} happiness coins</p>
      
    </div>
    </div>
  );
};

export default HappinessCoinsModal;
