import React, { useState, useEffect } from "react";
import { useLoadingSelector } from "store/reducers";
import "./Loading.css";
import Lottie from "lottie-react";
import loadingBliss from "../../../assets/JsonAnimations/loadingBliss.json";
import loadingAnimation from "../../../assets/JsonAnimations/loadingAnimation.json";

const Loading = ({ show }) => {
  const isLoading = useLoadingSelector();

  return (
    <div
      className={`fp-container-bliss ${
        !(show || isLoading) ? "fp-container-bliss-hidden" : ""
      }`}
    >
      <Lottie
        animationData={loadingAnimation}
        style={{height: "500px",width: "500px"}}
        loop={true}
        className="fp-loader-bliss"
      />
      {/* <Lottie
        animationData={loadingBliss}
        style={{height: "300px",width: "300px"}}
        loop={false}
      /> */}
    </div>
  );
};

export default Loading;
