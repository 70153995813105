export const SET_LOADING = "SET_LOADING";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const REMOVE_USER_TOKEN = "REMOVE_USER_TOKEN";
export const SET_USER_INFO = "SET_USER_INFO";
export const REMOVE_USER_INFO = "REMOVE_USER_INFO";
export const SET_AVAILABLE_FEATURES = "SET_AVAILABLE_FEATURES";
export const REMOVE_AVAILABLE_FEATURES = "REMOVE_AVAILABLE_FEATURES";
export const SET_VIDEO_ITEM = "SET_VIDEO_ITEM";
export const REMOVE_VIDEO_ITEM = "REMOVE_VIDEO_ITEM";
export const SET_USER_COINS = "SET_USER_COINS";
export const SET_USER_STREAK = "SET_USER_STREAK";
export const SET_LIBRARY_DATA = "SET_LIBRARY_DATA";
export const SET_LIBRARY_CATEGORIES_LOADING = "SET_LIBRARY_CATEGORIES_LOADING";
export const SET_LIBRARY_CATEGORY_LOADING = "SET_LIBRARY_CATEGORY_LOADING";
export const SET_LIBRARY_CATEGORY_DATA = "SET_LIBRARY_CATEGORY_DATA";
export const SET_lIBRARY_SELECTED_CATEGORY = "SET_lIBRARY_SELECTED_CATEGORY";
export const SET_LIBRARY_CATEGORIES = "SET_LIBRARY_CATEGORIES";
export const REMOVE_LIBRARY_DATA = "REMOVE_LIBRARY_DATA"; 

export const startLoading = () => ({
  type: SET_LOADING,
  payload: true,
});

export const stopLoading = () => ({
  type: SET_LOADING,
  payload: false,
});

export const setUserToken = (token) => ({
  type: SET_USER_TOKEN,
  payload: token,
});

export const removeUserToken = () => ({
  type: REMOVE_USER_TOKEN,
});

export const setUserInfo = (user) => ({
  type: SET_USER_INFO,
  payload: user,
});

export const removeUserInfo = () => ({
  type: REMOVE_USER_INFO,
});

export const setAvailableFeatures = (features) => ({
  type: SET_AVAILABLE_FEATURES,
  payload: features,
});

export const removeAvailableFeatures = () => ({
  type: REMOVE_AVAILABLE_FEATURES,
});

export const setVideoItem = (videoData) => ({
  type: SET_VIDEO_ITEM,
  payload: videoData,
});

export const removeVideoItem = () => ({
  type: REMOVE_VIDEO_ITEM,
});

export const setWellnessLibraryData = (data) => ({
  type: SET_LIBRARY_DATA,
  payload: data
});

export const setWellnessLibraryCategoriesLoading = (data) => ({
  type: SET_LIBRARY_CATEGORIES_LOADING,
  payload: data
});

export const setWellnessLibraryCategoryLoading = ({selectedCategory, value}) => ({
  type: SET_LIBRARY_CATEGORY_LOADING,
  payload: {selectedCategory, value}
});

export const setWellnessLibraryCategoryData = ({selectedCategory, value}) => ({
  type: SET_LIBRARY_CATEGORY_DATA,
  payload: {selectedCategory, value}
});

export const setWellnessLibrarySelectedCategory = (data) => ({
  type: SET_lIBRARY_SELECTED_CATEGORY,
  payload: data
});

export const setWellnessLibraryCategories = (data) => ({
  type: SET_LIBRARY_CATEGORIES,
  payload: data
});

export const clearWellnessLibraryData = () => ({
  type: REMOVE_LIBRARY_DATA,
  payload: null
});

export const setUserCoins = (value) => ({
  type: SET_USER_COINS,
  payload: value
});

export const setUserStreak = (value) => ({
  type: SET_USER_STREAK,
  payload: value
});


export const logoutUser = (dispatch) => {
  dispatch(removeUserToken());
  dispatch(removeUserInfo());
  dispatch(removeAvailableFeatures());
}