import { Component } from "react";
import "./index.css";
import MySpaceIcon from "assets/svg_icons/MySpaceIcon.svg";
import ChallengesIcon from "assets/svg_icons/challenges_icon.svg";
import LibraryIcon from "assets/svg_icons/library_icon.svg";
import EventsIcon from "assets/svg_icons/events_icon.svg";
import BookingsIcon from "assets/svg_icons/bookings_icon.svg";
import AssessmentIcon from "assets/svg_icons/assessment_icon.svg";
import { GoGraph } from "react-icons/go";
import {
  ANALYTICS, ASSESSMENTS, CHALLENGES,
  EVENTS,
  LIBRARY, MY_BOOKINGS,
  OVERVIEW
} from "./UserDashboard";

export class LeftMenuB2B extends Component {
  componentDidMount() {
    if (this.props.bydefaultOnBooking) {
    }
  }

  render() {
    return (
      <div className="left-menustuff">
        <p className="title-lms">
          GoodLives<sup style={{ fontSize: "14px" }}>TM</sup>
        </p>
        <div className="content-lms">
          <ul>
            <li
              className={`${this.props.selectedTab === OVERVIEW ? "tabActive" : ""}`}
              onClick={() => {
                this.props.showOverView();
              }}
            >
              <img src={MySpaceIcon} className="menu-icon" /> My Space
            </li>
            <li
              className={`${this.props.selectedTab === CHALLENGES ? "tabActive" : ""}`}
              onClick={() => {
                this.props.showChallenges();
              }}
            >
              <img src={ChallengesIcon} className="menu-icon" /> Challenges
            </li>
            <li
              className={`${this.props.selectedTab === EVENTS ? "tabActive" : ""}`}
              onClick={() => {
                this.props.showEvents();
              }}
            >
              <img src={EventsIcon} className="menu-icon" /> Events
            </li>
            <li
              className={`${this.props.selectedTab === LIBRARY ? "tabActive" : ""}`}
              onClick={() => {
                this.props.showLibrary();
              }}
            >
              <img src={LibraryIcon} className="menu-icon" /> Wellness Library
            </li>
            <li
              className={`${this.props.selectedTab === ANALYTICS ? "tabActive" : ""}`}
              onClick={() => {
                this.props.showAnalytics();
              }}
            >
              <GoGraph className="menu-icon" /> My Analytics
            </li>
            <li
              className={`${this.props.selectedTab === MY_BOOKINGS ? "tabActive" : ""}`}
              onClick={() => {
                this.props.showMyBookings();
              }}
            >
              <img src={BookingsIcon} className="menu-icon" /> My bookings
            </li>
            <li
              className={`${this.props.selectedTab === ASSESSMENTS ? "tabActive" : ""}`}
              onClick={() => {
                this.props.showAssessments();
              }}
            >
              <img src={AssessmentIcon} className="menu-icon" /> Assessments
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default LeftMenuB2B;
