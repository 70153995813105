import { useEffect, useState } from "react";
// import logo from "../assets/images/headerbookinglogo.png";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { ImFire } from "react-icons/im";
import { SlBell } from "react-icons/sl";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import { Link, useHistory } from "react-router-dom";
import MySpaceIconBlack from "assets/svg_icons/MySpaceIcon_black.svg";
import ChallengesIconBlack from "assets/svg_icons/challenges_icon_black.svg";
import LibraryIconBlack from "assets/svg_icons/library_icon_black.svg";
import EventsIconBlack from "assets/svg_icons/events_icon_black.svg";
import BookingsIconBlack from "assets/svg_icons/bookings_icon_black.svg";
import AssessmentIconBlack from "assets/svg_icons/assessment_icon_black.svg";
import NotificationsIcon from "assets/svg_icons/notifications_icon.svg";
import {
  ANALYTICS, ASSESSMENTS, CHALLENGES,
  EVENTS,
  LIBRARY, MY_BOOKINGS,
  OVERVIEW
} from "./UserDashboard";
import { useUserInfoSelector } from "store/reducers";
import { GoGraph } from "react-icons/go";
import ProfileMenu from "./ProfileMenu/ProfileMenu";
import StreakModal from "./StreakModal/StreakModal";
import HappinessCoinsModal from "./HappinessCoinsModal/HappinessCoinsModal";
import Lottie from "lottie-react";
import fire from "../assets/JsonAnimations/fire.json";
import coins2web from "../assets/JsonAnimations/coins2web.json";

const avatar = {
  female: "https://ucarecdn.com/5c10944e-91bc-4ee6-a259-23565aac6100/avatar3.png",
  male: "https://ucarecdn.com/e6d48801-632e-4d97-b297-bd15292f07ed/avatar2.png",
  other: "https://ucarecdn.com/732dfe6d-fdf3-40c5-a066-e8857ce85d23/avatar1.png",
};

const HeaderDashboard = ({ showProfileMenu, setShowProfileMenu, setShowStreakPopup, setShowCoinsPopup, notifications=[], ...props }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showNotificationsPanel, setShowNotificationsPanel] = useState(false);
  const userInfo = useUserInfoSelector();
  const history = useHistory();



  return (
    <div className="headerbookings" style={{ position: "fixed", backgroundColor: "white" }}>
      <img src="https://ucarecdn.com/4748640f-b537-4b9b-8896-362944eaaae3/headerbookinglogo.png" className="image-logo-hb" />
      <div className="lists-header-booking">
        <ul>
          <li>
            <Link to="/" style={{ textDecoration: "none", color: "#212529" }}>
              Home
            </Link>
          </li>
          <li>
            <a href="https://www.goodlives.in/how-it-works/" target="_blank" style={{ textDecoration: "none", color: "#212529" }}>
              How it works?
            </a>
          </li>
          <li>
            <Link to="/corporates" style={{ textDecoration: "none", color: "#212529" }}>
              For Corporates
            </Link>
          </li>
          <li>
            <a href="https://www.goodlives.in/blog/" target="_blank" style={{ textDecoration: "none", color: "#212529" }}>
              Blogs
            </a>
          </li>
        </ul>
      </div>
      <div className="menu-header-booking">
        <div className="headertop-info" onClick={() => {setShowStreakPopup((val) => !val)}}>
        {userInfo.streakCount > 0 ? (
          <Lottie
          animationData={fire}
          style={{ height: "28px", width: "30px", alignSelf: "center"}}
          />
        ) : (          
          <img 
            src="https://ucarecdn.com/b36951dd-d46e-405e-9e27-a11f6a523027/greyFire.svg"
            alt="fire"
            style={{height: "25px", width: "25px",}}
          />
        )}
          <span>{userInfo.streakCount}</span>
        </div>
        <div className="headertop-info">
          <Lottie
          animationData={coins2web}
          style={{ height: "30px", width: "35px", alignSelf: "center"}}
          onClick={() => {
            setShowCoinsPopup((val) => !val);
          }}
          />
          <span>{userInfo.coinsBalance}</span>
        </div>
        <div className="lists-tn notificationsboxcontainer">
          <img 
            src={NotificationsIcon}
            style={{ cursor: "pointer", height: "20px", width: "20px" }}
            onClick={() => {
              setShowNotificationsPanel(val => !val);
            }}
          />
          {/* <div className="ring-counts">
            <p>{notifications.length}</p>
          </div> */}
          {showNotificationsPanel && (
            <div className="inner-notificationboxmain">
              <div className="top-inbm">
                <p>Notifications</p>
              </div>
              <div className="bottom-inbm">
                {notifications.length > 0 ? (
                  <ol>
                    {notifications.map((e, i) => {
                      return (
                        <li
                          onClick={() => {
                            if (e.type === "booksession") {
                              props.showMyBookings();
                            }
                          }}
                        >
                          {i + 1}. {e.message}
                        </li>
                      );
                    })}
                  </ol>
                ) : (
                  <p className="nonewnotficiationslabel">
                    <NotificationsOffIcon />
                    <font>
                      No New <br /> Notifications
                    </font>
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        <button
          onClick={() => {
            setShowProfileMenu((val) => !val);
          }}
          className={`profile-icon ${!!showProfileMenu ? "active" : ""}`}
        >
          {userInfo && <img src={userInfo.avatar_url ? userInfo.avatar_url : avatar[userInfo.gender]} alt="user-avatar" className="avatar" />}
        </button>
        {showProfileMenu && (
          <ProfileMenu
            onEditProfileClick={() => {
              setShowProfileMenu(false);
              props.toggleProfilePopup();
            }}
            onRedeemCoinsClick={() => {
              setShowProfileMenu(false);
              history.push("/dashboard/redeem");
            }}
            onChangeTherapistClick={() => {
              setShowProfileMenu(false);
              history.push("/dashboard/sessionBooking", { change: true });
            }}
            onContactUsClick={() => {
              setShowProfileMenu(false);
              history.push("/dashboard/support", { change: true });
            }}
            onBackClick={() => {
              setShowProfileMenu(false);
              setMenuOpen(true);
            }}
          />
        )}
        <div className="lists-tn">
          <MenuIcon
            onClick={() => {
              setMenuOpen(true);
            }}
          />
        </div>
      </div>
      {menuOpen && (
        <div className="menu-lists-mobile">
          <CloseIcon
            style={{
              position: "absolute",
              top: 15,
              right: 15,
              zIndex: 99,
              fill: "black",
            }}
            onClick={() => {
              setMenuOpen(false);
            }}
          />
          <div className="left-menustuff">
            <p className="title-lms">
              GoodLives<sup style={{ fontSize: "14px" }}>TM</sup>
            </p>
            <button
              onClick={() => {
                setShowProfileMenu((val) => !val);
                setMenuOpen(false);
              }}
              className="left-menu-myprofile-btn"
            >
              {userInfo && <img src={userInfo.avatar_url ? userInfo.avatar_url : avatar[userInfo.gender]} alt="user-avatar" className="avatar" />}
              <div>My Profile</div>
            </button>
            <div className="content-lms">
              <ul>
                <li
                  className={`${props.selectedTab === OVERVIEW ? "tabActive" : ""}`}
                  onClick={() => {
                    props.showOverView();
                    setMenuOpen(false);
                  }}
                >
                  <img src={MySpaceIconBlack} color="#000" className="menu-icon" /> My Space
                </li>
                <li
                  className={`${props.selectedTab === CHALLENGES ? "tabActive" : ""}`}
                  onClick={() => {
                    props.showChallenges();
                    setMenuOpen(false);
                  }}
                >
                  <img src={ChallengesIconBlack} className="menu-icon" /> Challenges
                </li>
                <li
                  className={`${props.selectedTab === EVENTS ? "tabActive" : ""}`}
                  onClick={() => {
                    props.showEvents();
                    setMenuOpen(false);
                  }}
                >
                  <img src={EventsIconBlack} className="menu-icon" /> Events
                </li>
                <li
                  className={`${props.selectedTab === LIBRARY ? "tabActive" : ""}`}
                  onClick={() => {
                    props.showLibrary();
                    setMenuOpen(false);
                  }}
                >
                  <img src={LibraryIconBlack} className="menu-icon" /> Wellness Library
                </li>
                <li
                  className={`${props.selectedTab === ANALYTICS ? "tabActive" : ""}`}
                  onClick={() => {
                    props.showAnalytics();
                    setMenuOpen(false);
                  }}
                >
                  <GoGraph className="menu-icon" /> My Analytics
                </li>
                <li
                  className={`${props.selectedTab === MY_BOOKINGS ? "tabActive" : ""}`}
                  onClick={() => {
                    props.showMyBookings();
                    setMenuOpen(false);
                  }}
                >
                  <img src={BookingsIconBlack} className="menu-icon" /> My bookings
                </li>
                <li
                  className={`${props.selectedTab === ASSESSMENTS ? "tabActive" : ""}`}
                  onClick={() => {
                    props.showAssessments();
                    setMenuOpen(false);
                  }}
                >
                  <img src={AssessmentIconBlack} className="menu-icon" /> Assessments
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderDashboard;
