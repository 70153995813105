import styles from "./RedeemCoins.module.css";
import coinsBag from "assets/svg_icons/coins-bag.svg";
import coin from "assets/svg_icons/Coin.svg";
import { useEffect, useState } from "react";
import { getAllAvatars, redeemCoins } from "actions/dataLoad";
import { useUserInfoSelector, useUserTokenSelector } from "store/reducers";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading, setUserCoins } from "store/actions";
import Lottie from "lottie-react";
import coinsbag from "../../assets/JsonAnimations/coinsbag.json";

const RedeemCoins = ({ showError, showMessage }) => {
  const token = useUserTokenSelector();
  const userInfo = useUserInfoSelector();
  const { coinsBalance } = userInfo;
  const [availableClaims, setAllAvailableClaims] = useState([]);
  const dispatch = useDispatch();

  const fetchAllAvailableClaims = async () => {
    try {
      dispatch(startLoading());
      const response = await getAllAvatars(token);
      setAllAvailableClaims(response.data.data);
      dispatch(setUserCoins(response.data.coinsBalance));
    } catch (error) {
      showError(error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
      console.error(error);
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleClaimClick = async (itemId, type) => {
    try {
      dispatch(startLoading());
      const response = await redeemCoins(token, itemId, type);
      showMessage(response.data.message);
      fetchAllAvailableClaims();
    } catch (error) {
        showError(error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
      console.error(error);
    } finally {
      dispatch(stopLoading());
    }
  };

  useEffect(() => {
    fetchAllAvailableClaims();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.contentTop}>
          <div className={styles.contentTopLeft}>
            <div className={styles.contentTopLeftDiv}>
              <img src={coin} />
              <div>{coinsBalance}</div>
            </div>
            <div className={styles.contentTopLeftText}>Happiness Coins</div>
          </div>
          <Lottie
          animationData={coinsbag}
          className={styles.contentTopImage}
          loop={true}
          />
        </div>
        <div className={styles.contentMain}>
          <div className={styles.contentMainHeading}>Redeem Points</div>
          <div className={styles.contentMainContainer}>
            {availableClaims.map((item, index) => (
              <div className={styles.contentMainContainerItem}>
                <div className={styles.contentMainContainerItemLeft}>
                  <img src={item.cdn_url} />
                  <div>
                    <div>{item.name}</div>
                    <div>{item.coin_cost}</div>
                  </div>
                </div>
                <button onClick={() => handleClaimClick(item.id, item.type)}>Claim</button>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.contentBottom}>
          <div className={styles.contentBottomLeft}>
            <div>Refer and Earn Points</div>
            <div>Spread the word and help your friends & family priotize their wellness!</div>
          </div>
          <button>Refer</button>
        </div>
      </div>
    </div>
  );
};

export default RedeemCoins;
