import { useState, useEffect, useRef } from "react";
import HeaderHomeScreen from "../../components/HeaderHomeScreen";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { useUserTokenSelector } from "store/reducers";
import { Link, useLocation } from "react-router-dom";
import "./index.css";
import { sendForgotOTPToUser, updateB2BPassword } from "actions/auth";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "store/actions";
import CryptoJS from "crypto-js";
import Lottie from "lottie-react";
import blissWelcomingFinalWeb from "../../assets/JsonAnimations/blissWelcomingFinalWeb.json";
import blissClosingEyesFinalWeb from "../../assets/JsonAnimations/blissClosingEyesFinalWeb.json";

const ForgotPassword = ({ history }) => {
  const location = useLocation();
  const otpVerified = location.state?.otpVerified || false;
  const otp = location.state?.otp || "";
  const [loggedOutMessage, setLoggedOutMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(location.state?.email || "");
  const [numberAlertPopup, setNumberAlertPopup] = useState("");
  const [showPasswordSetup, setShowPasswordSetup] = useState(otpVerified);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isNewPasswordFocused, setIsNewPasswordFocused] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState(false);
  const userToken = useUserTokenSelector();
  const dispatch = useDispatch();

  // Refs for the inputs
  const emailRef = useRef(null);
  const newPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
    setLoggedOutMessage("");
  };

  const handleSendOTP = async () => {
    try {
      dispatch(startLoading());
      const response = await sendForgotOTPToUser({
        email,
        purpose: 0,
        captcha: ""
      });
      if (response.data.otpSent) {
        dispatch(stopLoading());
        setShowPasswordSetup(true);
        history.push({
          pathname: '/otpVerification',
          state: { email: email }
        });
      } else {
        setShowPasswordSetup(false);
        setSeverity("error");
        setLoggedOutMessage(response.data.error || "Please try sending OTP after sometime");
        setOpen(true);
        dispatch(stopLoading());
      }
    } catch (error) {
      setSeverity("error");
      setLoggedOutMessage(error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
      setOpen(true);
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleNewPasswordChange = async () => {
    try {
      if (newPassword === confirmPassword) {
        dispatch(startLoading());
        const passwordCipher = CryptoJS.AES.encrypt(
          JSON.stringify(newPassword),
          process.env.REACT_APP_PASSWORD_ENCRYPT_KEY,
        ).toString();

        const confirmPasswordCipher = CryptoJS.AES.encrypt(
          JSON.stringify(confirmPassword),
          process.env.REACT_APP_PASSWORD_ENCRYPT_KEY,
        ).toString();

        const response = await updateB2BPassword({
          email,
          otp,
          password: passwordCipher,
          confirmPassword: confirmPasswordCipher,
          captcha: ""
        });

        if (response.data.status === "success") {
            dispatch(stopLoading());
            setSeverity("success");
            setLoggedOutMessage("Password changed successfully");
            setOpen(true);
            history.replace({
              pathname: "/login"
            });
        } else {
          dispatch(stopLoading())
          setSeverity("error");
          setLoggedOutMessage("Password's doesn't match");
          setOpen(true);
        }
      }
    } catch (error) {
      setSeverity("error");
      setLoggedOutMessage(error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
      setOpen(true);
      console.error("error", error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (!showPasswordSetup && email) {
        handleSendOTP();
      } else {
        // Move focus to the next input field
        if (newPassword && !confirmPassword) {
          confirmPasswordRef.current?.focus();
        } else if (confirmPassword) {
          handleNewPasswordChange();
        }
      }
    }
  };

  return (
    <div>
      <HeaderHomeScreen />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {loggedOutMessage}
        </Alert>
      </Snackbar>

      <div className="forgot-screen-main-container" onKeyDown={handleKeyDown}>
        {isMobile ? (
          <div style={{backgroundColor:'#e8fdf2',display:'flex',flexDirection:'column',alignItems:'center',flex:1}}>
          <div className="mobileScreen-forgot-box-Image">
          <h3>Verify Your E-mail</h3>
            <Lottie
              key={isNewPasswordFocused || isConfirmPasswordFocused  ? "closing" : "welcoming"}
              animationData={isNewPasswordFocused || isConfirmPasswordFocused ? blissClosingEyesFinalWeb : blissWelcomingFinalWeb}
              style={{ height: "300px", width: "300px", marginTop:'-50px' }}
              loop={false}
            />
          </div>
          {!showPasswordSetup ? (
          // Email Input UI
          <div className="mobileScreen-forgot-box">
            <div className="mobileScreen-forgot-form-inner-stuff-lcm" style={{ marginTop: '0px',width:'90%' }}>
              <input
                ref={emailRef}
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {numberAlertPopup && (
                <p className="alert-field-fisl">{numberAlertPopup}</p>
              )}
            </div>
            <div className="btn-forgotmain" style={{width:'90%'}}>
              <button onClick={handleSendOTP}>Send OTP</button>
            </div>
            <p className="forgot-txt">
              Don't have an account yet?{" "}
              <Link to="/signup">
                Sign Up
                <img />
              </Link>
            </p>
          </div>
        ) : (
          // Password Setup UI
          <div className="mobileScreen-forgot-box">
          <div className="mobileScreen-forgot-form-inner-stuff-lcm" style={{ marginTop: "0px", position: "relative", width:'90%'}}>
            <input
              placeholder="Enter new password"
              type={showNewPassword ? "text" : "password"}
              style={{ paddingRight: "40px" }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              onFocus={() => setIsNewPasswordFocused(true)}
              onBlur={() => setIsNewPasswordFocused(false)}
            />
            <img
              src={showNewPassword 
                ? "https://ucarecdn.com/0d2f75a6-0797-440c-abf9-8912f9ac8f0b/eye.svg" 
                : "https://ucarecdn.com/fcb57aff-9ce6-4ce7-babc-b7d740922a2c/eyeslash.svg"}
              alt="Toggle Password Visibility"
              onClick={() => setShowNewPassword((prev) => !prev)}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                width: "24px",
                height: "24px",
              }}
            />
          </div>

          <div className="mobileScreen-forgot-form-inner-stuff-lcm" style={{ position: "relative", width:'90%' }}>
            <input
              placeholder="Confirm new password"
              type={showConfirmPassword ? "text" : "password"}
              style={{ paddingRight: "40px" }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onFocus={() => setIsConfirmPasswordFocused(true)}
              onBlur={() => setIsConfirmPasswordFocused(false)}
            />
            <img
              src={showConfirmPassword 
                ? "https://ucarecdn.com/0d2f75a6-0797-440c-abf9-8912f9ac8f0b/eye.svg" 
                : "https://ucarecdn.com/fcb57aff-9ce6-4ce7-babc-b7d740922a2c/eyeslash.svg"}
              alt="Toggle Confirm Password Visibility"
              onClick={() => setShowConfirmPassword((prev) => !prev)}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                width: "24px",
                height: "24px",
              }}
            />
          </div>
            <div className="btn-forgotmain" style={{marginTop:'40px',width:'90%'}} onClick={handleNewPasswordChange}>
              <button>Confirm</button>
            </div>
          </div>
        )}
          </div>
        ):(
        <>
        {!showPasswordSetup ? (
          // Email Input UI
          <div className="forgot-box">
            <p className="forgot-box-heading">Enter your Email</p>
            <div className="forgot-form-inner-stuff-lcm" style={{ marginTop: '40px' }}>
              <input
                ref={emailRef}
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {numberAlertPopup && (
                <p className="alert-field-fisl">{numberAlertPopup}</p>
              )}
            </div>
            <div className="btn-forgotmain">
              <button onClick={handleSendOTP}>Send OTP</button>
            </div>
            <p className="forgot-txt">
              Don't have an account yet?{" "}
              <Link to="/signup">
                Sign Up
                <img />
              </Link>
            </p>
          </div>
        ) : (
          // Password Setup UI
          <div className="forgot-box">
            <p className="forgot-box-heading">Setup New Password</p>
            {/* New Password Input */}
          <div className="forgot-form-inner-stuff-lcm" style={{ marginTop: "40px", position: "relative" }}>
            <input
              placeholder="Enter new password"
              type={showNewPassword ? "text" : "password"}
              style={{ paddingRight: "40px" }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              onFocus={() => setIsNewPasswordFocused(true)}
              onBlur={() => setIsNewPasswordFocused(false)}
            />
            {/* Eye Icon for New Password */}
            <img
              src={showNewPassword 
                ? "https://ucarecdn.com/0d2f75a6-0797-440c-abf9-8912f9ac8f0b/eye.svg" 
                : "https://ucarecdn.com/fcb57aff-9ce6-4ce7-babc-b7d740922a2c/eyeslash.svg"}
              alt="Toggle Password Visibility"
              onClick={() => setShowNewPassword((prev) => !prev)}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                width: "24px",
                height: "24px",
              }}
            />
          </div>

          {/* Confirm Password Input */}
          <div className="forgot-form-inner-stuff-lcm" style={{ position: "relative" }}>
            <input
              placeholder="Confirm new password"
              type={showConfirmPassword ? "text" : "password"}
              style={{ paddingRight: "40px" }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onFocus={() => setIsConfirmPasswordFocused(true)}
              onBlur={() => setIsConfirmPasswordFocused(false)}
            />
            {/* Eye Icon for Confirm Password */}
            <img
              src={showConfirmPassword 
                ? "https://ucarecdn.com/0d2f75a6-0797-440c-abf9-8912f9ac8f0b/eye.svg" 
                : "https://ucarecdn.com/fcb57aff-9ce6-4ce7-babc-b7d740922a2c/eyeslash.svg"}
              alt="Toggle Confirm Password Visibility"
              onClick={() => setShowConfirmPassword((prev) => !prev)}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                width: "24px",
                height: "24px",
              }}
            />
          </div>
            <div className="btn-forgotmain" style={{marginTop:'40px'}} onClick={handleNewPasswordChange}>
              <button>Confirm</button>
            </div>
          </div>
        )}
        <div className="forgot-box-Image">
        <h3>Verify Your E-mail</h3>
          <Lottie
            key={
              isNewPasswordFocused || isConfirmPasswordFocused 
              // || (!showNewPassword && !showConfirmPassword)
                ? "closing"
                : "welcoming"
            }
            animationData={
              isNewPasswordFocused || isConfirmPasswordFocused 
              // || (!showNewPassword && !showConfirmPassword)
                ? blissClosingEyesFinalWeb
                : blissWelcomingFinalWeb
            }
            style={{ height: "300px", width: "300px", marginLeft: "8vh" }}
            loop={false}
          />
        </div>
        </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;