export const getChallenges = async (token) =>
    await axios.get(
        process.env.REACT_APP_SERVER_URI + '/api/challenges',
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
export const getLeaderboard = async(token, challengeId) =>
    await axios.get(
        process.env.REACT_APP_SERVER_URI + '/api/challenge/' + challengeId,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );