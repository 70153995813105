import { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import { Tooltip } from "antd";
import { Link, useLocation } from "react-router-dom";
import "./index.css";
import { fetchUserPlanner, generateUserPlanner, markActivityComplete, regenerateUserPlanner } from "../../actions/dataLoad.js";
import { useHistory } from "react-router-dom";
import WaveIcon from "assets/svg_icons/wave_icon.svg";
import BookCardBg from "assets/svg_icons/book_card_bg.svg";
import MorningBliss from "assets/svg_icons/morning_bliss.svg";
import AfternoonBliss from "assets/svg_icons/afternoon_bliss.svg";
import EveningBliss from "assets/svg_icons/evening_bliss.svg";
import CoinsIcon from "assets/svg_icons/coins.svg";

import { createTheme } from "@material-ui/core/styles";
import { useUserInfoSelector, useUserTokenSelector } from "store/reducers.js";
import { useDispatch } from "react-redux";
import {
  setUserCoins,
  setWellnessLibraryCategories,
  setWellnessLibraryCategoriesLoading,
  setWellnessLibraryCategoryData,
  setWellnessLibraryCategoryLoading,
  setWellnessLibraryData,
  setWellnessLibrarySelectedCategory
} from "store/actions.js";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { TbRefresh } from "react-icons/tb";
import { FaCheck } from "react-icons/fa6";
import { getWellnessCategories, getWellnessSelectedCategoryVideos } from "actions/wellnesslibrary.js";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

// const { Step } = Steps
const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: "#3D41D7",
      },
      track: {
        color: "white",
      },
      rail: {
        color: "black",
      },
      mark: {
        color: "white",
      },
      root: {
        height: "100px",
      },
    },
  },
});

const tea = "https://ucarecdn.com/d0001aa1-888d-48c8-9e7b-aef03de1a389/cuptea.png";

const plannerSkeletonData = [
  {
    title: "Morning",
    data: [{}, {}],
  },
  {
    title: "Afternoon",
    data: [{}, {}],
  },
  {
    title: "Evening",
    data: [{}, {}],
  },
];

let userPlannerOutRef = null;

export const UserOverview = ({ showError, showMessage, setShowStreakPopup, setShowCoinsPopup }) => {
  const [userPlanner, setUserPlanner] = useState(null);
  const userPlannerRef = useRef(userPlannerOutRef);
  const [todayPlannerActivties, setTodayPlannerActivities] = useState([]);
  const [isPlannerLoading, setIsPlannerLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const userToken = useUserTokenSelector();
  const userInfo = useUserInfoSelector();
  const dispatch = useDispatch();
  const currentTourStep = userInfo.appTourStep ?? 1;
  const isFirstLogin = currentTourStep >= 1 && currentTourStep <= 3;
  const { redirectFrom } = location.state || {};

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
  };

  const generateNewPlanner = async (userId, token) => {
    try {
      setIsPlannerLoading(true);
      const response = await generateUserPlanner(token, userId, isFirstLogin);
      const { planner, status, error, answersSubmitted } = response.data;
      if (status !== "success") {
        throw new Error(error);
      }
      if (answersSubmitted === "false") {
        history.push("/onboardingQuestions");
      }
      if (planner) {
        setUserPlanner(planner);
      }
    } catch (error) {
      console.error("Failed to create planner: ", error.message);
    } finally {
      setIsPlannerLoading(false);
    }
  };
  
  const getUserPlanner = async (userId, token) => {
    try {
      setIsPlannerLoading(true);
      const response = await fetchUserPlanner(userId, token);
      const { status, planner, error, answersSubmitted } = response.data;
      if (status !== "success") {
        throw new Error(error);
      }
      if (answersSubmitted === "false") {
        history.push("/onboardingQuestions");
        return;
      }
      if (planner) {
        setUserPlanner(planner);
      } else if (isFirstLogin) {
        generateNewPlanner(userId, token, isFirstLogin);
      }
    } catch (error) {
      showError(error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
      console.error(error);
    } finally {
      setIsPlannerLoading(false);
    }
  };

  const regeneratePlanner = async (userId, token) => {
    try {
      setIsPlannerLoading(true);
      const response = await regenerateUserPlanner(userId, token);
      const { planner, status, error, coinsBalance, isDayCompleted } = response.data;
      if (status !== "success") {
        throw new Error(error);
      }
      if (isDayCompleted) {
        showError("Please come back tomorrow for new activities");
      }
      if (planner) {
        setUserPlanner(planner);
      }
    } catch (error) {
      showError(error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
      console.error(error);
    } finally {
      setIsPlannerLoading(false);
    }
  };

  const fetchWellnessLibrarySelectedCateogry = async (category, token) => {
    try {
      dispatch(setWellnessLibraryCategoryLoading({ selectedCategory: category, value: true }));

      const response = await getWellnessSelectedCategoryVideos(userToken, category);
      dispatch(setWellnessLibraryCategoryData({ selectedCategory: category, value: response.data.data || [] }));
    } catch (error) {
      console.log({ error });
    } finally {
      dispatch(setWellnessLibraryCategoryLoading({ selectedCategory: category, value: false }));
    }
  };

  const preloadWellnessLibraryCategories = async (categories, token) => {
    try {
      const libraryData = {};
      for (const category of categories) {
        libraryData[category.category] = null;
      }
      dispatch(setWellnessLibraryData(libraryData));
      for (const category of categories.slice(0, 4)) {
        fetchWellnessLibrarySelectedCateogry(category.category, token);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const fetchWellnessLibraryContent = async (token) => {
    try {
      dispatch(setWellnessLibraryCategoriesLoading(true));

      // Fetch categories and set state
      const res = await getWellnessCategories(userToken);
      const categories = res.data?.categoryList || [];
      let category = "";
      category = res.data?.categoryList?.[0]?.category;
      dispatch(setWellnessLibraryCategories(categories));
      dispatch(setWellnessLibrarySelectedCategory(category));
      preloadWellnessLibraryCategories(categories, token);
    } catch (error) {
      console.error("Error fetching categories or content data:", error);
    } finally {
      dispatch(setWellnessLibraryCategoriesLoading(false));
    }
  };

  useEffect(() => {
    if (userPlanner?.planner?.[0]) {
      const groupedActivites = [
        ["Morning", MorningBliss, "#C5CFFF"],
        ["Afternoon", AfternoonBliss, "#FFE9AA"],
        ["Evening", EveningBliss, "#C4EED9"],
      ].map((period) => ({ title: period[0], icon: period[1], background: period[2], data: [] }));
      for (const activity of userPlanner.planner[0].activities) {
        groupedActivites
          .find((val) => val.title === (activity.dayPeriod === "Any" ? "Evening" : activity.dayPeriod))
          ?.data.push(activity);
      }
      setTodayPlannerActivities(groupedActivites.filter((section) => section.data.length > 0));
      
      // show streak & coins modal if current day's 1st activity got completed
      const isOldPlannerAllPending = userPlannerRef.current?.planner[0].activities.every(activity => activity.status === "");
      const newPlannerFirstDone = userPlanner.planner[0].activities.find(activity => activity.status === "done");
      if (isOldPlannerAllPending && newPlannerFirstDone) {
          setShowStreakPopup(true);
          setTimeout(() => {
            setShowStreakPopup(false);
            setShowCoinsPopup(true);
            setTimeout(() => {
              setShowCoinsPopup(false);
            }, 3000);
          }, 3000);
        }
        userPlannerRef.current = userPlanner;
      }
  }, [userPlanner]);

  const completeActivity = async (activity) => {
    try {
      const response = await markActivityComplete(userToken, userInfo.id, activity._id);
      const { status, error, coinsBalance } = response.data;
      if (status !== "success") {
        throw new Error(error);
      }

      if (coinsBalance) {
        dispatch(setUserCoins(coinsBalance));
      }

      getUserPlanner(userInfo.id, userToken);
    } catch (error) {
      console.error("Failed to create planner: ", error.message);
    }
  };

  const handleActivityClick = async (activity) => {
    if (["blog", "redirect"].includes(activity.resourceType)) {
      if (activity.resourceType === "redirect" && !activity.status) {
        completeActivity(activity);
      }
      window.open(activity.data?.url, "_blank");
    } else if (activity.resourceType === "therapy") {
      history.push("/dashboard/sessionBooking");
    } else if (activity.resourceType === "video") {
      history.push(`/dashboard/library/video`, {
        url: activity?.data?.url,
        duration: activity.data.duration,
        activityId: activity._id,
      });
    } else if (activity.resourceType === "health") {
      history.push("/dashboard/analytics");
    } else if (activity.resourceType === "assessment") {
      history.push(`/dashboard/assessments/${activity.data.urlSlug}`, { activityId: activity._id });
    }
  };

  useEffect(() => {
    if (redirectFrom === "signup") {
      generateNewPlanner(userInfo.id, userToken);
    } else {
      getUserPlanner(userInfo.id, userToken);
    }
    fetchWellnessLibraryContent(userToken);

    return () => {
      userPlannerOutRef = userPlannerRef.current;
    }
  }, []);

  const healthUnits = {
    Steps: "steps",
    Calories: "calories",
    Sleep: "mins",
  };

  const handleWaveIconClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = (e) => {
    // Prevent closing when clicking inside the modal
    if (e.target.id === "modalBackdrop") {
      setIsModalOpen(false);
    }
  };

  return (
    <div className="dashboardofboxes">
      <p className="dashboard-heading">
        Hey {userInfo.fullName} <img src={WaveIcon} onClick={handleWaveIconClick} />
      </p>
      <div className="containers-dashboardboxed dashboardbooking">
        <div className="top-containerbooking">
          <Link to="/dashboard/sessionBooking" className="booknow-card">
            <p className="firstheading">
              Begin your <span>healing journey</span>{" "}
            </p>
            <p className="bottomheading">
              Completely confidential &nbsp;
              <Tooltip
                placement="bottomLeft"
                title="Your Data is secure and confidential with Goodlives."
                color="#e8e8e8"
                overlayInnerStyle={{
                  borderRadius: "10px",
                  fontSize: "10px",
                  color: "#000",
                  fontWeight: "bold",
                  maxWidth: "250px",
                }}
              >
                <IoMdInformationCircleOutline size={20} />
              </Tooltip>
            </p>
            <img src={BookCardBg} className="book-img" />
            <div className="book-btn-cont">
              <div className="book-btn">
                <p>Find my therapist</p>
              </div>
            </div>
          </Link>
        </div>
        <div className="bottom-containerbooking">
          <div className="bottom-containerbooking-header"
          // style={{width:'48%',justifyContent:'space-between'}}
          >
            <div className="bottom-containerbooking-header-txt">Today's Plan</div>
            <div>
              <button onClick={() => regeneratePlanner(userInfo.id, userToken)}>
                <TbRefresh size={24} />
              </button>
              <Tooltip
                placement="bottomLeft"
                title="This will regenerate your daily routine and deduct some coins."
                color="#e8e8e8"
                overlayInnerStyle={{
                  borderRadius: "10px",
                  fontSize: "10px",
                  color: "#000",
                  fontWeight: "bold",
                  maxWidth: "250px",
                }}
              >
                <IoMdInformationCircleOutline size={14} />
              </Tooltip>
            </div>
          </div>
          <div className="bottom-containerbooking-content">
            {isPlannerLoading &&
              plannerSkeletonData.map((group) => {
                return (
                  <SkeletonTheme>
                    <div className="planner-section skeleton">
                      <p>
                        <Skeleton height={20} />{" "}
                      </p>
                      <div className="planner-section-cards">
                        {group.data.map((item) => (
                          <button className="planner-section-card">
                            <Skeleton />
                          </button>
                        ))}
                      </div>
                    </div>
                  </SkeletonTheme>
                );
              })}
            {!isPlannerLoading &&
              todayPlannerActivties.map((group) => {
                return (
                  <div className="planner-section">
                    <p>{group.title}</p>
                    <div className="planner-section-cards">
                      {group.data.map((item) => {
                        return (
                          <button
                            className="planner-section-card"
                            style={{ backgroundColor: group.background }}
                            onClick={() => handleActivityClick(item)}
                          >
                            <div className="planner-section-card-check">
                              {item.status === "done" && <FaCheck size={20} fill="#49AF7C" />}
                            </div>
                            <div className="planner-section-card-content">
                              <div>
                                <h3>{item.name}</h3>
                                <p>{item.resourceType === "blog" ? item.data?.name : item.data?.textContent}</p>
                              </div>
                              <div className="planner-section-card-content-bottom">
                                <span>
                                  {item.resourceType === "health"
                                    ? `${item.activityTime} ${healthUnits[item.name]}`
                                    : `${item.activityTime} mins`}
                                </span>
                                <div>
                                  <img src={CoinsIcon} />
                                  {item.coins}
                                </div>
                              </div>
                            </div>
                            <img src={group.icon} />
                          </button>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserOverview;