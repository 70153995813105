import { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import Lottie from "lottie-react";
import fire from "../../assets/JsonAnimations/fire.json";
import tick from "../../assets/JsonAnimations/tick.json";
import "./StreakModal.css";

export const StreakModal = ({ isOpenStreak, onCloseStreak,streakCount = 0 }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onCloseStreak();
      }
    };

    if (isOpenStreak) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenStreak, onCloseStreak]);

  if (!isOpenStreak) return <></>;

  const allDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const todayIndex = new Date().getDay() - 1;
  const adjustedTodayIndex = todayIndex < 0 ? 6 : todayIndex;

  const getHighlightedDays = () => {
    let highlightedDays = [];
    for (let i = 0; i < streakCount; i++) {
      let index = (adjustedTodayIndex - i + 7) % 7;
      highlightedDays.unshift(index);
    }
    return highlightedDays;
  };

  const highlightedDays = getHighlightedDays();

  return (
    <div className="streak-modal-overlay" onClick={onCloseStreak}>
      <div className="streak-modal-content" style={{ width: "430px" }} ref={modalRef}>
        <Lottie
          animationData={fire}
          style={{ height: "100px", width: "100px", alignSelf: "center" }}
        />
        <p className="streakCount">{streakCount}</p>
        <p className="steakTxt">Day Streak!</p>
        <div className="DaysBox">
          <div style={{ flexDirection: "row", display: "flex" }}>
            {allDays.map(
              (day, index) => (
                <div key={index} className="day-item">
                  <p className={`day-label ${highlightedDays.includes(index) ? "active-day" : ""}`}>
                  {day}
                </p>
                {highlightedDays.includes(index) ? (
                    <Lottie
                      animationData={tick}
                      className="lottie-icon"
                      loop={false}
                    />
                  ) : (
                    <div className="circle"></div>
                  )}
                </div>
              )
            )}
          </div>
          <div
            style={{
              width: "90%",
              height: "1px",
              backgroundColor: "#ccc",
              marginTop: "10px",
              alignSelf: "center",
            }}
          />
          <p className="steakdec">
            Steak will reset if you don't
            <br />
            practice tomorrow.
          </p>
        </div>
      </div>
    </div>
  );
};

export default StreakModal;
