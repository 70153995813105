// import notFound from "../../assets/images/notFound.gif"
import "./PageNotFound.css";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import HeaderHomeScreen from "../../components/HeaderHomeScreen";
import HeaderBooking from "../../components/HeaderBooking";
import HeaderDashboard from "../../components/HeaderDashboard";
import TopNavbar from "../../components/TopNavbar";
import { logout } from "../../actions/auth";
import { logoutUser } from "store/actions";
import { useUserInfoSelector, useUserTokenSelector } from "store/reducers";

const PageNotFound = ({ history }) => {
  const userToken = useUserTokenSelector();
  const { categoryId } = useUserInfoSelector();
  const dispatch = useDispatch();

  const getNavBar = () => {
    if (userToken) {
      switch (categoryId) {
        case 0:
          return <HeaderBooking handleLogout={handleLogout} />;
        case 1:
          return <HeaderDashboard handleLogout={handleLogout} />;
        case 2:
          return <HeaderDashboard handleLogout={handleLogout} />;
        case 3:
          return <TopNavbar handleLogout={handleLogout} />;
        case 4:
          return <HeaderDashboard handleLogout={handleLogout} />;
      }
    } else {
      return <HeaderHomeScreen />;
    }
  };

  const getClassName = () => {
    if (userToken) {
      switch (categoryId) {
        case 0:
          return "addMargin50";
        case 1:
          return "addMargin120";
        case 2:
          return "addMargin120";
        case 3:
          return "addMargin120";
        case 4:
          return "addMargin120";
      }
    } else {
      return "addMargin120";
    }
  };

  const handleLogout = () => {
    logout(userToken);

    logoutUser(dispatch);

    window.localStorage.removeItem("auth");
    history.push({
      pathname: "/signin",
      state: {
        loggedOutMessage: "You have been logged out. Please login again.",
      },
    });
  };

  return (
    <div className="pnf-container">
      {getNavBar()}
      <Container className={getClassName()}>
        <Row>
          <Col sm={12} md={7}>
            <img
              src="https://ucarecdn.com/e3256b3b-2436-4f8a-b189-90a7b9e6f913/notFound.gif"
              atl="404error"
              className="pnf-image"
            />
          </Col>
          <Col sm={12} md={5} style={{ marginBottom: "50px" }}>
            <h1 className="pnf-heading">
              Sometimes, we feel lost too. And that's okay!
            </h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PageNotFound;
