import axios from "axios"

export const fetchTherapists = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/therapist/matchTherapist",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        withCredentials: true,
      },
    }
  )

export const previousBooking = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/therapist/previousBooking",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

export const therapistDashboardInfo = async (token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/therapist/therapistDashboardInfo",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
