import { useState, useEffect, useLayoutEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { Modal, Box, Typography, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { addGender, checkGender, getUserProfile, refreshToken } from "../actions/auth";
import { checkTeamLocation, logout, updateTeamLocation } from "../actions/auth";
import { logoutUser, setAvailableFeatures, setUserInfo, setUserToken, startLoading, stopLoading } from "store/actions";
import { useLoadingSelector, useUserTokenSelector } from "store/reducers";
import { checkLastSessionFeedback, postFeedback } from "actions/bookSlot";

import LeftMenuB2B from "./LeftMenuB2B";
import UserOverview from "../Containers/UserOverview/index";
import HeaderDashboard from "./HeaderDashboard";
import Support from "Containers/Support";
import SessionFeedbackPopup from "./SessionFeedbackPopup/SessionFeedbackPopup";
import TeamLocationPopup from "./TeamLocationPopup/TeamLocationPopup";
import MoodTrackerScreen from "Containers/MoodTrackerScreen";
import UserWallet from "Containers/UserWallet";
import UserBookings from "Containers/UserBookings";
import "./index.css";
import MyProfilePopup from "Containers/MyProfile/MyProfile";
import WellnessLibrary from "../Containers/WellnessLibrary/WellnessLibrary";
import { useUserInfoSelector } from "store/reducers";
import Events from "Containers/Events/Events";
import MyAnalytics from "Containers/My Analytics/MyAnalytics";
import Challenges from "Containers/Challenges/Challenges";
import RedeemCoins from "./RedeemCoins/RedeemCoins";
import { loadAllFeatures } from "actions/features";
import UserAssessments from "Containers/UserAssessments/UserAssessments";
import BookingSelection from "Containers/Booking Selection";
import AssessmentQuiz from "Containers/Assessment Quiz";
import StreakModal from "./StreakModal/StreakModal";
import HappinessCoinsModal from "./HappinessCoinsModal/HappinessCoinsModal";
import { fetchUserNotifications } from "actions/dataLoad";

export const OVERVIEW = "OVERVIEW";
export const MOOD_TRACKER = "MOOD_TRACKER";
export const MY_BOOKINGS = "MY_BOOKINGS";
export const LIBRARY = "LIBRARY";
export const EVENTS = "EVENTS";
export const MY_PROFILE = "MY_PROFILE";
export const ANALYTICS = "ANALYTICS";
export const CHALLENGES = "CHALLENGES";
export const SUPPORT = "SUPPORT";
export const WALLET = "WALLET";
export const REDEEM_COINS = "REDEEM_COINS";
export const ASSESSMENTS = "ASSESSMENTS";
export const ASSESSMENT_QUIZ = "ASSESSMENT_QUIZ";
export const THERAPY_BOOKING = "THERAPY_BOOKING";

export const UserDashboard = (props) => {
  const userInfo = useUserInfoSelector();
  const location = useLocation();
  const { redirectFrom } = location.state || {};
  const [selectedTab, setSelectedTab] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showProfilePopup, setShowProfilePopup] = useState({ show: false, edit: false });
  const [googleAuth, setGoogleAuth] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [imagePopup, setImagePopup] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [referralPopup, setReferralPopup] = useState("");
  const [userId, setSignedupId] = useState("");
  const [sessionFeedbackPopupState, setSessionFeedbackPopupState] = useState({ show: false, data: {} });
  const [teamLocationPopupState, setTeamLocationPopupState] = useState({ show: false, teamLocations: [] });
  const [successMessage, setSuccessMessage] = useState("");
  const [showStreakPopup, setShowStreakPopup] = useState(false);
  const [showCoinsPopup, setShowCoinsPopup] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const userToken = useUserTokenSelector();
  const isLoading = useLoadingSelector();

  useEffect(() => {
    const signup = new URLSearchParams(location.search).get("googleAuth");
    const userid = new URLSearchParams(location.search).get("userId");
    if (signup == null) {
      setGoogleAuth(false);
    } else {
      showModal(userid);
    }
    checkTeamLocation(userToken)
      .then((res) => {
        // show team location popup to user if user hasn't submitted data yet
        if (!res.data.isSubmitted) {
          const { teamLocations } = res.data;
          setTeamLocationPopupState({ show: true, teamLocations: teamLocations });
        }
      })
      .catch((e) => {});
    checkLastSessionFeedback(userToken)
      .then((res) => {
        // show feedback popup to user if user hasn't rated last completed session already
        if (!res.data.isFeedbackGiven) {
          const { therapistName, therapistId, sessionId } = res.data;
          setSessionFeedbackPopupState({ show: true, data: { therapistName, therapistId, sessionId } });
        }
      })
      .catch((e) => {});
    fetchUserNotifications(userToken)
      .then((res) => {
        setNotifications(res.data.notifications || []);
      })
      .catch((e) => {});
  }, []);

  const showModal = async (userid) => {
    try {
      const res = await checkGender(userid);
      if (res.data.AvatarNotSelected == true) {
        setModalOpen(true);
        setSignedupId(userid);
        setGoogleAuth(true);
      } else {
        setModalOpen(false);
        setSignedupId("");
        setGoogleAuth(false);
      }
    } catch (error) {
      setModalOpen(false);
      setSignedupId("");
      setGoogleAuth(false);
      showError(error.response.data.error);
    }
  };

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  const showError = (text) => {
    setError(text);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
  };

  useEffect(() => {
    if (props.routeProps && selectedTab !== "") {
      const { activeTab } = props.routeProps;
      setSelectedTab(activeTab || OVERVIEW);
    }
  }, [props.routeProps]);

  const handleLogout = () => {
    logout(userToken);

    logoutUser(dispatch);

    window.localStorage.removeItem("auth");

    history.push({
      pathname: "/signin",
      state: {
        loggedOutMessage: "You have been logged out. Please login again.",
      },
    });
  };
  const handleModalSubmit = async () => {
    if (selectedImage.length === 0) {
      setImagePopup("Please choose an Avatar");
    } else {
      setImagePopup("");
    }
    if (referralCode.length > 0) {
      if (referralCode.length != 5) {
        setReferralPopup("Enter a Valid Code");
      }
    }
    if (selectedImage !== "") {
      if (referralCode.length > 0) {
        if (referralCode.length != 5) {
          return;
        }
      }
      /*if (referralCode.length === 5) {
        try {
          const res = await checkReferral({ referralCode });
          const found = res.data.found;
          if (!found) {
            setReferralPopup("Referral Code Not Found");
            return;
          }
        } catch (e) {
          setReferralPopup(e.response.data.error);
        }
      }*/
      //Update the Gender and referral Code
      let avatar = "man";
      if (selectedImage === 1) avatar = "woman";
      if (selectedImage === 2) avatar = "man";
      if (selectedImage === 3) avatar = "other";

      try {
        const res1 = await addGender(
          {
            avatar: avatar,
            referralCode: referralCode,
          },
          userToken
        );

        // if (referralCode.length === 5) {
        //   const res2 = await addUserId({ referralCode, userId: userId, email: a  });
        // }

        setModalOpen(false);
      } catch (error) {
        setReferralPopup(error.response.data.error);
      }
    }
  };

  const handleSessionFeedbackSubmit = async ({ rating, sessionId, therapistId, message, comments }) => {
    try {
      await postFeedback({ rating, bookingID: sessionId, therapistID: therapistId, message, comments }, userToken);
      setSessionFeedbackPopupState({ show: false, data: {} });
    } catch (err) {
      showError("An error occurred!");
    }
  };

  const onTeamLocationSubmit = async (teamName, teamLocation) => {
    try {
      await updateTeamLocation({ teamName, teamLocation }, userToken);
      setTeamLocationPopupState({ show: false, data: [] });
    } catch (err) {
      showError("An error occurred!");
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    maxWidth: "90%",
  };

  const fetchUserProfile = async () => {
    try {
      dispatch(startLoading());
      const res = await getUserProfile(userToken);
      const { user: userInfo } = res.data;
      userInfo.isDemoUser = false;
      if (userInfo.email.substring(userInfo.email.indexOf("@") + 1) === "goodlives.demo") {
        userInfo.isDemoUser = true;
      }

      dispatch(setUserInfo(userInfo));
    } catch (error) {
      showError(
        error.response?.data?.message || error.response?.data?.error || "Couldn't fetch user profile. Please refresh the tab."
      );
    } finally {
      dispatch(stopLoading());
      const { activeTab } = props.routeProps || {};
      setSelectedTab(activeTab || OVERVIEW);
    }
  };

  const fullScreenTabs = [THERAPY_BOOKING, ASSESSMENT_QUIZ];

  useLayoutEffect(() => {
    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (userInfo.isProfilePending) {
      setShowProfilePopup({ show: true, edit: true });
    }
  }, [userInfo.isProfilePending]);

  return (
    <div>
      {googleAuth && (
        // popup to ask user's gender after signing up with Google
        <Modal
          onBackdropClick={() => setModalOpen(true)}
          open={modalOpen}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleModalClose();
            }
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{ textAlign: "center" }}>
              <Typography variant={"h5"}>Almost There...</Typography>
            </div>
            <div className="chooseavataricon">
              <p>Select Your Gender*</p>
              <div className="inner-cai">
                <img
                  src="https://ucarecdn.com/5c10944e-91bc-4ee6-a259-23565aac6100/avatar3.png"
                  className={`${selectedImage === 1 && "active"}`}
                  onClick={() => setSelectedImage(1)}
                />
                <img
                  src="https://ucarecdn.com/e6d48801-632e-4d97-b297-bd15292f07ed/avatar2.png"
                  className={`${selectedImage === 2 && "active"}`}
                  onClick={() => setSelectedImage(2)}
                />
                <img
                  src="https://ucarecdn.com/732dfe6d-fdf3-40c5-a066-e8857ce85d23/avatar1.png"
                  className={`${selectedImage === 3 && "active"}`}
                  onClick={() => setSelectedImage(3)}
                />
              </div>
              {imagePopup.length > 0 && (
                <p className="alert-field-fisl" style={{ color: "red" }}>
                  {imagePopup}
                </p>
              )}
            </div>
            <div className="formsinnerfmc">
              <input
                type="text"
                placeholder="Referral Code(Optional)"
                maxLength={5}
                value={referralCode.toUpperCase()}
                onChange={(e) => setReferralCode(e.target.value)}
              />
            </div>
            {referralPopup.length > 0 && <p className="alert-field-fisl">{referralPopup}</p>}
            <div style={{ textAlign: "center" }}>
              <Button
                onClick={() => handleModalSubmit()}
                style={{ marginTop: "10px", textAlign: "center" }}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </div>
          </Box>
        </Modal>
      )}

      {/* Dashboard navbar & tab navigation for mobile   */}
      <HeaderDashboard
        selectedTab={selectedTab}
        showProfileMenu={showProfileMenu}
        setShowProfileMenu={setShowProfileMenu}
        setShowCoinsPopup={setShowCoinsPopup}
        setShowStreakPopup={setShowStreakPopup}
        categoryId={props.categoryId}
        notifications={notifications}
        shouldWalletAppear={props.showWallet}
        eventId={props.eventId}
        handleLogout={handleLogout}
        toggleProfilePopup={() => setShowProfilePopup((val) => ({ show: !val.show, edit: false }))}
        showOverView={() => {
          setSelectedTab(OVERVIEW);
          history.push("/dashboard/overview");
        }}
        showMyBookings={() => {
          setSelectedTab(MY_BOOKINGS);
          history.push("/dashboard/my-bookings");
        }}
        showLibrary={() => {
          setSelectedTab(LIBRARY);
          history.push("/dashboard/library");
        }}
        showEvents={() => {
          setSelectedTab(EVENTS);
          history.push("/dashboard/events");
        }}
        showChallenges={() => {
          setSelectedTab(CHALLENGES);
          history.push("/dashboard/challenges");
        }}
        showAnalytics={() => {
          setSelectedTab(ANALYTICS);
          history.push("/dashboard/analytics");
        }}
        showAssessments={() => {
          setSelectedTab(ASSESSMENTS);
          history.push("/dashboard/assessments");
        }}
      />

      <div className="wholeapp c2capp b2bapp">
        <div className={`right-app-stuff ${fullScreenTabs.includes(selectedTab) ? "fullScreenTab" : ""}`}>
          {(() => {
            switch (selectedTab) {
              case OVERVIEW:
                return (
                  <UserOverview
                    handleLogout={handleLogout}
                    showMessage={(msg) => setSuccessMessage(msg)}
                    showError={showError}
                    setShowStreakPopup={setShowStreakPopup}
                    setShowCoinsPopup={setShowCoinsPopup}
                  />
                );
              case MOOD_TRACKER:
                return <MoodTrackerScreen handleLogout={handleLogout} />;
              case MY_BOOKINGS:
                return (
                  <UserBookings showMessage={(msg) => setSuccessMessage(msg)} showError={showError} handleLogout={handleLogout} />
                );
              case MOOD_TRACKER:
                return <MoodTrackerScreen handleLogout={handleLogout} />;
              case WALLET:
                return props.showWallet ? <UserWallet handleLogout={handleLogout} /> : <></>;
              case LIBRARY:
                return <WellnessLibrary showMessage={(msg) => setSuccessMessage(msg)} showError={showError} />;
              case EVENTS:
                return <Events />;
              case ANALYTICS:
                return <MyAnalytics showMessage={(msg) => setSuccessMessage(msg)} showError={showError} />;
              case CHALLENGES:
                return <Challenges />;
              case SUPPORT:
                return <Support />;
              case REDEEM_COINS:
                return <RedeemCoins showError={showError} showMessage={(msg) => setSuccessMessage(msg)} />;
              case ASSESSMENTS:
                return <UserAssessments showError={showError} />;
              case ASSESSMENT_QUIZ:
                return <AssessmentQuiz />;
              case THERAPY_BOOKING:
                return <BookingSelection />;
              default:
                return <></>;
            }
          })()}
        </div>

        {!fullScreenTabs.includes(selectedTab) && (
          <LeftMenuB2B
            eventId={props.eventId}
            shouldWalletAppear={props.showWallet}
            selectedTab={selectedTab}
            showOverView={() => {
              setShowProfileMenu(false);
              setSelectedTab(OVERVIEW);
              history.push("/dashboard/overview");
            }}
            showMyBookings={() => {
              setShowProfileMenu(false);
              setSelectedTab(MY_BOOKINGS);
              history.push("/dashboard/my-bookings");
            }}
            showLibrary={() => {
              setShowProfileMenu(false);
              setSelectedTab(LIBRARY);
              history.push("/dashboard/library");
            }}
            showChallenges={() => {
              setShowProfileMenu(false);
              setSelectedTab(CHALLENGES);
              history.push("/dashboard/challenges");
            }}
            showAnalytics={() => {
              setShowProfileMenu(false);
              setSelectedTab(ANALYTICS);
              history.push("/dashboard/analytics");
            }}
            showEvents={() => {
              setShowProfileMenu(false);
              setSelectedTab(EVENTS);
              history.push("/dashboard/events");
            }}
            showAssessments={() => {
              setShowProfileMenu(false);
              setSelectedTab(ASSESSMENTS);
              history.push("/dashboard/assessments");
            }}
          />
        )}
      </div>
      <StreakModal
        isOpenStreak={showStreakPopup}
        onCloseStreak={() => setShowStreakPopup(false)}
        streakCount={userInfo.streakCount}
      />
      <HappinessCoinsModal
        isOpenCoins={showCoinsPopup}
        onCloseCoins={() => setShowCoinsPopup(false)}
        coinsCount={userInfo.coinsBalance}
      />
      <SessionFeedbackPopup
        isOpen={!teamLocationPopupState.show && sessionFeedbackPopupState.show}
        onSessionRatingSubmit={handleSessionFeedbackSubmit}
        sessionData={sessionFeedbackPopupState.data}
      />
      <TeamLocationPopup
        isOpen={teamLocationPopupState.show}
        onSubmit={onTeamLocationSubmit}
        teamLocations={teamLocationPopupState.teamLocations}
      />
      <MyProfilePopup
        isOpen={showProfilePopup.show}
        toggleProfilePopup={() => setShowProfilePopup((val) => ({ show: !val.show, edit: false }))}
        showError={showError}
        edit={showProfilePopup.edit}
        showMessage={(msg) => setSuccessMessage(msg)}
      />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage("")}>
        <Alert onClose={() => setSuccessMessage("")} severity="success" sx={{ width: "100%" }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UserDashboard;
