import React, { useEffect, useState } from "react";
// import logo from "../assets/images/headerbookinglogo.png"
// import arrowUpSvg from "../AssetsUpdated/images/arrow-up-navbar.svg";
import { BiMenu } from "react-icons/bi";
import { MdClose } from "react-icons/md";
// import { Link } from "react-router-dom"
import "../ComponentsUpdated/Navbar.css";

function Navbar() {
  const [corporatesDropdown, setCorporatesDropdown] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [onCurrentPage, setCurrentPage] = useState("");

  useEffect(() => {
    if (window.location.href.includes("/pulse-check-in")) {
      setCurrentPage("corporate-page");
    } else if (window.location.href.includes("/employee-engagement")) {
      setCurrentPage("corporate-page");
    } else if (window.location.href.includes("/predict-burnout")) {
      setCurrentPage("corporate-page");
    } else if (window.location.href.includes("/overall-wellness")) {
      setCurrentPage("corporate-page");
    } else if (window.location.href.includes("/about-us")) {
      setCurrentPage("");
    } else {
      setCurrentPage("home");
    }
  });

  useEffect(() => {
    // alert("s")
    window.addEventListener("click", (event) => {
      const box = document.querySelector(".dropdown-corporates");
      let mainDiv = document.querySelector(".open-dropdown-div");

      if (window.innerWidth > 1009) {
        if (mainDiv?.contains(event.target)) {
          // console.log(document.querySelector(".dropdown-corporates").style.opacity, '/sees')
          // if (document.querySelector(".dropdown-corporates").style.opacity == 1) {
          //     setCorporatesDropdown(false)
          // } else {
          if (corporatesDropdown) {
            setCorporatesDropdown(false);
          } else {
            setCorporatesDropdown(true);
          }
          // }
        } else {
          if (!box?.contains(event.target)) {
            setCorporatesDropdown(false);
          }
        }
      }
    });
  }, [corporatesDropdown]);

  return (
    <div className="navbar-main">
      <div className="navbar-container">
        <img
          src="https://ucarecdn.com/4748640f-b537-4b9b-8896-362944eaaae3/headerbookinglogo.png"
          className="image-logo-hb"
          alt="logo"
          onClick={() => {
            window.location.href = "/";
          }}
        />
        <div className="menu-navbar-container">
          <ul>
            <li className={`hoveranimate ${onCurrentPage === "home" && "active"}`}>
              <a href="/">Home</a>
            </li>
            {/* <li className='hoveranimate active'><Link to="/">Home</Link></li> */}
            <li className={onCurrentPage === "corporate-page" && "active"}>
              <font
                className="open-dropdown-div"
                onClick={() => {
                  // setCorporatesDropdown(!corporatesDropdown)
                  //console.log("working");
                }}
              >
                For Corporates{" "}
                <img
                  src="https://ucarecdn.com/4e79ba88-dba3-4818-8db6-31d0c71939b9/arrowupnavbar.svg"
                  style={{ marginLeft: 6 }}
                  alt="nav-arrow-up"
                />
              </font>
              <div
                className="dropdown-corporates"
                style={{
                  opacity: corporatesDropdown ? 1 : 0,
                  pointerEvents: corporatesDropdown ? "all" : "none",
                }}
              >
                <label className={window.location.href.includes("/overall-wellness") && "active"}>
                  <a href="overall-wellness">Overall Wellness</a>
                </label>
                <label className={window.location.href.includes("/employee-engagement") && "active"}>
                  <a href="employee-engagement">Employee Engagement</a>
                </label>
                <label className={window.location.href.includes("/pulse-check-in") && "active"}>
                  <a href="pulse-check-in">Pulse Check-In</a>
                </label>
                <label className={window.location.href.includes("/predict-burnout") && "active"}>
                  <a href="predict-burnout">Predict Burnout</a>
                </label>
              </div>
            </li>
            <li className="hoveranimate">
              <a href="http://www.goodlives.in/blog/" target="_blank">
                Blogs
              </a>
            </li>
          </ul>
        </div>
        <div className="left-navbar-container">
          <p style={{ cursor: "pointer" }}>
            <a href="/signin">Login</a>
          </p>
          <button style={{ cursor: "pointer" }}>
            <a href="/signup">Get Started</a>
          </button>
          {mobileMenuOpen ? (
            <MdClose
              className="menu-icon-navbar"
              onClick={() => {
                if (mobileMenuOpen) {
                  document.getElementById("root").style.position = "static";
                } else {
                  document.getElementById("root").style.position = "fixed";
                  document.getElementById("root").style.width = "100%";
                }
                setMobileMenuOpen(!mobileMenuOpen);
              }}
            />
          ) : (
            <BiMenu
              className="menu-icon-navbar"
              onClick={() => {
                if (mobileMenuOpen) {
                  document.getElementById("root").style.position = "static";
                } else {
                  document.getElementById("root").style.position = "fixed";
                  document.getElementById("root").style.width = "100%";
                }
                setMobileMenuOpen(!mobileMenuOpen);
              }}
            />
          )}
        </div>
      </div>
      <div className="menu-open-modal" style={{ right: mobileMenuOpen ? 0 : "-100%" }}>
        <label>
          <a href="/">Home</a>
        </label>
        <label>
          <font
            onClick={() => setCorporatesDropdown(!corporatesDropdown)}
            style={{ display: "flex", alignItems: "center" }}
          >
            For Corporates{" "}
            <img
              src="https://ucarecdn.com/4e79ba88-dba3-4818-8db6-31d0c71939b9/arrowupnavbar.svg"
              style={{ marginLeft: 6 }}
              alt="nav-arrow-up"
            />
          </font>
          {corporatesDropdown && (
            <div
              className="dropdown-corporates"
              style={{
                opacity: corporatesDropdown ? 1 : 0,
                pointerEvents: corporatesDropdown ? "all" : "none",
              }}
            >
              <label className={window.location.href.includes("/overall-wellness") && "active"}>
                <a href="overall-wellness">Overall Wellness</a>
              </label>
              <label className={window.location.href.includes("/employee-engagement") && "active"}>
                <a href="employee-engagement">Employee Engagement</a>
              </label>
              <label className={window.location.href.includes("/pulse-check-in") && "active"}>
                <a href="pulse-check-in">Pulse Check-In</a>
              </label>
              <label className={window.location.href.includes("/predict-burnout") && "active"}>
                <a href="predict-burnout">Predict Burnout</a>
              </label>
            </div>
          )}
        </label>
        <label>
          <a href="http://www.goodlives.in/blog/" target="_blank">
            Blogs
          </a>
        </label>
        <label>
          <a href="/signin">Login</a>
        </label>
      </div>
    </div>
  );
}

export default Navbar;
