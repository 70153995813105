import React, { useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IoMdLogOut } from "react-icons/io";
import { useUserInfoSelector } from "store/reducers";
import EditProfile from "components/EditProfile/EditProfile";
import "./ProfileMenu.css";
import SignOut from "components/SignOut/SignOut";
import { useHistory } from "react-router-dom";
import { motion } from "motion/react";
import { duration } from "moment";

const avatar = {
  female: "https://ucarecdn.com/5c10944e-91bc-4ee6-a259-23565aac6100/avatar3.png",
  male: "https://ucarecdn.com/e6d48801-632e-4d97-b297-bd15292f07ed/avatar2.png",
  other: "https://ucarecdn.com/732dfe6d-fdf3-40c5-a066-e8857ce85d23/avatar1.png",
};

const ProfileMenu = ({ onEditProfileClick, onRedeemCoinsClick, onChangeTherapistClick, onContactUsClick, onBackClick }) => {
  const userInfo = useUserInfoSelector();
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showSignout, setShowSignout] = useState(false);

  return (
    <>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="profile-menu">
        <button className="profile-back-btn" onClick={onBackClick} >
            <IoIosArrowBack size={24} />
        </button>
        <img src={userInfo.avatar_url ? userInfo.avatar_url : avatar[userInfo.gender]} alt="user-avatar" className="avatar" />
        <div className="pmenu-top">
          <p>{userInfo.fullName}</p>
        </div>
        <button className="menu-item" onClick={onEditProfileClick}>
          <p>Edit Profile</p>
          <IoIosArrowForward />
        </button>
        <button className="menu-item" onClick={onRedeemCoinsClick}>
          <p>Reedem Coins</p>
          <IoIosArrowForward />
        </button>
        <button className="menu-item" onClick={onChangeTherapistClick}>
          <p>Change Therapist</p>
          <IoIosArrowForward />
        </button>
        <a className="menu-item" href="https://goodlives.in/privacy-policy/" target="_blank">
          <p>Privacy Policy</p>
          <IoIosArrowForward />
        </a>
        <button className="menu-item" onClick={onContactUsClick}>
          <p>Contact Us</p>
          <IoIosArrowForward />
        </button>
        <button className="menu-item signout" onClick={() => setShowSignout(true)}>
          <p>Sign Out</p>
          <IoMdLogOut />
        </button>
      </motion.div>
      {showSignout && <SignOut onClose={() => setShowSignout(false)} />}
    </>
  );
};

export default ProfileMenu;
