import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import HeaderHomeScreen from "../../components/HeaderHomeScreen";
import "./UserOnboardingQuestions.css";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { useUserInfoSelector, useUserTokenSelector } from "store/reducers";
import { getOnBoardingQuestions, giveOnboardingQuestionAnswers } from "actions/auth";
import { stopLoading } from "store/actions";
import { useDispatch } from "react-redux";
import Lottie from "lottie-react";
import blissWavingHand from "../../assets/JsonAnimations/blissWavingHand.json";

const UserOnboardingQuestions = () => {
  const history = useHistory();
  const location = useLocation();
  const { signup, canGoBack=false } = location.state || {};
  const [loggedOutMessage, setLoggedOutMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [open, setOpen] = useState(false);
  const userToken = useUserTokenSelector();
  const dispatch = useDispatch();
  const [plannerQuestions, setPlannerQuestions] = useState([]);
  const userInfo = useUserInfoSelector();
  const [currentPage, setCurrentPage] = useState(1);
  const [showBackModal, setShowBackModal] = useState(false);
  const questionsToShow = (() => {
    const startIndex = (currentPage - 1) * 2;
    const endIndex = startIndex + 2;

    return plannerQuestions.slice(startIndex, endIndex);
  })();
  const [expandedQuestions, setExpandedQuestions] = useState({});
  const isFirstScreen = currentPage <= 1;
  const isLastScreen = currentPage * 2 >= plannerQuestions.length;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const historyUnblockRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (userToken && userInfo.id) {
      getPlannerQuestions();
    }
  }, []);

  useEffect(() => {
    // Block the browser's back navigation and show modal
    historyUnblockRef.current = history.block((location, action) => {
      if (action === "POP") {
        // This will trigger on back navigation
        if (canGoBack) {
          setShowBackModal(true); // Show the modal asking for confirmation
        }
        return false; // Prevent the navigation
      }
    });
    // setUnblockHistory(unblock);
    // Cleanup on component unmount
    return () => {
      historyUnblockRef.current();
    };
  }, [history]);

  const toggleExpand = (questionId) => {
    setExpandedQuestions((prevState) => ({
      ...prevState,
      [questionId]: !prevState[questionId],
    }));
  };

  const handleOptionSelect = (id, value) => {
    setPlannerQuestions((prevState) =>
      prevState.map((question) => {
        if (question._id === id) {
          const isAlreadySelected = question.answer?.includes(value);

          // Handle multiSelect questions, e.g., 'activitiesDisliked'
          if (question.type === "multiSelect") {
            // Prevent selecting more than 2 options for 'activitiesDisliked'
            if (question.identifier === "activitiesDisliked" && !isAlreadySelected) {
              if (question.answer?.length >= 2) {
                return question; // If there are already 2 selections, don't allow more
              }
            }

            const updatedAnswer = isAlreadySelected
              ? question.answer.filter((option) => option !== value)
              : [...(question.answer || []), value]; // Toggle the selection

            return { ...question, answer: updatedAnswer };
          } else if (question.type === "singleSelect") {
            // For 'singleSelect', just pick the new value and replace the old one
            const updatedAnswer = isAlreadySelected
              ? [] // If the value is already selected, remove it
              : [value]; // Else, select this one value

            return { ...question, answer: updatedAnswer };
          }
        }
        return question; // No change for other questions
      })
    );
  };

  const areAllQuestionsAnswered = (questions) => {
    return questions.every((question) => {
      if (!question.answer || question.answer.length === 0) return false;

      switch (question.identifier) {
        case "problemAreas":
          return question.answer.length >= 2;
        case "activitiesDisliked":
          return true;
        default:
          return question.answer.length > 0;
      }
    });
  };

  const handleContinueClick = async () => {
    try {
      if (!isLastScreen) {
        setCurrentPage((prev) => prev + 1);
      } else {
        const payload = plannerQuestions.map((que) => ({
          identifier: que.identifier,
          answer: que.answer || [],
        }));

        // Validate if all questions have been answered
        const isAllAnswered = areAllQuestionsAnswered(payload);
        if (!isAllAnswered) {
          setSeverity("error");
          setLoggedOutMessage("Please answer all questions");
          setOpen(true);
        }

        // Send answers to API
        const response = await giveOnboardingQuestionAnswers(userInfo.id, userToken, payload);
        const { data } = response;
        if (data.status !== "success") {
          setSeverity("error");
          setLoggedOutMessage(data.error || "Something went wrong, please try again later!");
          setOpen(true);
          throw new Error(data.error);
        }
        history.push("/dashboard", { redirectFrom: "signup" });
      }
    } catch (error) {
      setSeverity("error");
      setLoggedOutMessage(error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
      setOpen(true);
    } finally {
      dispatch(stopLoading());
    }
  };

  const getPlannerQuestions = async () => {
    try {
      const response = await getOnBoardingQuestions(userInfo.id, userToken);
      const { data } = response;

      if (data.status !== "success") {
        throw new Error(data.error);
      }
      setPlannerQuestions(data.questions);
    } catch (error) {
      setLoggedOutMessage(error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
      setOpen(true);
      console.error("error", error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
    setLoggedOutMessage("");
  };

  const handleBackModalClose = () => {
    setShowBackModal(false); // Close the modal
  };

  const handleBackModalConfirm = () => {
    if (!canGoBack) {
      return;
    }
    setShowBackModal(false); // Close the modal
    historyUnblockRef.current();
    history.replace("/buildYourSpace", { isBack: true });
  };

  return (
    <div>
      <HeaderHomeScreen />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {loggedOutMessage}
        </Alert>
      </Snackbar>
      {isMobile ? (
        <div className="mobileScree-onboarding-screen-main-container">
          <div>
            <h2 className="mobileScreen-onboarding-heading">Build Your Space</h2>
          </div>
          <div className="progress-indicator1" style={{ marginLeft: "20px", marginTop: "10px", marginBottom: "20px" }}>
            <span className={`step1 ${currentPage >= 1 ? "active1" : ""}`}></span>
            <span className={`step1 ${isFirstScreen || currentPage >= 2 ? "active1" : ""}`}></span>
            <span className={`step1 ${isLastScreen ? "active1" : ""}`}></span>
          </div>
          {questionsToShow.map((question) => {
            const isExpanded = expandedQuestions[question._id] || false;

            const visibleOptions =
              question.identifier === "activitiesDisliked"
                ? question.options
                : question.options.length <= 8
                ? question.options
                : isExpanded
                ? question.options
                : question.options.slice(0, 7);

            return (
              <div key={question._id} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <p className="onboarding-box-heading">
                  {question.questionText.split(" ").map((word, index) =>
                    word.toLowerCase() === "don't" ? (
                      <span key={index} className="highlightedText">
                        {" "}
                        {word}{" "}
                      </span>
                    ) : (
                      ` ${word} `
                    )
                  )}
                </p>
                {question.shortNote && <p className="sub-heading">{question.shortNote}</p>}
                <div
                  className="options-container"
                  style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${question.type === "singleSelect" ? 2 : 3}, 1fr)`,
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  {question.type === "input" ? (
                    <input
                      type="text"
                      value={question.answer}
                      onChange={(e) => handleOptionSelect(question._id, e.target.value)}
                      placeholder="Enter value"
                      style={{ padding: "10px", borderRadius: "8px", border: "1px solid #ccc" }}
                    />
                  ) : (
                    <>
                      {visibleOptions.map((option, idx) => (
                        <button
                          key={idx}
                          className={`option-button ${question.answer?.includes(option) ? "selected" : ""}`}
                          onClick={() => {
                            handleOptionSelect(question._id, option);
                          }}
                        >
                          {option}
                        </button>
                      ))}
                      {question.options.length > 8 && question.identifier !== "activitiesDisliked" && (
                        <button className="option-button" onClick={() => toggleExpand(question._id)}>
                          {isExpanded ? "See Less" : "See More"}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })}

          <div className="mobileScreen-onboarding-btn-Continuemain">
            <button
              onClick={handleContinueClick}
              disabled={!isLastScreen && !areAllQuestionsAnswered(questionsToShow)}
              style={{
                backgroundColor: !isLastScreen && !areAllQuestionsAnswered(questionsToShow) ? "rgba(73, 175, 124, 0.4)" : "#49af7c",
                padding: "10px 15px",
                borderRadius: "25px",
                color: "white",
                cursor: "pointer",
                width: "100%",
                border: "0px",
              }}
            >
              {isFirstScreen ? "Next" : "Continue"}
            </button>
          </div>
        </div>
      ) : (
        <div className="onboarding-screen-main-container">
          <div className="onboarding-box">
            <div className="progress-indicator1">
              <span className={`step1 ${currentPage >= 1 ? "active1" : ""}`}></span>
              <span className={`step1 ${isFirstScreen || currentPage >= 2 ? "active1" : ""}`}></span>
              <span className={`step1 ${isLastScreen ? "active1" : ""}`}></span>
            </div>
            {questionsToShow.map((question) => {
              const isExpanded = expandedQuestions[question._id] || false;

              const visibleOptions =
                question.identifier === "activitiesDisliked"
                  ? question.options
                  : question.options.length <= 8
                  ? question.options
                  : isExpanded
                  ? question.options
                  : question.options.slice(0, 7);

              return (
                <div key={question._id}>
                  {/* <p className="onboarding-box-heading">{question.questionText}</p> */}
                  <p className="onboarding-box-heading">
                    {question.questionText.split(" ").map((word, index) =>
                      word.toLowerCase() === "don't" ? (
                        <span key={index} className="highlightedText">
                          {" "}
                          {word}{" "}
                        </span>
                      ) : (
                        ` ${word} `
                      )
                    )}
                  </p>
                  {question.shortNote && <p className="sub-heading">{question.shortNote}</p>}
                  <div
                    className="options-container"
                    style={{
                      display: "grid",
                      gridTemplateColumns: `repeat(${question.type === "singleSelect" ? 2 : 3}, 1fr)`,
                      gap: "10px",
                      width: question.type === "singleSelect" ? "68%" : "100%",
                      justifyContent: "center",
                    }}
                  >
                    {question.type === "input" ? (
                      <input
                        type="text"
                        value={question.answer}
                        onChange={(e) => handleOptionSelect(question._id, e.target.value)}
                        placeholder="Enter value"
                        style={{ padding: "10px", borderRadius: "8px", border: "1px solid #ccc" }}
                      />
                    ) : (
                      <>
                        {visibleOptions.map((option, idx) => (
                          <button
                            key={idx}
                            style={{ width: "200px" }}
                            className={`option-button ${question.answer?.includes(option) ? "selected" : ""}`}
                            onClick={() => {
                              handleOptionSelect(question._id, option);
                            }}
                          >
                            {option}
                          </button>
                        ))}
                        {question.options.length > 8 && question.identifier !== "activitiesDisliked" && (
                          <button className="option-button" style={{ width: "200px" }} onClick={() => toggleExpand(question._id)}>
                            {isExpanded ? "See Less" : "See More"}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              );
            })}
            <div className="btn-Continuemain">
              <button
                onClick={handleContinueClick}
                disabled={!isLastScreen && !areAllQuestionsAnswered(questionsToShow)}
                style={{
                  backgroundColor:
                    !isLastScreen && !areAllQuestionsAnswered(questionsToShow)
                      ? "rgba(73, 175, 124, 0.4)" // Disabled background color
                      : "#49af7c", // Active background color
                  padding: "10px 15px",
                  borderRadius: "25px",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                {isFirstScreen ? "Next" : "Continue"}
              </button>
            </div>
          </div>
          <div className="onboarding-box-Image" style={{ position: "fixed", right: "0px" }}>
            <h2 style={{ textAlign: "left" }}>
              Build
              <br />
              Your
              <br />
              Space
            </h2>
            <Lottie
              animationData={blissWavingHand}
              style={{ height: "280px", width: "280px", marginRight: "-80px" }}
              loop={false}
            />
          </div>
        </div>
      )}
      {showBackModal && (
        <div className="user-modal">
          <div className="signout-modal">
            <h2 className="signout-main-head">Your account is getting created</h2>
            <p className="signout-head">Going back will hamper this process. Do you still wish to continue?</p>
            <div className="sbtns">
              <button className="signout-btn" onClick={handleBackModalConfirm}>
                Yes
              </button>
              <button className="signout-btn no" onClick={handleBackModalClose}>
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserOnboardingQuestions;
