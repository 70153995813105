import React, { Component } from "react";
// import logo from "../assets/images/headerbookinglogo.png"
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import SettingsIcon from "@material-ui/icons/Settings";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import { Tooltip } from "antd";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

export class HeaderBooking extends Component {
  state = {
    overviewActive: true,
    usersActive: false,
    sessionsActive: false,
    organizationalActive: false,
    supportActive: false,
    menuOpen: false,
    notifications: [],
    showNotificationsPanel: false,
  };

  render() {
    return (
      <div className="headerbookings">
        <img
          src="https://ucarecdn.com/4748640f-b537-4b9b-8896-362944eaaae3/headerbookinglogo.png"
          className="image-logo-hb"
        />
        <div className="lists-header-booking">
          <ul>
            <li>
              <a href="/" style={{ textDecoration: "none", color: "#212529" }}>
                Home
              </a>
            </li>
            <li>
              <a
                href="https://www.goodlives.in/how-it-works/"
                style={{ textDecoration: "none", color: "#212529" }}
              >
                How it works?
              </a>
            </li>
            <li>
              <a
                href="/corporates"
                style={{ textDecoration: "none", color: "#212529" }}
              >
                For Corporates
              </a>
            </li>
            <li>
              <a
                href="https://www.goodlives.in/blog/"
                style={{ textDecoration: "none", color: "#212529" }}
              >
                Blogs
              </a>
            </li>
          </ul>
        </div>
        <div className="menu-header-booking">
          <div className="lists-tn notificationsboxcontainer">
            <NotificationsNoneIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({ showNotificationsPanel: true });
              }}
            />
            <div className="ring-counts">
              <p>{this.state.notifications.length}</p>
            </div>
            {this.state.showNotificationsPanel && (
              <div className="inner-notificationboxmain">
                <div className="top-inbm">
                  <p>Notifications</p>
                </div>
                <div className="bottom-inbm">
                  {this.state.notifications.length > 0 ? (
                    <ol>
                      {this.state.notifications.map((e, i) => {
                        return (
                          <li
                            onClick={() => {
                              this.props.showSessions();
                              document.getElementById("root").style.overflowY =
                                "auto";
                              document.getElementById("root").style.position =
                                "static";
                              this.setState({
                                sessionsActive: true,
                                usersActive: false,
                                organizationalActive: false,
                                supportActive: false,
                                overviewActive: false,
                                walletActive: false,
                                supportActiveBottom: false,
                                menuOpen: false,
                                showNotificationsPanel: false,
                              });
                            }}
                          >
                            {i + 1}. Notification {e}
                          </li>
                        );
                      })}
                    </ol>
                  ) : (
                    <p className="nonewnotficiationslabel">
                      <NotificationsOffIcon />
                      <font>
                        No New <br /> Notifications
                      </font>
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="lists-tn">
            <Tooltip
              title="Log Out"
              zIndex="999999999"
              placement="bottom"
              overlayInnerStyle={{
                borderRadius: "10px",
                fontSize: "10px",
                fontWeight: "bold",
                color: "#000",
              }}
              color="#e8e8e8"
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={this.props.handleLogout}
              >
                <ExitToAppIcon />
              </div>
            </Tooltip>
          </div>
          {window.innerWidth < 1051 && (
            <div className="lists-tn">
              <MenuIcon
                onClick={() => {
                  document.getElementById("root").style.overflowY = "hidden";
                  document.getElementById("root").style.position = "fixed";
                  this.setState({ menuOpen: true });
                }}
              />
            </div>
          )}
        </div>
        {this.state.menuOpen && (
          <div className="menu-lists-mobile">
            <CloseIcon
              style={{
                position: "absolute",
                top: 15,
                right: 15,
                zIndex: 99,
                fill: "white",
              }}
              onClick={() => {
                document.getElementById("root").style.overflowY = "auto";
                document.getElementById("root").style.position = "static";
                this.setState({ menuOpen: false });
              }}
            />
            <div className="left-menustuff">
              <p className="title-lms">
                GoodLives<sup>TM</sup>
              </p>
              <div className="content-lms">
                <ul>
                  <li>
                    <a
                      href="/"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.goodlives.in/how-it-works/"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      How it works?
                    </a>
                  </li>
                  <li>
                    <a
                      href="/corporates"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      For Corporates
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.goodlives.in/blog/"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      Blogs
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
        {this.state.showNotificationsPanel && (
          <div
            className="popup-payment-alert-overaly"
            style={{ backgroundColor: "transparent" }}
            onClick={() => {
              this.setState({ showNotificationsPanel: false });
            }}
          ></div>
        )}
      </div>
    );
  }
}

export default HeaderBooking;
