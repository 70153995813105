import { useState, useEffect, useRef } from "react";
import HeaderHomeScreen from "../../components/HeaderHomeScreen";
import "./index.css";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { useUserTokenSelector } from "store/reducers";
import { useHistory, useLocation } from "react-router-dom";
import { checkOTP, sendOTPToUser, signinB2C } from "actions/auth";
import { useDispatch } from "react-redux";
import { setUserInfo, setUserToken, startLoading, stopLoading } from "store/actions";
import Lottie from "lottie-react";
import blissWelcomingFinalWeb from "../../assets/JsonAnimations/blissWelcomingFinalWeb.json";
import blissClosingEyesFinalWeb from "../../assets/JsonAnimations/blissClosingEyesFinalWeb.json";

const OTPVerification = () => {
  const [loggedOutMessage, setLoggedOutMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [otpSent, setOtpSent] = useState(true);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [secondsLeft, setSecondsLeft] = useState(30);
  const [isTimerEnded, setIsTimerEnded] = useState(false);
  const inputRefs = useRef([]);
  const userToken = useUserTokenSelector();
  const location = useLocation();
  const dispatch = useDispatch();
  const number = location.state?.number || "";
  const countryCode = location.state?.countryCode || "";
  const email = location.state?.email || "";
  const [severity, setSeverity] = useState("");
  const history = useHistory();
  const [isOtpFocused, setIsOtpFocused] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (otpSent && secondsLeft > 0) {
      const timer = setInterval(() => {
        setSecondsLeft((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (secondsLeft === 0) {
      setIsTimerEnded(true);
    }
  }, [secondsLeft, otpSent]);

  const sendOtp = async () => {
    try {
      dispatch(startLoading())
    const response = await sendOTPToUser({
      phone: number,
      countryCode: countryCode,
      purpose: 1,
    });
    if (response.data.otpSent) {
      dispatch(stopLoading())
      setSeverity("success")
      setLoggedOutMessage(response.data.message || "OTP sent successfully")
      setOpen(true)
      setOtpSent(true)
      setSecondsLeft(30)
      setIsTimerEnded(false)
    } else {
      dispatch(stopLoading())
    }
    } catch (error) {
      setSeverity("error")
      setLoggedOutMessage(error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
      setOpen(true)
      console.error("error", error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
    } finally {
      dispatch(stopLoading())
    }
  };

  const handleOtpChange = (index, value) => {
    if (!/^\d?$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }

    // Check if OTP is complete, if so, trigger OTP verification
    if (newOtp.every((digit) => digit !== "")) {
      verifyOtp(newOtp); // Call the verifyOTP function once all digits are entered
    }
  };

  const handleOtpKeyDown = (index, event) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const verifyOtp = async (otp) => {
    const otpValue = otp.join("");
    try {
      if (otpValue.length !== 6) {
        setSeverity("error")
        setLoggedOutMessage("Please enter six digit OTP");
        setOpen(true)
        return;
      } else {
        dispatch(startLoading())
        if (!email) {
          const response = await signinB2C({
            phone: number,
            otp: otpValue,
            devicetoken: null,
          });
          if (response.data.status === "success") {
            window.localStorage.setItem("auth", JSON.stringify(response.data))
            dispatch(setUserInfo(response.data.user));
            dispatch(setUserToken(response.data.token));
            dispatch(stopLoading());
            history.push("/dashboard", { redirectFrom: "signin" })
          } else if (response.data.redirectToSignup) {
            history.push({
              pathname: "/buildYourSpace",
              state: { phone: number, countryCode: countryCode, otp: otpValue }
            })
            dispatch(stopLoading())
          } else {
            dispatch(stopLoading())
            console.error("error")
          }
        } else {
          const response = await checkOTP({
            email,
            otp: otpValue,
            captcha: ""
          })
          if (response.data.status === "success") {
            dispatch(stopLoading())
            history.push({
              pathname: "/forgetPassword",
              state: { otpVerified: true, email, otp: otpValue }
            })
          } else {
            dispatch(stopLoading())
            console.error("OTP incorrect")
          }
          dispatch(stopLoading())
        }
      }
    } catch (error) {
      setSeverity("error")
      setLoggedOutMessage(error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
      setOpen(true)
      console.error("error", error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
    } finally {
      dispatch(stopLoading())
    }
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
    setLoggedOutMessage("");
  };

  return (
    <div>
      <HeaderHomeScreen />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {loggedOutMessage}
        </Alert>
      </Snackbar>
      <div className="otp-screen-main-container">
      {isMobile ? (
        <>
        <div className="mobileScreen-otp-box-Image">
        <h3>OTP Verification</h3>
          <Lottie
            key={isOtpFocused ? "closing" : "welcoming"}
            animationData={isOtpFocused ? blissClosingEyesFinalWeb : blissWelcomingFinalWeb} 
            style={{height:'300px',width:'300px',marginTop:'-35px'}}
            loop={false}
          />
        </div>
        <div className="otp-box" style={{marginTop:'0px'}}>
          {
            email ?
              <p className="otp-txt" style={{textAlign:'center'}}>We’ve sent you a one-time password on <p className="opt-number">{email}</p></p>
              :
              <p className="otp-txt">We’ve sent you a one-time password on <p className="opt-number">{countryCode}-{number}</p></p>
          }
          <div className="otp-container" style={{width:'100%',justifyContent:'space-between'}}>
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                onKeyDown={(e) => handleOtpKeyDown(index, e)}
                ref={(el) => (inputRefs.current[index] = el)}
                className="otp-input"
                onFocus={() => setIsOtpFocused(true)}
                onBlur={() => setIsOtpFocused(false)}
              />
            ))}
          </div>
          {number && <p className="otp-txt">
            {isTimerEnded ? (
              <span onClick={sendOtp} style={{ color: "#394b42", cursor: "pointer", fontWeight: '600' }}>
                Resend OTP
              </span>
            ) : (
              `00:${secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft} Resend`
            )}
          </p>}
          <div className="btn-otpmain">
            <button onClick={() => verifyOtp(otp)}>Verify OTP</button>
          </div>
        </div>
        </>
      ) : (
        <>
        <div className="otp-box">
          <p className="otp-box-heading">OTP Verification</p>
          {
            email ?
              <p className="otp-txt" style={{textAlign:'center'}}>We’ve sent you a one-time password on <p className="opt-number">{email}</p></p>
              :
              <p className="otp-txt">We’ve sent you a one-time password on <p className="opt-number">{countryCode}-{number}</p></p>
          }
          <div className="otp-container">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                onKeyDown={(e) => handleOtpKeyDown(index, e)}
                ref={(el) => (inputRefs.current[index] = el)}
                className="otp-input"
                onFocus={() => setIsOtpFocused(true)}
                onBlur={() => setIsOtpFocused(false)}
              />
            ))}
          </div>
          {/* Timer & Resend OTP Button */}
          {number && <p className="otp-txt">
            {isTimerEnded ? (
              <span onClick={sendOtp} style={{ color: "#394b42", cursor: "pointer", fontWeight: '600' }}>
                Resend OTP
              </span>
            ) : (
              `00:${secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft} Resend`
            )}
          </p>}
          <div className="btn-otpmain">
            <button onClick={() => verifyOtp(otp)}>Verify OTP</button>
          </div>
        </div>
        <div className="otp-box-Image">
        <h3>{email ? ("Verify Your E-mail"):("Verify your mobile number")}</h3>
          <Lottie
            key={isOtpFocused ? "closing" : "welcoming"}
            animationData={isOtpFocused ? blissClosingEyesFinalWeb : blissWelcomingFinalWeb} 
            style={{height:'300px',width:'300px',marginLeft:'8vh'}}
            loop={false}
          />
        </div>
        </>
      )}
      </div>
    </div>
  );
};

export default OTPVerification;