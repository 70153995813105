import "./SignOut.css";
import { logout } from "../../actions/auth";
import { logoutUser } from "store/actions";
import { useDispatch } from "react-redux";
import { useUserTokenSelector } from "store/reducers";
import { useHistory } from "react-router";

const SignOut = ({ onClose }) => {
    const userToken = useUserTokenSelector();
    const dispatch = useDispatch();
    const history = useHistory();
    const handleLogout = () => {
        logout(userToken);

        logoutUser(dispatch);

        window.localStorage.removeItem("auth");

        history.push({
            pathname: "/signin",
            state: {
                loggedOutMessage: "You have been logged out. Please login again.",
            },
        });
    };
    return (
        <div className="user-modal">
            <div className="signout-modal"> 
                <p className="signout-head">Are you sure you want to sign out?</p>
                <div className="sbtns">
                    <button className="signout-btn" onClick={handleLogout}>Yes</button>
                    <button className="signout-btn no" onClick={onClose}>No</button>
                </div>
            </div>
        </div>
    )
}

export default SignOut;