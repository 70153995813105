import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { loadAllPaymentPlans } from "../../actions/dataLoad";
import LoadingSessionBooked from "../../components/Utilities/Loading/LoadingSessionBooked";
import AOS from "aos";
import "./index.css";
import "aos/dist/aos.css";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useUserTokenSelector } from "store/reducers";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "store/actions";
import BookNowButton from "../../assets/svg_icons/book_now_button.svg";
import { IoIosArrowBack } from "react-icons/io";
AOS.init();

export const PaymentPlan = ({
  routeProps,
  categoryId,
  plans,
  walletSessionBalance,
  payAndBookEvent,
  errorPayment,
  setErrorPayment,
  loading,
  setShowPayment,
  currency,
  handleLogout,
}) => {
  const history = useHistory();
  const userToken = useUserTokenSelector();
  const [paymentPlans, setPaymentPlans] = useState([]);
  const [walletBalance, setWalletBalance] = useState(0);
  const [categoryid, setCategoryid] = useState();
  const [paymentCurrency, setPaymentCurrency] = useState();
  const [errorpayment, setErrorpayment] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(
    "Bi-monthly Subscription"
  );
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const showError = (text) => {
    setError(text);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
  };
  /*const [errorPayment, setErrorPayment] = useState(false)
  console.log(plans)*/
  const loadPlans = async () => {
    try {
      dispatch(startLoading());
      const res = await loadAllPaymentPlans(userToken);
      const sortedPlans =
        res.data.plans?.sort((a, b) => b.sortOrder - a.sortOrder) || [];
      setPaymentPlans(sortedPlans);
      setWalletBalance(res.data.walletSessionBalance);
      dispatch(stopLoading());
    } catch (error) {
      dispatch(stopLoading());
      showError(error.toString());
    }
  };

  useEffect(() => {
    if (plans) {
      const sortedPlans = [...plans].sort((a, b) => b.sortOrder - a.sortOrder);

      setPaymentPlans(sortedPlans);
    } else {
      history.push("/dashboard/sessionBooking");
    }
    if (walletSessionBalance) {
      setWalletBalance(walletSessionBalance);
    }
    if (currency) {
      setPaymentCurrency(currency);
    }
    if (categoryId) {
      setCategoryid(categoryId);
    }
    if (errorPayment) {
      setErrorpayment(errorPayment);
    }
    if (routeProps) {
      if (routeProps.currency) {
        setPaymentCurrency(routeProps.currency);
      }
      if (routeProps.categoryId) {
        setCategoryid(routeProps.categoryId);
      }
      if (routeProps.errorPayment) {
        setErrorpayment(routeProps.errorPayment);
      }
      try {
        dispatch(startLoading());
        loadPlans();
        dispatch(stopLoading());
      } catch (error) {}
    }

    if (categoryId === 2 || categoryId === 3) {
      setSelectedSubscription("Deduct from Wallet");
    }
  }, []);

  const bookEvent = async (p) => {
    try {
      await payAndBookEvent(p);
    } catch (err) {
      if (err.response.data.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(
          () =>
            history.push({
              pathname: "/signin",
              state: { referer: location.pathname },
            }),
          3000
        );
      } else {
        showError(err.response.data.error);
      }
    }
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
      {isMobile ? ( 
      <div className="pp-mobileScreen-container">
        <div className="pp-mobileScreen-header">
            <button
              onClick={() => setShowPayment(false)}
              className="pp-mobileScreen-backButton"
            >
              <IoIosArrowBack size={13} style={{marginTop:'-6px'}}/>
            </button>
            <h3>Affordable Packages</h3>
            <div className="pp-mobileScreen-backButton" style={{backgroundColor:'#fffcf8'}}/>
          </div>
          <div className="pp-mobileScreen-progress-container">
            <div className="progress-line"></div>
            <div
              className="progress-fill"
              style={{ width: `${(2 / 2.5) * 100}%` }}
            ></div>
            <div className="progress-steps">
              <div className="pp-mobileScreen-progress-step completed" style={{color:'white',backgroundColor:'#49af7c',borderColor:'#49af7c'}}>✔</div>
              <div className="pp-mobileScreen-progress-step completed" style={{color:'white',backgroundColor:'#49af7c',borderColor:'#49af7c'}}>✔</div>
              <div className="pp-mobileScreen-progress-step active"></div>
            </div>
          </div>
          {paymentPlans && (
          <div
          className="pp-mobileScreen-left-cpp"
          >
            {paymentPlans
              .filter((p) =>
                categoryid === 1
                  ? p.planID !== 1001
                  : categoryid === 2
                  ? p.planID === 1001
                  : true
              )
              .map((p) => {
                let basePrice = p.basePrice;
                let discountedPrice = p.discountedPrice;
                let pricePerSession = p.pricePerSession;

                if (paymentCurrency !== "INR") {
                  basePrice = p.basePriceUSD;
                  discountedPrice = p.discountedPriceUSD;
                  pricePerSession = p.pricePerSessionUSD;
                }

                return (
                  <>
                    <div
                      className={`pp-mobileScreen-container-lcpp ${
                        selectedSubscription === p.planName && p.planID !== 1001
                          ? "active"
                          : ""
                      }`}
                      key={p.planID}
                      onClick={() => {
                        if (p.planID !== 1001) {
                          setSelectedSubscription(
                            selectedSubscription === p.planName
                              ? ""
                              : p.planName
                          );
                        }
                      }}
                    >
                      {p.planID !== 1001 &&
                        basePrice - discountedPrice !== 0 &&
                        selectedSubscription !== p.planName && (
                          <div className="pp-mobileScreen-tag-specialsubscription">
                            <p>
                              Save {basePrice - discountedPrice}{" "}
                              {paymentCurrency}
                            </p>
                          </div>
                        )}

                      <p className="subscription-title" style={{fontSize:"15px"}}>
                        {p.planName}
                        {p.planID !== 1001 && ( // Remove side arrow for planID 1001
                          <>
                            <div className="moresubscription" style={{backgroundColor:'#E8FDF2',color:'black'}}>
                              <ExpandMoreIcon />
                            </div>
                            <div className="lesssubscription" style={{backgroundColor:'#E8FDF2',color:'black'}}>
                              <ExpandLessIcon />
                            </div>
                          </>
                        )}
                      </p>

                      {/* If planID === 1001, show only the custom text */}
                      {p.planID === 1001 ? (
                        <>
                          <p className="wallet-session-text" style={{height:'200px'}}>
                            Schedule a therapy session from your wallet
                          </p>
                          <p></p>
                        </>
                      ) : (
                        <>
                          <p className="subscription-subtitle">
                            This package includes:
                          </p>
                          {selectedSubscription === p.planName && (
                            <ul className="subscription-subtitle">
                              {p.planFeatures.map((f, index) => (
                                <li key={index}>{f}</li>
                              ))}
                            </ul>
                          )}
                        </>
                      )}

                      <div
                        style={{
                          display: "flex",
                          flexDirection:'column',
                        }}
                      >
                        {p.planID !== 1001 && (
                          <div className="price-details">
                            {selectedSubscription === p.planName && (
                              <>
                                <div className="price-row" style={{marginBottom:'5px'}}>
                                  {basePrice > discountedPrice && (
                                    <p  style={{fontSize:'12px'}}>{basePrice}</p>
                                  )}
                                  <p style={{fontSize:'18px'}}>{discountedPrice}</p>
                                </div>
                                <p className="sessionName" style={{fontSize:'12px'}}>
                                  {" "}
                                  for {p.planName.toLowerCase()}
                                </p>
                              </>
                            )}
                            <p
                              className="subscription-price"
                              style={{ marginTop: "-20px",fontSize: 15 }}
                            >
                              {pricePerSession}{" "}
                              <span style={{ fontSize: 10 }}>
                                {paymentCurrency}{" "}
                              </span>
                              <span style={{ fontWeight: 500,fontSize: 16 }}>
                                {" "}
                                / Session
                              </span>
                            </p>
                          </div>
                        )}
    <div>
    <img src="https://ucarecdn.com/87474e1b-10c7-4381-9756-cd7dcaa1cee7/bookNowBtn.svg"
    style={{height:'80px',width:'210px',alignSelf:'center'}}
    className={`subscription-price book-now-btn ${
        p.planID === 1001 ? "special" : "default"
      }`} 
      onClick={() => {
        if (p.planID === 1001 && walletBalance < 1) {
          showError(
            "No Balance Available in your Wallet. Please use other payment options."
          );
        } else {
          bookEvent(p);
        }
      }}
      />
      </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        )}
      </div> 
      ) :(
      <div className="complete-payment-plan">
        <button onClick={() => setShowPayment(false)} className='backButton'>
            <IoIosArrowBack size={18} />
        </button>
        <div className="container-rcpp">
          <h3 style={{ paddingTop: "20px", }}>Affordable Packages</h3>
        </div>
        <div
          className="progress-container"
          style={{ position:'absolute',top:'0px',marginLeft: "-30px"}}
        >
          {/* Background line below the steps */}
          <div className="progress-line"></div>
          {/* Filled progress line (Green only till completed steps) */}
          <div
            className="progress-fill"
            style={{ width: `${(2 / 2.5) * 100}%`  }}
          ></div>

          {/* Progress Steps */}
          <div className="progress-steps">
            <div className="progress-step completed">✔</div>
            <div className="progress-step completed">✔</div>
            <div className="progress-step active"></div>
          </div>
        </div>

        {paymentPlans && (
          <div className="left-cpp">
            {paymentPlans
              .filter((p) =>
                categoryid === 1
                  ? p.planID !== 1001
                  : categoryid === 2
                  ? p.planID === 1001
                  : true
              )
              .map((p) => {
                let basePrice = p.basePrice;
                let discountedPrice = p.discountedPrice;
                let pricePerSession = p.pricePerSession;

                if (paymentCurrency !== "INR") {
                  basePrice = p.basePriceUSD;
                  discountedPrice = p.discountedPriceUSD;
                  pricePerSession = p.pricePerSessionUSD;
                }

                return (
                  <>
                    <div
                      className={`container-lcpp ${
                        selectedSubscription === p.planName && p.planID !== 1001
                          ? "active"
                          : ""
                      }`}
                      key={p.planID}
                      onClick={() => {
                        if (p.planID !== 1001) {
                          setSelectedSubscription(
                            selectedSubscription === p.planName
                              ? ""
                              : p.planName
                          );
                        }
                      }}
                    >
                      {p.planID !== 1001 &&
                        basePrice - discountedPrice !== 0 &&
                        selectedSubscription !== p.planName && (
                          <div className="tag-specialsubscription">
                            <p>
                              Save {basePrice - discountedPrice}{" "}
                              {paymentCurrency}
                            </p>
                          </div>
                        )}

                      <p className="subscription-title">
                        {p.planName}
                        {p.planID !== 1001 && ( // Remove side arrow for planID 1001
                          <>
                            <div className="moresubscription" style={{backgroundColor:'#E8FDF2',color:'black'}}>
                              <ExpandMoreIcon />
                            </div>
                            <div className="lesssubscription" style={{backgroundColor:'#E8FDF2',color:'black'}}>
                              <ExpandLessIcon />
                            </div>
                          </>
                        )}
                      </p>

                      {/* If planID === 1001, show only the custom text */}
                      {p.planID === 1001 ? (
                        <>
                          <p className="wallet-session-text" style={{height:'200px'}}>
                            Schedule a therapy session from your wallet
                          </p>
                          <p></p>
                        </>
                      ) : (
                        <>
                          <p className="subscription-subtitle">
                            This package includes:
                          </p>
                          {selectedSubscription === p.planName && (
                            <ul className="subscription-subtitle">
                              {p.planFeatures.map((f, index) => (
                                <li key={index}>{f}</li>
                              ))}
                            </ul>
                          )}
                        </>
                      )}

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {p.planID !== 1001 && (
                          <div className="price-details">
                            {selectedSubscription === p.planName && (
                              <>
                                <div className="price-row">
                                  {basePrice > discountedPrice && (
                                    <p>{basePrice}</p>
                                  )}
                                  <p>{discountedPrice}</p>
                                </div>
                                <p className="sessionName">
                                  {" "}
                                  for {p.planName.toLowerCase()}
                                </p>
                              </>
                            )}
                            <p
                              className="subscription-price"
                              style={{ marginTop: "-20px" }}
                            >
                              {pricePerSession}{" "}
                              <span style={{ fontSize: 13 }}>
                                {paymentCurrency}{" "}
                              </span>
                              <span style={{ fontWeight: 500 }}>
                                {" "}
                                / Session
                              </span>
                            </p>
                          </div>
                        )}

                        {/* <p
                          className="subscription-price"
                          style={{ fontWeight: 600, cursor: "pointer" }}
                          onClick={() => {
                            if (p.planID === 1001 && walletBalance < 1) {
                              showError(
                                "No Balance Available in your Wallet. Please use other payment options."
                              );
                            } else {
                              bookEvent(p);
                            }
                          }}
                        >
                          <span className="book-txt">Book Now</span>
                        </p> */}
    <div className="book-now-container">
    <img src={BookNowButton} 
    className={`subscription-price book-now-btn ${
        p.planID === 1001 ? "special" : "default"
      }`} 
      onClick={() => {
        if (p.planID === 1001 && walletBalance < 1) {
          showError(
            "No Balance Available in your Wallet. Please use other payment options."
          );
        } else {
          bookEvent(p);
        }
      }}
      />
      </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        )}
      </div>
    )}
      {errorpayment && (
        <div
          className="popup-payment-alert-overaly"
          onClick={() => {
            /*this.setState({ errorPayment: false }) }*/ setErrorpayment(false);
          }}
        ></div>
      )}
      <div
        className="popup-payment-alert"
        style={{
          opacity: errorpayment ? 1 : 0,
          pointerEvents: errorpayment ? "all" : "none",
        }}
      >
        <div className="left-ppa">
          <p className="title-ilppa">Oops!</p>
          <p className="desc-ilppa">
            Sorry, the Payment couldn't go through. Please retry to complete the
            payment. If the money is debited from your account, it will be
            credited back in 5-7 business days.
          </p>
          <img
            src="https://ucarecdn.com/149066e3-2139-4d8c-985b-6908c7a2e08b/paymentFailIcon.svg"
            alt="warning"
          />
          <div className="retrybtn">
            <button
              onClick={() => {
                setErrorpayment(false);
              }}
            >
              Retry Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentPlan;
