import React, { Component } from "react"
import SettingsIcon from "@material-ui/icons/Settings"
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import ListAltIcon from "@material-ui/icons/ListAlt"
import GroupIcon from "@material-ui/icons/Group"
import AssessmentIcon from "@material-ui/icons/Assessment"
import MoodIcon from "@material-ui/icons/Mood"
import HelpIcon from "@material-ui/icons/Help"
import "./index.css"

export class TopNavbar extends Component {
  state = {
    overviewActive: true,
    usersActive: false,
    sessionsActive: false,
    organizationalActive: false,
    supportActive: false,
    menuOpen: false,
  }

  render() {
    return (
      <div className='top-navbar'>
        {window.innerWidth < 1051 && (
          <p className='title-lms'>
            GoodLives<sup>TM</sup>
          </p>
        )}
        <div className='container-tn'>
          <div className='lists-tn'>
            <NotificationsNoneIcon />
            <div className='ring-counts'>
              <p>1</p>
            </div>
          </div>
          <div className='lists-tn'>
            <SettingsIcon />
          </div>
          <div className='lists-tn'>
            <div
              className='profile-img'
              style={{ cursor: "pointer" }}
              onClick={this.props.handleLogout}
            ></div>
          </div>
          {window.innerWidth < 1051 && (
            <div className='lists-tn'>
              <MenuIcon
                onClick={() => {
                  document.getElementById("root").style.overflowY = "hidden"
                  document.getElementById("root").style.position = "fixed"
                  this.setState({ menuOpen: true })
                }}
              />
            </div>
          )}
        </div>
        {this.state.menuOpen && (
          <div className='menu-lists-mobile'>
            <CloseIcon
              style={{
                position: "absolute",
                top: 15,
                right: 15,
                zIndex: 99,
                fill: "white",
              }}
              onClick={() => {
                document.getElementById("root").style.overflowY = "auto"
                document.getElementById("root").style.position = "static"
                this.setState({ menuOpen: false })
              }}
            />
            <div className='left-menustuff'>
              <p className='title-lms'>
                GoodLives<sup>TM</sup>
              </p>
              <div className='content-lms'>
                <ul>
                  <li
                    className={`${this.state.overviewActive ? "active" : ""}`}
                    onClick={() => {
                      this.props.showOverView()
                      this.setState({
                        usersActive: false,
                        sessionsActive: false,
                        organizationalActive: false,
                        supportActive: false,
                        overviewActive: true,
                      })
                    }}
                  >
                    <ListAltIcon /> Overview
                  </li>
                  <li
                    className={`${this.state.usersActive ? "active" : ""}`}
                    onClick={() => {
                      this.props.showUsers()
                      this.setState({
                        usersActive: true,
                        sessionsActive: false,
                        organizationalActive: false,
                        supportActive: false,
                        overviewActive: false,
                      })
                    }}
                  >
                    <GroupIcon /> Users
                  </li>
                  <li
                    className={`${this.state.sessionsActive ? "active" : ""}`}
                    onClick={() => {
                      this.props.showSessions()
                      this.setState({
                        sessionsActive: true,
                        usersActive: false,
                        organizationalActive: false,
                        supportActive: false,
                        overviewActive: false,
                      })
                    }}
                  >
                    <AssessmentIcon /> Sessions
                  </li>
                  <li
                    className={`${
                      this.state.organizationalActive ? "active" : ""
                    }`}
                    onClick={() => {
                      this.props.showOrganizational()
                      this.setState({
                        organizationalActive: true,
                        sessionsActive: false,
                        usersActive: false,
                        supportActive: false,
                        overviewActive: false,
                      })
                    }}
                  >
                    <MoodIcon /> Organizational Mood
                  </li>
                  <li
                    className={`${this.state.supportActive ? "active" : ""}`}
                    onClick={() => {
                      this.props.showSupport()
                      this.setState({
                        supportActive: true,
                        sessionsActive: false,
                        organizationalActive: false,
                        usersActive: false,
                        overviewActive: false,
                      })
                    }}
                  >
                    <HelpIcon /> Support
                  </li>
                </ul>
              </div>
            </div>
            {/* <ul>
                        <li className={`${this.state.overviewActive ? "active" : ""}`} onClick={() => {
                            this.props.showOverView()
                            this.setState({ usersActive: false, sessionsActive: false, organizationalActive: false, supportActive: false, overviewActive: true })
                        }}><ListAltIcon /> Overview</li>
                        <li className={`${this.state.usersActive ? "active" : ""}`} onClick={() => {
                            this.props.showUsers()
                            this.setState({ usersActive: true, sessionsActive: false, organizationalActive: false, supportActive: false, overviewActive: false })
                        }}><GroupIcon /> Users</li>
                        <li className={`${this.state.sessionsActive ? "active" : ""}`} onClick={() => {
                            this.props.showSessions()
                            this.setState({ sessionsActive: true, usersActive: false, organizationalActive: false, supportActive: false, overviewActive: false })
                        }}><AssessmentIcon /> Sessions</li>
                        <li className={`${this.state.organizationalActive ? "active" : ""}`} onClick={() => {
                            this.setState({ organizationalActive: true, sessionsActive: false, usersActive: false, supportActive: false, overviewActive: false })
                        }}><MoodIcon /> Organizational Mood</li>
                        <li className={`${this.state.supportActive ? "active" : ""}`} onClick={() => {
                            this.setState({ supportActive: true, sessionsActive: false, organizationalActive: false, usersActive: false, overviewActive: false })
                        }}><HelpIcon /> Support</li>
                    </ul> */}
          </div>
        )}
      </div>
    )
  }
}

export default TopNavbar
