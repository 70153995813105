import React, { useEffect, useState } from "react";
import "./UserAssessments.css";
import { FaEdit } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import DailyGoals from "components/DailyGoals/DailyGoals";
import CaloriesIcon from "assets/svg_icons/calories_icon.svg";
import StepsIcon from "assets/svg_icons/steps_icon.svg";
import SocialMediaIcon from "assets/svg_icons/social_media_icon.svg";
import CoinsIcon from "assets/svg_icons/coins.svg";
import StreakIcon from "assets/svg_icons/streak_fire.svg";
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "store/actions";
import { getAssessmentData } from "actions/dataLoad";
import { useUserInfoSelector, useUserTokenSelector } from "store/reducers";
import { Link, useHistory } from "react-router-dom";
import { loadAllQuiz } from "actions/quiz";
import { IoSearch } from "react-icons/io5";
import { motion } from "motion/react";
import Lottie from "lottie-react";
import blissWithTrademill from '../../assets/JsonAnimations/blissWithTrademill.json'

const UserAssessments = ({ showError }) => {
  const [assessments, setAssessments] = useState([]);
  const [searchText, setSearchText] = useState("");
  const userInfo = useUserInfoSelector();
  const userToken = useUserTokenSelector();
  const history = useHistory();
  const dispatch = useDispatch();

  const loadAssessments = async () => {
    try {
      dispatch(startLoading());
      const res = await loadAllQuiz(userToken);
      setAssessments(res.data);
      dispatch(stopLoading());
    } catch (err) {
      console.warn(err);
      dispatch(stopLoading());
      if (err.response?.data?.redirectToSignIn) {
        showError(err.response.data.error);
        setTimeout(() => history.push({ pathname: "/signin", state: { referer: location.pathname } }), 3000);
      } else {
        showError(err.response?.data?.error);
      }
    }
  };

  const filteredAssessments = assessments.filter((item) => {
    return item.name.toLowerCase().includes(searchText.toLowerCase());
  });

  useEffect(() => {
    loadAssessments();
  }, []);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="assessments-page">
      <div className="assessments-heading">
        <h1>Assessments</h1>
      </div>
      <div className="assessments-searchbar">
        <IoSearch size={20} />
        <input placeholder="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
      </div>
      <div className="assessments-container">
        {filteredAssessments.map((a) => {
          return (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              to={`/dashboard/assessments/${a.urlSlug}`}
              className="assessment-card"
            >
              <div className="assessment-card-content">
                <h3>{a.name}</h3>
                <p>{a.description}</p>
              </div>
              <Link to={`/dashboard/assessments/${a.urlSlug}`} className="assessment-card-btn">
                Take Quiz Now
              </Link>
            </motion.div>
          );
        })}
      </div>
    </motion.div>
  );
};

export default UserAssessments;