import "./DailyGoals.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IoAddOutline } from "react-icons/io5";
import React, { useState, useEffect, useRef } from "react";

const goals = [
    {
        "name": "Burn Calories",
        "options": [
            250,
            300,
            350,
            400,
        ],
        "icon": "/images/calories.png"
    },
    {
        "name": "Social Media Detox",
        "options": [
            "15 min",
            "30 min",
            "45 min",
            "1 hour",
        ],
        "icon": "/images/social-media.png"
    },
    {
        "name": "Walking",
        "options": [
            "250 steps",
            "300 steps",
            "1350 steps",
            "1500 steps",
        ],
        "icon": "/images/steps.png"
    },
    {
        "name": "Meditation",
        "options": [
            "15 min",
            "30 min",
            "45 min",
        ],
        "icon": "/images/meditation.png"
    },
    {
        "name": "Yoga",
        "options": [
            "15 min",
            "30 min",
            "45 min",
        ],
        "icon": "/images/yoga.png"
    }
];

const DailyGoals = ({ onClose }) => {
    const previousGoals = {
        "Burn Calories": 300,
        "Social Media Detox": "30 min",
        "Walking": "300 steps",
        "Meditation": "30 min",
        "Yoga": "30 min"
    };

    const [goalsShown, setGoalsShown] = useState(0);
    const [selectedGoals, setSelectedGoals] = useState(previousGoals);
    const goalsValueRef = useRef(null);

    const handleDecrease = () => {
        if (goalsShown > 0) {
            setGoalsShown(goalsShown - 1);
        }
    };

    const handleIncrease = () => {
        if (goalsShown < goals.length) {
            setGoalsShown(goalsShown + 1);
        }
    };

    const handleGoalSelect = (name, value) => {
        setSelectedGoals(prevState => ({
            ...prevState,
            [name]: value
        }));
        console.log(selectedGoals);
        console.log(name, value);
    };

    const handleSubmit = () => {
        //send selected goals to backend
        onClose();
    }

    useEffect(() => {
        scrollToSelected();
    }, [goalsShown]);

    const scrollToSelected = () => {
        if (goalsValueRef.current) {
            const selectedElement = goalsValueRef.current.querySelector('.goal-amount.selected');
            if (selectedElement) {
                selectedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    };

    return (
        <div className="user-modal">
            <div className="dailygoals-modal">
                <div className='goals-top'>
                    <IoIosArrowBack onClick={handleDecrease} size={25} color={goalsShown === 0 ? "grey" : "black"} style={{ cursor: "pointer" }} />
                    {
                        goalsShown < goals.length ?
                            <div className='goals-head'>
                                <div className={goals[goalsShown].name === "Meditation" ? "icon-div meditation" : goals[goalsShown].name === "Yoga" ? "icon-div yoga" : "icon-div"}>
                                    <img src={goals[goalsShown].icon} alt={goals[goalsShown].name} />
                                </div>
                                <div className="goals-desc">
                                    <p className="head">{goals[goalsShown].name}</p>
                                </div>
                            </div>
                            :
                            <div></div>
                    }
                    <IoIosArrowForward onClick={handleIncrease} size={25} color={goalsShown === goals.length ? "grey" : "black"} style={{ cursor: "pointer" }} />
                </div>
                {
                    goalsShown < goals.length ?
                        <div className="goals-value" ref={goalsValueRef}>
                            {
                                goals[goalsShown].options.map((value, index) => (
                                    <div key={index} className={value === selectedGoals[goals[goalsShown].name] ? "goal-amount selected" : "goal-amount"} onClick={() => handleGoalSelect(goals[goalsShown].name, value)}>
                                        <p>{value}</p>
                                    </div>
                                ))
                            }
                        </div>
                        :
                        <div className="add-div">
                            <div className="add-goal">
                                <IoAddOutline size={40} color={"#FFFCF8"} />
                            </div>
                            <p className="create-goal">Create New Daily Goal</p>
                        </div>
                }
            {
                goalsShown < goals.length &&
                <button type="submit" className="save-goal" onClick={handleSubmit}>
                    Save
                </button>
            }
        </div>
        </div >
    );
};

export default DailyGoals;
